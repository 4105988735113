<template>
  <a
    class="btn user-btn j-header-logout-btn"
    :href="'javascript:void(0)'"
    v-show="isLogin"
    :class="lang"
  >
    <a :href="'javascript:;'" class="name blue logout" @click="logout">
      [{{ letter.logout }}]
    </a>
    <span class="name" v-html="userName"></span>
  </a>
</template>

<script>
import globalConfig from '../../utiles/globalConfig'
import { RESET_USER_INFO } from '../../store/login';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'headerAccountGlobal',
  computed: {
    userName() {
      // 20240725 需求人: 邓智轩 特殊处理：顽星域名账号展示隐藏前缀 以DISPLAY_NAME字段作为账号显示名称
      if (globalConfig.getMainHost() === 'playplanetgame.com') {
        return this.$store.state.login.displayName;
      } else {
        return this.$store.state.login.userName;
      }
    },

    ...mapGetters(['isLogin', 'lang', 'letter']),
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
