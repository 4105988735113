import { mapGetters } from 'vuex';
export default {
  computed: {
    isGm99() {
      return this.platform === 'gm99';
    },
    isGlobal() {
      return this.platform === 'global';
    },
    isThirdPlatform() {
      return this.thirdPlatformType !== '';
    },
    ...mapGetters(['platform', 'thirdPlatformType']),
  },
  methods: {
    updatePlatform(name) {
      this.$store.commit('updatePlatform', name);
    },
  },
};
