import { getJSON } from '../store/ultile'
import ajaxUrl from '../config/ajax'
import md5 from 'md5'
import globalConfig from '../utiles/globalConfig'
let stopProcessAction = []
class Interceptor {
  constructor(context, trigger, actionRequestParams) {
    this.trigger = trigger
    this.context = context
    this.actionRequestParams = actionRequestParams
  }

  async checkIsContinue() {
    if (stopProcessAction.includes(this.trigger.eventName)) {
      return false
    }

    if (!this.trigger.isAsk) {
      return true
    }

    if (!this.trigger.isLock) {
      return await this.askApiCanContinue()
    }

    this.showLoadingPage()
    let res = await Promise.race([
      this.askApiCanContinue(),
      new Promise(function (resolve) {
        setTimeout(() => {
          resolve('request timeout')
        }, 3000)
      })
    ])
    this.hideLoadingPage()
    if (res === 'request timeout') {
      return false
    } else {
      return !!res
    }
  }

  // 请求接口询问是否能继续
  askApiCanContinue() {
    return new Promise(resolve => {
      const eventName = this.trigger.eventName || ''
      const eventValue = this.trigger.eventValue || ''
      const tcaActionId = this.trigger.tcaActionId || ''
      const timeStamp = Date.parse(new Date())
      const key = ''
      let sign = md5(eventName + eventValue + tcaActionId + timeStamp + key)
      const {
        loginAccount = '',
        gameId = '',
        appLanguage = '',
        language = '',
        devicePlate = '',
        uid = '',
        serverId = '',
        packageName = '',
        roleLevel = '',
        roleName = '',
        roleId = ''
      } = this.actionRequestParams
      getJSON(ajaxUrl[window.pageInfo.platform].tca.checkTca, {
        eventName,
        eventValue,
        tcaActionId,
        timeStamp,
        sign,
        loginAccount,
        gameId,
        devicePlate,
        packageName,
        appLanguage,
        language,
        roleId,
        roleName,
        uid,
        serverId,
        roleLevel,
        ptCode: window.pageInfo.platform || '',
        packageVersion: globalConfig.getHrefParam('packageVersion') || ''
      }).then(({ res, data }) => {
        if (data && !data.isContinue) {
          stopProcessAction.push(eventName)
        }
        if (res && res.result && +res.result === 1) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  }

  hideLoadingPage() {
    this.context.hideTcaPageLoading()
  }

  showLoadingPage() {
    this.context.showTcaPageLoading()
  }
}

export default Interceptor
