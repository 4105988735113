export default {
  gm99: {
    h5: 'h5.',
    passort: 'passport.',
    event: 'events.',
    eventapi: 'eventsapi.',
    service: 'service.',
    msupport: 'msupport.',
    store: 'mstore.',
    page: 'www.',
  },

  global: {
    h5: 'h5.',
    passort: 'gpassport.',
    event: 'gevents.',
    eventapi: 'geventsapi.',
    support: 'gsupport.',
    store: 'gstore.',
    pay: 'pay.',
    abres: 'gabres.',
    page: 'www.',
  },
};
