import { pxToVh } from '../utiles/gameWidth';
export default {
  methods: {
    loadImg(src, type, iswidth, isheight) {
      if (src) {
        let self = this;
        let newImg = new Image();
        newImg.onload = function () {
          self.updateSize(type, newImg, iswidth, isheight);
          self[type] = src;
        };
        newImg.src = src;
      }
    },

    updateSize(type, img, iswidth, isheight) {
      let res = '';
      if (iswidth) {
        res += `width:${pxToVh(img.width)}vh;`;
      }
      if (isheight) {
        res += `height:${pxToVh(img.height)}vh;`;
      }
      this[`${type}Css`] = res;
      this.updateSpecialSize(type, img);
    },
    updateSpecialSize(type, img) {},
  },
};
