<template>
  <div :style="bgCss" class="gift-list-popup" v-if="bg && close">
    <img :src="bg" :style="bgCss" alt="" />
    <a class="close" href="javascript:;" @click="closePopup">
      <img :src="close" :style="closeCss" alt="" />
    </a>

    <div class="content" :style="fontcolor">
      <p class="title" :style="mainColor">{{ letter.h5_check_gift }}</p>
      <div class="table" :style="borderColor">
        <table>
          <thead>
            <tr>
              <th :style="theadCss">{{ letter.h5_award_popup_type }}</th>
              <th :style="theadCss">{{ letter.h5_award_popup_content }}</th>
              <th :style="theadCss">{{ letter.h5_award_popup_code }}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td :style="tdCss">{{ letter.h5_favour_gift_name }}</td>
              <td
                :style="tdCss"
                v-html="likeGiftState ? favourGiftContent : '-'"
              ></td>
              <td :style="tdCss">
                {{ likeGiftState ? giftList.like_award.card : '-' }}
              </td>
            </tr>
            <tr>
              <td :style="tdCss">{{ letter.h5_download_gift_name }}</td>
              <td
                :style="tdCss"
                v-html="downloadGiftState ? downloadGiftContent : '-'"
              ></td>
              <td :style="tdCss">
                {{ downloadGiftState ? giftList.download_award.card : '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="tips" :style="mainColor">{{ exchangeWay }}</p>
    </div>
  </div>
</template>

<script>
import LoadConfigImg from '../mixins/LoadConfigImg'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      popupInfo: window.pageInfo['popup'],
      fontcolor: `color:${window.pageInfo['normalColor']}`,
      bg: '',
      bgCss: '',
      close: '',
      closeCss: '',
      borderColor: `border-color:${window.pageInfo['borderColor']}`,
      mainColor: `color:${window.pageInfo['mainColor']}`,
      theadCss: `background-color:${
        window.pageInfo['tableHeaderBgColor']
      };border-color:${window.pageInfo['borderColor']};color:${
        window.pageInfo['tableHeaderTextColor'] || 'auto'
      }`,
      tdCss: `background-color:${
        window.pageInfo['tableBodyBgColor']
      };border-color:${window.pageInfo['borderColor']};color:${
        window.pageInfo['tableBodyTextColor'] || 'auto'
      }`
    }
  },
  mixins: [LoadConfigImg],
  computed: {
    exchangeWay() {
      return (
        (window.pageInfo['awardExchangeText'] &&
          window.pageInfo['awardExchangeText'][this.lang]) ||
        ''
      )
    },
    likeGiftState() {
      return (
        this.giftList &&
        this.giftList.like_award &&
        this.giftList.like_award.is_award
      )
    },
    downloadGiftState() {
      return (
        this.giftList &&
        this.giftList.download_award &&
        this.giftList.download_award.is_award
      )
    },
    favourGiftContent() {
      return window.pageInfo['favourAward'].info[this.lang] || '-'
    },
    downloadGiftContent() {
      return window.pageInfo['downloadAward'].info[this.lang] || '-'
    },
    ...mapGetters(['commonTipsContent', 'giftList', 'lang', 'letter'])
  },
  mounted() {
    if (this.popupInfo) {
      if (this.popupInfo.popupBgB) {
        this.loadImg(this.popupInfo.popupBgB, 'bg', true, true)
      }
      if (this.popupInfo.popupClose) {
        this.loadImg(this.popupInfo.popupClose, 'close', true, true)
      }
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('toggleGiftListPopup', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-list-popup {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 2;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    font-size: 20vh;
    .title {
      font-size: 28vh;
      font-weight: 600;
      margin: 30vh auto 20vh;
    }
    .table {
      margin: auto 30vh;
      max-height: 60%;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid transparent;
      border-left: none;
      border-right: none;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      td,
      th {
        border: 1px solid #fff;
        line-height: 150%;
        font-size: 20vh;
        width: 33%;
      }
      th {
        padding: 4% 0;
        font-weight: 100;
        border-top: none;
      }
      td {
        padding: 3%;
        border-bottom: none;
      }
    }

    .tips {
      font-size: 20vh;
      margin: 15vh 30vh 0;
      text-align: left;
    }
  }
}
</style>
