import de from './de-DE'
import en from './en-US'
import es from './es-ES'
import fr from './fr-FR'
import ja from './ja-JP'
import ko from './ko-KR'
import pt from './pt-PT'
import ru from './ru-RU'
import th from './th-TH'
import tr from './tr-TR'
import vi from './vi-VN'
import zh_CN from './zh-CN'
import zh_TW from './zh-TW'
import it from './it-IT'
import pl from './pl-PL'
import { getHrefParam } from '../ultile'
import globalConfig from '../../utiles/globalConfig'
const MAIN_HOSt = globalConfig.getMainHost()

const LANG_MAP = {
  'en-US': en,
  'es-ES': es,
  'tr-TR': tr,
  'zh-CN': zh_CN,
  'zh-TW': zh_TW,
  'fr-FR': fr,
  'pt-PT': pt,
  'vi-VN': vi,
  'th-TH': th,
  'ja-JP': ja,
  'ko-KR': ko,
  'ru-RU': ru,
  'de-DE': de,
  'it-IT': it,
  'pl-PL': pl
}

const LANG_TEXT = {
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
  'ja-JP': '日本語',
  'th-TH': 'ไทย',
  'ko-KR': '한국어',
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'pt-PT': 'Português',
  'ru-RU': 'Русские',
  'tr-TR': 'Türkçe',
  'vi-VN': 'Tiếng Việt',
  'it-IT': 'Lingua Italiana',
  'pl-PL': 'Polski'
}

const DEFAULT_LANG =
  window.pageInfo && window.pageInfo['isBrowserLang'] && window.pageInfo['lang']
    ? window.pageInfo['lang']
    : 'en-US'
export const LANGUAGE_LOCALSTORGE_NAME = '37games_page_lang'

// 匹配浏览器语言到LANG_MAP
const getBrowserLanguage = function () {
  if (window.navigator) {
    let lang =
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.systemLanguage
    if (lang.indexOf('-') > -1) {
      let langArr = lang.split('-')
      let realLang = langArr[0].toLowerCase() + '-' + langArr[1].toUpperCase()
      return LANG_MAP[realLang] ? realLang : false
    } else {
      let realLang = lang.toLowerCase()
      let res = ''
      for (let key in LANG_MAP) {
        let langkey = key.split('-')[0]
        if (realLang === langkey) {
          res = key
          break
        }
      }
      return res
    }
  } else {
    return
  }
}

const getLocalLang = function () {
  let localLang = globalConfig.getLclStorageItem(LANGUAGE_LOCALSTORGE_NAME)
  return localLang
}

export const judgeLangCurrent = function (lang) {
  let res = false
  for (let key in LANG_TEXT) {
    if (lang === key) {
      res = true
    }
  }
  return true
}

export default {
  language: '',
  defaultLang: DEFAULT_LANG,
  setLanguage(language) {
    this.language = language
  },
  init() {
    // 如果用浏览器语言的话 pageInfo里面的lang就是默认语言值，如果不是，lang是当前语言值
    let smartyLang =
      window.pageInfo && window.pageInfo['isBrowserLang']
        ? ''
        : window.pageInfo && window.pageInfo['lang']
    // 如果用浏览器语言的话，就不用localstorage记录的语言了
    let isUseLocalLang =
      window.pageInfo && window.pageInfo['isBrowserLang'] ? '' : getLocalLang()
    let lan =
      smartyLang ||
      getHrefParam('appLanguage') ||
      getHrefParam('language') ||
      isUseLocalLang ||
      getBrowserLanguage() ||
      DEFAULT_LANG
    if (
      window.pageInfo &&
      window.pageInfo['langList'] &&
      window.pageInfo['langList'].length > 0
    ) {
      let canIndex = window.pageInfo['langList'].findIndex(item => {
        return item === lan
      })
      if (canIndex == -1) {
        lan = DEFAULT_LANG
      }
    }
    this.setLanguage(lan)
  },
  mapMutiples(fields, lan) {
    if (lan) {
      this.setLanguage(lan)
    }
    const LAN = LANG_MAP[this.language] || LANG_MAP[DEFAULT_LANG]
    let languages = {}
    /* 
             数组的使用方式
            languages.mapMutiples([
            "feedback",
            "cancel",
            "confirm",
            "switch"]}

            feedback 是产对的对象的字段名， 语言包的字段名也是feedback。

            对象的使用方式：
             languages.mapMutiples({
                 "feedback" : "abc"
             })

             feedback 是产对的对象的字段名，abc 是语言包的字段名。
        */
    if (fields instanceof Array) {
      for (let i = 0, len = fields.length; i < len; i++) {
        let key = fields[i]
        languages[key] = LAN[key] || LANG_MAP[DEFAULT_LANG][key]
        languages[key] =
          (languages[key] && languages[key].replace('{mainHost}', MAIN_HOSt)) ||
          ''
      }
    } else {
      for (let prop in fields) {
        let mapKey = fields[prop]
        languages[prop] = LAN[mapKey] || LANG_MAP[DEFAULT_LANG][mapKey]
        languages[prop] =
          (languages[prop] &&
            languages[prop].replace('{mainHost}', MAIN_HOSt)) ||
          ''
      }
    }

    return languages
  },

  switch(fields, lan) {
    this.setLanguage(lan)
    const LAN = LANG_MAP[this.language] || LANG_MAP[DEFAULT_LANG]
    let languages = {}
    for (let i = 0, len = fields.length; i < len; i++) {
      let key = fields[i]
      languages[key] = LAN[key] || LANG_MAP[DEFAULT_LANG][key]
      languages[key] =
        (languages[key] && languages[key].replace('{mainHost}', MAIN_HOSt)) ||
        ''
    }
    return languages
  },
  get(fielName, lan) {
    if (lan) {
      this.setLanguage(lan)
    }
    const LAN = LANG_MAP[this.language] || LANG_MAP[DEFAULT_LANG]
    let result = LAN[fielName] || LANG_MAP[DEFAULT_LANG][fielName]
    return (result && result.replace('{mainHost}', MAIN_HOSt)) || ''
  },
  getLangText() {
    return LANG_TEXT
  },
  getAll() {
    const LAN = LANG_MAP[this.language] || LANG_MAP[DEFAULT_LANG]
    for (let key in LAN) {
      LAN[key] = (LAN[key] && LAN[key].replace('{mainHost}', MAIN_HOSt)) || ''
    }
    return LAN
  }
}
