import actionSdkPopup from './action/actionSdkPopup/index'
import Interceptor from './interceptor'
import ajaxUrl from '../config/ajax'
import { post } from '../store/ultile'
import { tcaApiData } from './devMock'

const ACTION_NAME = {
  sdkPopup: 'sdkPopup'
}

export const INIT_STAGE_CONFIG = {
  appInit: {
    stage: 'appInit',
    initReqestFunction: 'getAppInitTcaData'
  },
  enterServer: {
    stage: 'enterServer',
    initReqestFunction: 'getEnterServerTcaData'
  }
}

class TcaCoreManager {
  constructor() {
    this.nowStage = INIT_STAGE_CONFIG.appInit.stage
    this.actionMap = new Map()
    this.triggers = new Map()
    this.apiData = ''
    this.nowReqParams = {}
    this.initActionMap()
  }

  // 注册所有的action，初始化actionMap
  initActionMap() {
    this.addAction(ACTION_NAME.sdkPopup, actionSdkPopup)
  }

  //获取当前阶段初始化数据
  initTaData({ platform, apiKey, reqParams }) {
    this.nowReqParams = reqParams
    let nowStageReqestFunction =
      this[INIT_STAGE_CONFIG[this.nowStage].initReqestFunction]
    return new Promise((resolve, reject) => {
      if (
        Object.prototype.toString.call(nowStageReqestFunction) ===
        '[object Function]'
      ) {
        nowStageReqestFunction
          .apply(this, [
            {
              platform,
              apiKey,
              reqParams
            }
          ])
          .then(() => {
            this.initTriggers()
            this.returnResForAction()
            resolve()
          })
          .catch(() => {
            reject()
          })
      } else {
        reject()
      }
    })
  }

  // 获取入服的接口数据
  getEnterServerTcaData({ platform, apiKey, reqParams }) {
    return new Promise((resolve, reject) => {
      post(ajaxUrl[platform].tca[apiKey], reqParams, {
        withCredentials: true
      }).then(({ res, data }) => {
        //本地开发需要模拟数据打开，上线则注释
        // res = tcaApiData
        // data = tcaApiData.data
        if (
          res &&
          res.result &&
          +res.result === 1 &&
          data &&
          data.tca &&
          data.tca.taList &&
          data.tca.taList.length > 0
        ) {
          this.apiData = data.tca
          resolve()
        } else {
          reject()
        }
      })
    })
  }

  initTriggers() {
    let taList = this.apiData.taList
    taList &&
      taList.length &&
      taList.forEach(taItem => {
        if (this.triggers.has(taItem.eventName)) {
          this.triggers.get(taItem.eventName).push(taItem)
        } else {
          this.triggers.set(taItem.eventName, [])
          this.triggers.get(taItem.eventName).push(taItem)
        }
      })
  }

  // 只有触发时机匹配的action才会获取各自请求参数和返回业务参数
  returnResForAction() {
    for (let [key, item] of this.actionMap.entries()) {
      let actionStage = item.getStage()
      if (actionStage == this.nowStage) {
        item.setRequestParams(this.nowReqParams)
        item.parseParams(this.getActionApiData(key))
      }
    }
  }

  getActionApiData(actionName) {
    return this.apiData[actionName] || ''
  }

  // 添加一个动作
  addAction(name, action) {
    if (action && !this.actionMap.has(name)) {
      this.actionMap.set(name, action)
    }
  }

  // 在游戏内事件或SDK事件上报的地方调用TCA框架的trigger方法
  async trigger(event, context) {
    let triggerObj = this.triggers.get(event)
    if (!triggerObj) {
      return
    }
    let trigger = triggerObj[0]
    if (trigger && trigger.tcaAction) {
      let action = this.actionMap.get(trigger.tcaAction)
      action &&
        action.checkIsActivated() &&
        (await this.checkIntercept(
          context,
          trigger,
          action.getRequestParams()
        )) &&
        action.process(trigger, context)
    }
  }

  // 确认是否拦截
  async checkIntercept(context, trigger, actionRequesParams) {
    let interceptor = new Interceptor(context, trigger, actionRequesParams)
    return await interceptor.checkIsContinue()
  }

  // 更新当前阶段
  updateNowStage(stage) {
    this.nowStage = stage
  }
}

export default TcaCoreManager
