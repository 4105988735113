import url from '../config/url';
import globalConfig from '../utiles/globalConfig';
import config from '../config/index';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      userAccount: '',
      userPassword: '',
      userRePassword: '',
      userCode: '',
      userAccountTips: '',
      userPasswordTips: '',
      userRePasswordTips: '',
      userCodeTips: '',
      loginRequestTips: '',
      registerRequestTips: '',
      termTips: '',
      isRemember: true,
      isAgreeTerm: true,

      canLogin: true,
      canRegister: true,

      isLoginPannel: true,
      isRegisterTerm: window.pageInfo['defaultRegisterTerm'],

      isShowRecaptcha: false,
    };
  },
  computed: {
    findPwdLink() {
      return globalConfig.hrefAddParams(url[this.platform].findPassword, {
        gameId: this.gameId,
        language: this.lang,
        appLanguage: this.lang,
      });
    },
    loginCodeUrl() {
      return this.loginNeedCode
        ? globalConfig.hrefAddParams(url[this.platform].code, {
            rnd: this.codeTimestamp,
          })
        : '';
    },
    registerCodeUrl() {
      return this.registerNeedCode
        ? globalConfig.hrefAddParams(url[this.platform].code, {
            rnd: this.codeTimestamp,
          })
        : '';
    },
    ...mapGetters([
      'gameId',
      'letter',
      'thirdList',
      'platform',
      'loginNeedCode',
      'registerNeedCode',
      'codeTimestamp',
      'userName',
    ]),
  },
  methods: {
    blurUserAccount(needCheck) {
      let res = this.judgeUserAccount();
      if (res && config[this.platform].login.code) {
        this.checkNeedCode(this.userAccount);
      }
      globalConfig.gaStatistics('page_action', {
        action_name: '点击账号输入框',
      });
    },
    blurUserPassword(needCheck) {
      if (needCheck) {
        this.judgeUserPassword();
      }
      globalConfig.gaStatistics('page_action', {
        action_name: '点击密码输入框',
      });
    },
    blurUserRePassword(needCheck) {
      if (needCheck) {
        this.judgeUserRepassword();
      }
      globalConfig.gaStatistics('page_action', {
        action_name: '点击注册密码二次输入框',
      });
    },
    blurUserCode(needCheck) {
      if (needCheck) {
        this.judgeCode();
      }
      globalConfig.gaStatistics('page_action', {
        action_name: '点击验证码输入框',
      });
    },
    judgeUserAccount() {
      if (!this.userAccount) {
        this.userAccountTips = this.letter.accountName;
        return;
      }
      switch (this.platform) {
        case 'global':
          if (!/^([a-zA-Z0-9]|[_\.@]){6,30}$/.test(this.userAccount)) {
            this.userAccountTips = this.letter.accountValidateMsg;
            return;
          }
          break;
        case 'gm99':
          if (!!~this.userAccount.indexOf('@')) {
            if (
              !/^([a-zA-Z0-9_-])+([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                this.userAccount
              )
            ) {
              this.userAccountTips =
                '非郵箱類帳號只能6~16個包含字母、數字、下底線字符';
              return;
            }
            if (this.userAccount.length < 6 || this.userAccount.length > 100) {
              this.userAccountTips = '郵箱類帳號合法長度為6~100個字符';
              return;
            }
          } else {
            if (
              !this.isLoginPannel &&
              !/^[a-zA-Z0-9_]{6,16}$/.test(this.userAccount)
            ) {
              this.userAccountTips =
                '非郵箱類帳號只能6~16個包含字母、數字、下底線字符';
              return;
            }
          }
          break;
        default:
          break;
      }
      this.userAccountTips = '';
      return true;
    },
    judgeUserPassword(type) {
      if (!this.userPassword) {
        this.userPasswordTips = this.letter.password;
        return;
      }
      switch (this.platform) {
        case 'global': {
          if (!/^[\W|\w]{6,20}$/.test(this.userPassword)) {
            this.userPasswordTips = this.letter.pwdValidateMsg;
            return;
          }

          break;
        }
        case 'gm99':
          if (!this.isLoginPannel) {
            if (!/^[\W|\w]{6,16}$/.test(this.userPassword)) {
              this.userPasswordTips = '密碼6-16個字元';
              return;
            }
            if (this.userPassword == this.userAccount) {
              this.userPasswordTips = '密碼不能與帳號相同';
              return;
            }
          }
          break;
        default:
          break;
      }

      if (!this.isLoginPannel) {
        this.judgeUserRepassword();
      }

      this.userPasswordTips = '';
      return true;
    },
    judgeUserRepassword() {
      if (!this.userRePassword || this.userPassword !== this.userRePassword) {
        this.userRePasswordTips = this.letter.repeatPassword;
        return;
      }
      this.userRePasswordTips = '';
      return true;
    },
    judgeCode() {
      if (
        !config[this.platform].login.code ||
        (config[this.platform].login.code &&
          ((!this.loginNeedCode && this.isLoginPannel) ||
            (!this.registerNeedCode && !this.isLoginPannel)))
      ) {
        return true;
      }
      if (!this.userCode) {
        this.userCodeTips = '請填寫驗證碼';
        return;
      }
      if (!/^[a-zA-Z0-9]{4}$/.test(this.userCode)) {
        this.userCodeTips = '驗證碼格式錯誤';
        return;
      }
      this.userCodeTips = '';
      return true;
    },
    judgeTerm() {
      if (!this.isAgreeTerm) {
        this.termTips = this.letter.checkServiceTips;
        return;
      }
      return true;
    },
    toggleRemember() {
      this.isRemember = !this.isRemember;
      try {
        globalConfig.gaStatistics('page_action', {
          action_name: '点击登录是否记住密码框',
        });
      } catch (e) {}
    },
    toggleAgreeTerm() {
      this.isAgreeTerm = !this.isAgreeTerm;
      try {
        globalConfig.gaStatistics('page_action', {
          action_name: '点击注册是否同意条款框',
        });
      } catch (e) {}
    },
    switchToRegister() {
      this.isLoginPannel = false;
      this.resetData();
      try {
        globalConfig.gaStatistics('page_action', {
          action_name: '点击切换至账号注册界面',
        });
      } catch (e) {}
    },
    switchToLogin() {
      this.resetData();
      this.isLoginPannel = true;
      try {
        globalConfig.gaStatistics('page_action', {
          action_name: '点击切换至账号登录界面',
        });
      } catch (e) {}
    },
    resetData() {
      this.userAccount = '';
      this.userPassword = '';
      this.userRePassword = '';
      this.userAccountTips = '';
      this.userPasswordTips = '';
      this.userRePasswordTips = '';
      this.loginRequestTips = '';
      this.registerRequestTips = '';
      this.isRemember = true;

      this.isAgreeTerm = this.isRegisterTerm;
    },
    checkLoginData() {
      return (
        this.judgeUserAccount() &&
        this.judgeUserPassword('login') &&
        this.judgeCode()
      );
    },
    checkRegisterData() {
      return (
        this.judgeUserAccount() &&
        this.judgeUserPassword('register') &&
        this.judgeUserRepassword() &&
        this.judgeTerm() &&
        this.judgeCode()
      );
    },
    login() {
      if (this.canLogin) {
        this.canLogin = false;
        let checkLoginDataRes = this.checkLoginData();

        if (checkLoginDataRes) {
          globalConfig.gaStatistics('login_type', {
            login_type: 'account_login',
          });
          this.accountLogin({
            userAccount: this.userAccount,
            userPassword: this.userPassword,
            code: this.userCode,
            isRemember: this.isRemember,
          })
            .then(this.judgeIsLogin)
            .then(res => {
              if (res) {
                this.resetData();
                this.isLoginPannel = true;
                //清除缓存是否开启谷歌验证
                document.cookie = "recaptchaCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
              this.canLogin = true;
            })
            .catch(res => {
              if (res.msg.indexOf("900239") !== -1) {
                this.isShowRecaptcha = true;
                //缓存是否开启谷歌验证
                let d = new Date();
                d.setTime(d.getTime() + (1 * 60 * 60 * 1000));
                let expires = "expires=" + d.toGMTString();
                document.cookie = "recaptchaCookie=true; " + expires;
              }
              this.loginRequestTips = res.msg;
              this.canLogin = true;
            });
          if (config[this.platform].login.code) {
            this.checkNeedCode(this.userAccount);
          }
        } else {
          this.canLogin = true;
        }
      }
    },
    register() {
      if (this.canRegister) {
        this.canRegister = false;
        let checkRegisterDataRes = this.checkRegisterData();
        if (checkRegisterDataRes) {
          globalConfig.gaStatistics('login_type', {
            login_type: 'account_register',
          });
          this.accountRegister({
            userAccount: this.userAccount,
            userPassword: this.userPassword,
            userRePassword: this.userRePassword,
            code: this.userCode,
          })
            .then(this.judgeIsLogin)
            .then(res => {
              if (res) {
                this.resetData();
                this.isLoginPannel = true;
              }
              this.canRegister = true;
            })
            .catch(res => {
              this.registerRequestTips = res.msg;
              this.canRegister = true;
            });
          if (config[this.platform].login.code) {
            this.checkNeedCode(this.userAccount);
          }
        } else {
          this.canRegister = true;
        }
      }
    },
    ...mapActions([
      'judgeIsLogin',
      'accountLogin',
      'accountRegister',
      'checkNeedCode',
    ]),
  },
};
