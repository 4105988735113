<template>
  <div class="header" :class="platform">
    <div class="header-content">
      <img class="logo" :src="logoUrl" alt="" />
      <div class="header-account">
        <a
          :href="item.link"
          class="btn"
          :class="`j-header-${item.className}-btn`"
          v-for="(item, key) in headerIconList"
          :key="key"
          target="_blank"
        >
          <img class="btn-img" :src="item.icon" alt="" />
          <span class="span">{{ item.label }}</span>
        </a>
        <a
          class="btn j-header-screen-btn"
          href="javascript:;"
          v-if="screenBtnState"
          @click="fullscreen"
        >
          <em class="screen"></em>
          <span class="span">{{ letter.h5_header_screen }}</span>
        </a>
        <a
          class="btn qr-btn j-header-qr-btn"
          href="javascript:;"
          v-if="qrBtnState"
        >
          <em class="qr"></em>
          <div class="code" v-if="bg">
            <img class="b" :src="bg" :style="bgCss" alt="" />
            <p class="t" :style="fontColor">{{ letter.qrCodeTip }}</p>
            <img class="c" :src="qrcodeSrc" v-show="qrcodeSrc" alt="" />
          </div>
        </a>
        <a
          class="btn j-header-gift-btn"
          href="javascript:;"
          @click="excShowGiftPopup"
          v-if="giftBtnState"
        >
          <em class="gift"></em>
          <span class="span">{{ letter.h5_header_gift }}</span>
        </a>
        <a
          class="btn j-header-home-btn"
          :href="homeUrl"
          v-if="homeUrl"
          target="_blank"
        >
          <em class="home"></em>
          <span class="span">{{ letter.h5_header_home_text }}</span>
        </a>
        <a
          v-if="canSwitchHorizontal"
          class="btn"
          href="javascript:;"
          @click="switchHorizontal"
        >
          <em class="rotate"></em>
          <span class="span">{{ letter.switch_horizontal }}</span>
        </a>
        <a
          v-if="twitterLink !== ''"
          class="btn"
          :href="twitterLink"
          target="_blank"
        >
          <em class="tw"></em>
        </a>
        <a
          class="btn j-header-recharge-btn"
          :href="storeUrl"
          v-if="storeBtnState"
          target="_blank"
        >
          <em class="recharge"></em>
          <span class="span">{{ letter.h5_header_store_text }}</span>
        </a>
        <a
          class="btn j-header-download-btn"
          href="javascript:;"
          @click="downloadFn"
          v-if="downloadBtnState && !isExe"
        >
          <em class="download"></em>
          <span class="span">{{ letter.download }}</span>
        </a>

        <div class="tips-box" v-if="autoTips && !isExe">
          <transition name="fade">
            <div class="tips" v-show="showTips">
              <img :src="tipsBg" alt="" />
              <p v-html="tipsText" :class="lang" :style="tipCss"></p>
              <em class="close-tip" @click="closeTips"></em>
            </div>
          </transition>
        </div>
        <a class="btn j-header-favour-btn" @click="favourFn" v-if="!isExe">
          <em class="favour"></em>
          <span class="span">{{ letter.favour }}</span>
        </a>
        <a class="btn" @click="headerInstall" v-if="showPwaInstall">
          <em class="pwa"></em>
          <span class="span">{{ letter.pwsInstall }}</span>
        </a>

        <template class="btn">
          <slot name="account"></slot>
        </template>
        <a
          v-if="headerBanner && headerBanner.show"
          :href="headerBanner.link"
          target="_blank"
          class="header-banner"
          :style="{ 'background-image': `url(${headerBanner.bg})` }"
        >
        </a>
      </div>
    </div>
    <transition name="fade">
      <SafariPwaGuidePopup
        v-if="isGuide"
        @close="hideSafariTip"
      ></SafariPwaGuidePopup>
    </transition>
    <transition name="fade">
      <PwaGuidePopup
        v-if="isPwaGuide"
        @install="guideInstall"
        @close="hidePwaGuide"
      ></PwaGuidePopup>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Pwa from '../../mixins/Pwa'
import Favour from '../../mixins/Favour'
import StoreUrl from '../../mixins/StoreUrl'
import Download from '../../mixins/Download'
import Qrcode from '../../mixins/qrCode'
import globalConfig from '../../utiles/globalConfig'
import LoadConfigImg from '../../mixins/LoadConfigImg'
import Platform from '../../mixins/Platform'
import LogoUrl from '../../mixins/LogoUrl'
import Fullscreen from '../../mixins/Fullscreen'
import Exe from '../../mixins/Exe'
import SafariPwaGuidePopup from './SafariPwaGuidePopup'
import PwaGuidePopup from './PwaGuidePopup'
export default {
  name: 'h5header',
  data() {
    return {
      showTips: false,
      tipsBg: window.pageInfo['tips'] || '',
      isFavourAward: window.pageInfo['favourAward']['isAward'],
      isDownloadAward: window.pageInfo['downloadAward']['isAward'],
      headerIconList: window.pageInfo['headerBtnList'],
      tipCss: `color:${window.pageInfo['normalColor']}`,
      giftBtnState: window.pageInfo['pcHeaderGift'],
      qrBtnState: window.pageInfo['pcCode'] === 'header',
      storeBtnState: window.pageInfo['pcHeaderRecharge'],
      twitterLink: window.pageInfo['pcHeaderTwitter'],
      autoTips: window.pageInfo['autoTips'],
      canSwitchHorizontal: window.pageInfo['canSwitchHorizontal'],
      bg: '',
      bgCss: '',
      fontColor: `color:${window.pageInfo['normalColor']}`,
      downloadBtnState: window.pageInfo['pcHeaderDownload'],
      screenBtnState: window.pageInfo['pcHeaderScreen'],
      headerBanner: window.pageInfo['headerBanner']
    }
  },
  mixins: [
    Pwa,
    Favour,
    Download,
    Qrcode,
    LoadConfigImg,
    LogoUrl,
    StoreUrl,
    Platform,
    Fullscreen,
    Exe
  ],
  computed: {
    homeUrl() {
      let realUrl =
        window.pageInfo['homeUrl'][window.h5ip] ||
        window.pageInfo['homeUrl'].default
      if (!window.pageInfo['pcHeaderHome'] && !realUrl) {
        return ''
      }
      return globalConfig.hrefAddParams(realUrl, {
        language: this.lang,
        appLanguage: this.lang,
        lang: this.lang,
        gameId: this.gameId,
        h5: 1
      })
    },

    tipsText() {
      return (
        window.pageInfo['tipsText'] ||
        this.escape2Html(this.letter.favourAndDownloadTip)
      )
    },
    userPhoto() {
      return this.$store.state.login.userPhoto
    },
    userName() {
      return this.$store.state.login.userName
    },
    ...mapGetters([
      'isHorizontal',
      'letter',
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'lang',
      'platform'
    ])
  },
  mounted() {
    if (window.pageInfo['pcCodeBg']) {
      this.loadImg(window.pageInfo['pcCodeBg'], 'bg', true, true)
    }
    if (this.autoTips) {
      this.autoTipsFn()
    }
  },
  methods: {
    switchHorizontal() {
      this.$store.commit('updateIsHorizontal', !this.isHorizontal)
    },
    closeTips() {
      this.showTips = false
    },
    autoTipsFn() {
      if (this.tipsBg) {
        let isTips = parseInt(
          globalConfig.getLclStorageItem('isFavourAndDownloadTip'),
          10
        )
        if (isTips) {
          let lastTime = globalConfig.getLclStorageItem(
            'favourAndDownloadTipTime'
          )
          let nowTime = new Date().getTime()
          let day = parseInt((nowTime - lastTime) / (1000 * 60 * 60 * 24))
          if (day > 0) {
            this.readyShowTip()
          }
        } else {
          this.readyShowTip()
        }
      }
    },
    readyShowTip() {
      setTimeout(() => {
        this.showTips = true
        globalConfig.setLclStorageItem('isFavourAndDownloadTip', 1)
        globalConfig.setLclStorageItem(
          'favourAndDownloadTipTime',
          new Date().getTime()
        )
      }, 1000 * 60 * 3)
    },

    escape2Html(str) {
      let arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t]
      })
    },
    excShowGiftPopup() {
      this.getGiftList()
      this.$store.commit('toggleGiftListPopup', true)
    },
    ...mapActions(['getGiftList'])
  },
  components: {
    SafariPwaGuidePopup,
    PwaGuidePopup
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_dimen.scss';

.header {
  width: 100%;
  text-align: center;
  height: $headerHeight;
  background-color: white;
  border-bottom: 3px solid #f87e19;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  &-content {
    width: 90%;
    height: 100%;
    margin: auto;
    position: relative;
  }
  &-account {
    height: 100%;
  }
  .btn {
    text-decoration: none;
    height: $headerHeight;
    color: #333;
    font-size: $headerFont;
    vertical-align: middle;
    border: none;
    background: none;
    outline: none;
    padding: 0 0 0 20vh;
    line-height: $headerHeight;
    text-align: center;
    position: relative;
    margin: auto 20vh;
    float: right;
    .span {
      vertical-align: middle;
      transition: all 0.1s;
      font-size: 18vh;
    }
    // &:first-child {
    //    margin-right: 0;
    // }
    &:hover {
      cursor: pointer;
    }
    .btn-img {
      position: absolute;
      top: 50%;
      margin-top: -10vh;
      left: 0;
      width: 18vh;
      height: 20vh;
    }
  }

  .pwa {
    background: url($img + 'pwa.png') no-repeat center center;
  }
  .favour {
    background: url($img + 'collect.png') no-repeat center center;
  }
  .download {
    background: url($img + 'download.png') no-repeat center center;
  }
  .cafe {
    background: url($img + 'cafe.png') no-repeat center center;
  }
  .qr {
    background: url($img + 'qrcode-icon.png') no-repeat center center;
  }
  .gift {
    background: url($img + 'gift-icon.png') no-repeat center center;
  }
  .home {
    background: url($img + 'home-icon.png') no-repeat center center;
  }
  .recharge {
    background: url($img + 'store-icon.png') no-repeat center center;
  }
  .screen {
    background: url($img + 'screen-icon.png') no-repeat center center;
  }
  .rotate {
    background: url($img + 'rotate-icon.png') no-repeat center center;
  }
  .tw {
    background: url($img + 'tw-icon.png') no-repeat center center;
  }
  .pwa,
  .cafe,
  .favour,
  .download,
  .qr,
  .gift,
  .home,
  .recharge,
  .screen,
  .rotate,
  .tw {
    position: absolute;
    background-size: contain;
    width: 18vh;
    height: 50%;
    top: 25%;
    left: 0;
    // transform: translateY(-50%);
  }
  .qr-btn {
    position: relative;
    padding: 0 20vh;
    .qr {
      width: 48vh;
    }
    .code {
      position: absolute;
      top: $headerHeight - 16vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      display: none;
      .c,
      .t {
        position: absolute;
        left: 10%;
        // transform: translateX(-50%);
        width: 80%;
      }
      .c {
        top: 90vh;
      }
      .t {
        top: 10vh;
        text-align: center;
        line-height: 150%;
        font-weight: 800;
        font-size: 16vh;
      }
    }
    &:hover {
      .code {
        display: block;
      }
    }
  }

  .logo {
    // background: url($img+'logo.png') no-repeat center center;
    // background-size: contain;
    // height: 35vh;
    // width: 202vh;
    position: absolute;
    left: 0;
    top: 10%;
    // transform: translateY(-50%);
    height: 80%;
  }
  .name {
    color: #333;
    font-size: $headerFont;
    vertical-align: middle;
    line-height: $headerHeight;
    font-size: 18vh;
    float: right;
  }
  .header-banner {
    float: right;
    height: $headerHeight;
    width: 300vh;
    background: no-repeat center;
    background-size: contain;
  }

  .tips-box {
    float: right;
    width: 0;
    position: relative;
    .tips {
      position: absolute;
      top: $headerHeight - 16vh;
      right: -106vh;
      img {
        width: 490vh;
        height: 128vh;
      }
      p {
        position: absolute;
        width: 420vh;
        height: 60vh;
        bottom: 22vh;
        left: 16vh;
        font-size: 14vh;
        text-align: left;
        line-height: 140%;
        white-space: pre-line;
      }
      .zh-TW,
      .ko-KR,
      .vi-VN {
        font-size: 18vh;
      }
      .ja-JP {
        font-size: 12vh;
      }

      .close-tip {
        width: 40vh;
        height: 40vh;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .gift-box {
    float: right;
    cursor: pointer;
    transform: scale(0.8);
  }
  &.gm99 {
    .btn:hover .span {
      color: $orange;
    }
  }
  &.global {
    .btn:hover .span {
      color: $blue;
    }
  }
}
</style>
