import { ADS_PLATFORM } from '../ads/define'
import { createPopupAds, displayPopupAds } from '../ads/gam/popup'
import { createAnchorAds, displayAnchorAds } from '../ads/gam/anchor'
import { initAds } from '../ads/gam/index'
import { mapGetters } from 'vuex'
import AdsRewardAdsMixin from './ads/reward'
import globalConfig from '../utiles/globalConfig'

const openRewardedAds = window.pageInfo['ads']['rewardedAds']
const openPopupAds = window.pageInfo['ads']['popupAds']
const openAnchorAds = window.pageInfo['ads']['anchorAds']

const adsPlatform = window.pageInfo['ads']['adsPlatform']
const asideAdsWait = +window.pageInfo['ads']['asideAdsWait'] || 0

let isInit = false

export default {
  data() {
    return {
      startTime: null,
      elapsedTime: 0,
      intervalId: null
    }
  },
  mounted() {
    if (isInit) return
    isInit = true
    if (adsPlatform == ADS_PLATFORM.gam) {
      if (openPopupAds) {
        createPopupAds()
      }
      if (openRewardedAds) {
        this.initRewardEvents()
      }
      // 加载ad
      initAds()
      if (openPopupAds) {
        displayPopupAds()
      }
      // 延迟加载
    }
    this.startTime = Date.now()
    this.intervalId = setInterval(this.updateElapsedTime, 1000)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  },
  computed: {
    ...mapGetters([
      'gameId',
      'lang',
      'userName',
      'userId',
      'gclid',
      'cid',
      'scid',
      'serverId',
      'roleId',
      'fbc',
      'fbp',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname',
      'isLogin'
    ])
  },
  mixins: [AdsRewardAdsMixin],
  methods: {
    executeMethod() {
      if (openAnchorAds && !window.isPhone) {
        createAnchorAds()
        displayAnchorAds()
      }
    },
    updateElapsedTime() {
      const now = Date.now()
      this.elapsedTime = now - this.startTime
      if (this.elapsedTime >= asideAdsWait * 1000) {
        this.executeMethod()
        clearInterval(this.intervalId)
        return true
      }
      return
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        let isTime = this.updateElapsedTime()
        if (!isTime) {
          this.startTime = Date.now()
          this.intervalId = setInterval(this.updateElapsedTime, 1000)
        }
      } else {
        clearInterval(this.intervalId)
      }
    },
    showRewardedAds(params) {
      if (!openRewardedAds) return
      globalConfig.gaStatistics('show_ad_reward')
      if (params.platform == ADS_PLATFORM.gam) {
        let bindId = params.bindId == void 0 ? '' : params.bindId
        this.excShowRewardAds(bindId)
      }
      setTimeout(() => {
        history.replaceState(
          {},
          document.title,
          window.location.origin +
            window.location.pathname +
            window.location.search
        )
      }, 100)
    },
    canShowRewardedAds(params) {
      if (!openRewardedAds) return
      let bindId = params.bindId == void 0 ? '' : params.bindId
      if (params.platform == ADS_PLATFORM.gam) {
        this.canShowRewardAds(bindId)
        return
      }
    }
  }
}
