<template>
  <div class="account-box btn" v-show="isLogin">
    <a class="list-btn">
      <img
        src="https://www.gm99.com/bbs/avatar?v=1604547874511"
        class="user-img"
        alt=""
      />
      <span class="username" v-html="userName"></span>
      <em class="tra"></em>
    </a>
    <div class="account-list">
      <a class="account-list-a" href="//www.gm99.com/center" target="_blank">
        <em class="center"></em>
        <span class="content">會員中心</span>
      </a>
      <a
        class="account-list-a"
        href="//www.gm99.com/center?type=message"
        target="_blank"
      >
        <em class="message"></em>
        <span class="content">消息({{ messageCount }})</span>
      </a>
      <a class="account-list-a" href="javascript:;" @click="logout">
        <em class="switch"></em>
        <span class="content">切換帳號</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'headerAccountGlobal',
  watch: {
    isLogin(newV, oldV) {
      if (newV && newV !== oldV) {
        this.getMessageCount()
      }
    }
  },
  computed: {
    userName() {
      return this.$store.state.login.userName
    },
    ...mapGetters(['isLogin', 'messageCount'])
  },
  methods: {
    ...mapActions(['getMessageCount', 'logout'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.user-img,
.tra,
.username {
  display: inline-block;
  vertical-align: middle;
}
.username {
  margin: auto 12vh auto 6vh;
  font-size: 18vh;
}
.user-img {
  width: 40vh;
  height: 40vh;
  border-radius: 100px;
}
.tra {
  background: url($img + 'tra.png') no-repeat top center;
  background-size: contain;
  width: 10vh;
  height: 6vh;
}
.account-box {
  position: relative;
  &:hover {
    .account-list {
      display: block;
    }
  }
}
.account-list {
  position: absolute;
  background: white;
  top: $headerHeight - 10vh;
  left: 0;
  width: 100%;
  display: none;
}
.account-list-a {
  display: block;
  text-align: center;
  line-height: 270%;
  color: #333;
  &:hover {
    .center {
      background: url($img + 'person-hover.png') no-repeat top center;
      background-size: contain;
    }
    .message {
      background: url($img + 'message-hover.png') no-repeat top center;
      background-size: contain;
    }
    .switch {
      background: url($img + 'switch-hover.png') no-repeat top center;
      background-size: contain;
    }
    .content {
      color: $orange;
    }
    cursor: pointer;
  }
}
.content {
  width: 80vh;
  font-size: 18vh;
  text-align: left;
  margin-left: 10vh;
  min-width: 50px;
}

.center {
  background: url($img + 'person.png') no-repeat top center;
  width: 19vh;
  height: 17vh;
}
.message {
  background: url($img + 'message.png') no-repeat top center;
  width: 19vh;
  height: 14vh;
}
.switch {
  background: url($img + 'switch.png') no-repeat top center;
  width: 19vh;
  height: 12vh;
}
.center,
.message,
.switch,
.content {
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
}
</style>
