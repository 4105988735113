import globalConfig from '../utiles/globalConfig';
import {
  fbSdkGetStatus,
  fbSdkLogin,
  fbSdkShare,
} from '../utiles/thirdPartySdkFn';
import { mapGetters } from 'vuex';

const mainHost = globalConfig.getMainHost();

export default {
  computed: {
    pageUrl() {
      return (
        window.location.origin +
        window.location.pathname +
        `?game_id=${this.gameId}&cid=gameshare`
      );
    },
    ...mapGetters(['letter', 'gameId']),
  },
  methods: {
    facebookShare({ title, descript, id, img }) {
      let href = `https://gactivityapi.${mainHost}/fbShareCustomize/customizeShare/message/${encodeURIComponent(
        descript
      )}/app_id/${encodeURIComponent(id)}/redict_url/${encodeURIComponent(
        `${this.pageUrl}&scid=fbshare`
      )}/share_title/${encodeURIComponent(title)}/pic_url/${encodeURIComponent(
        img
      )}`;
      fbSdkGetStatus().then(res => {
        if (res.status === 'connected') {
          fbSdkShare(href);
        } else {
          fbSdkLogin().then(() => {
            fbSdkShare(href);
          }, this.loginFail);
        }
      });
    },
    twitterShare(text) {
      window.open(
        `//twitter.com/intent/tweet?text=${encodeURIComponent(
          text || ''
        )} ${encodeURIComponent(`${this.pageUrl}&scid=twishare`)}`
      );
    },
    lineShare() {
      window.open(
        `https://lineit.line.me/share/ui?url=${encodeURIComponent(
          `${this.pageUrl}&scid=lineshare`
        )}`,
        '_blank',
        'toolbar=yes,location=yes,directories=no,status=no,menubar=yes,scrollbars=yes,resizable=no,copyhistory=yes'
      );
    },
    loginFail() {
      alert(this.letter.fb_canvas_login_fail);
    },
  },
};
