import globalConfig from '../utiles/globalConfig';
const mainHost = globalConfig.getMainHost();

export default {
  data() {
    return {
      // ip制定logo-》游戏默认logo=》域名默认logo
      logoUrl:
        window.pageInfo['headerLogo'][window.h5ip] ||
        window.pageInfo['headerLogo'].default ||
        `//abres.octlib.com/platformIcon/h5/${mainHost}.png`,
    };
  },
};
