import login from './login';
import store from './store';
import downAgift from './downAgift';
import language from './language';
import other from './other';
import mobage from './mobage';
import gclidAnaly from './gclidAnaly';
import ajaxUrl from '../config/ajax';
import { getJSON } from './ultile';
import globalConfig from '../utiles/globalConfig';
import md5 from 'md5';

let getGameLinkTimes = 0;
const DEFAULT_BG = window.pageInfo['bg'];
const DEFAULT_OTHER_BG = window.pageInfo['otherBg'];
const DEFAULT_IS_HORIZONTAL = window.pageInfo['isHorizontal']
const DEFAULT_WIDTH_SIZE = window.pageInfo['widthSize'];
const DEFAULT_OTHER_WIDTH_SIZE = window.pageInfo['otherWidthSize'];

export default {
  state: {
    platform: window.pageInfo['platform'] || '',
    thirdPlatformType: globalConfig.getThirdPlatformType(),

    gameId: null,

    showPwaInstall: false,

    gameLink: '',
    serverId: '',
    serverName: '',
    roleId: '',
    roleName: '',
    roleLevel: '',

    bg: DEFAULT_BG,
    widthSize: DEFAULT_WIDTH_SIZE,
    isHorizontal: DEFAULT_IS_HORIZONTAL,
    isfullscreen: false,
    clickfullscreen: false,

    commonTipsContent: '', // 公共弹窗的内容，有内容显示，没内容关闭
    commonTipsSure: '',

    serverState: true,
    privacyState: true,

    agePopupState: false,
    asidePopupState: false,

    qrCodeVersion: '', // 二维码版本号

    verifiedPopupState: false, // 实名认证弹窗
  },
  getters: {
    platform: (state) => state.platform,
    thirdPlatformType: (state) => state.thirdPlatformType,
    bg: state => state.bg,
    widthSize: state => state.widthSize,
    isHorizontal: state => state.isHorizontal,
    showPwaInstall: (state) => state.showPwaInstall,
    isLogin: (state) => !!state.login.userId,
    gameId: (state) => state.gameId,
    gameLink: (state) => state.gameLink,
    serverId: (state) => state.serverId,
    roleId: (state) => state.roleId,
    roleName: (state) => state.roleName,
    roleLevel: (state) => state.roleLevel,
    favourSelecePopupState: (state) => state.favourSelecePopupState,
    isfullscreen: (state) => state.isfullscreen,
    clickfullscreen: (state) => state.clickfullscreen,

    location: (state) => {
      if (window.pageInfo['isBrowserLang']) {
        return (
          window.pageInfo['location'] ||
          state.language.lang.replace(/^[a-z]{2}-/, '')
        );
      } else {
        return window.pageInfo['location'];
      }
    },

    commonTipsContent: (state) => state.commonTipsContent,
    commonTipsSure: (state) => state.commonTipsSure,

    serverState: (state) => state.serverState, // 服务条款
    privacyState: (state) => state.privacyState, // 隐私协议

    agePopupState: (state) => state.agePopupState,
    asidePopupState: (state) => state.asidePopupState,

    qrCodeVersion: (state) => state.qrCodeVersion,

    verifiedPopupState: (state) => state.verifiedPopupState,
  },
  mutations: {
    updateShowPwaInstall(state, data) {
      state.showPwaInstall = data;
    },
    updateIsHorizontal(state, data) {
      if(data === DEFAULT_IS_HORIZONTAL) {
        state.widthSize = DEFAULT_WIDTH_SIZE;
        state.bg = DEFAULT_BG;
      } else {
        state.widthSize = DEFAULT_OTHER_WIDTH_SIZE;
        state.bg = DEFAULT_OTHER_BG;
      }
      state.isHorizontal = data
    },
    updatePlatform(state, data) {
      state.platform = data;
    },
    updateGameId(state, data) {
      state.gameId = +data;
    },
    updateGameLink(state, src) {
      if (!/^(\/|http)/.test(src)) {
        src = '//' + src;
      }
      state.gameLink = src;
    },
    resetGameLink(state) {
      state.gameLink = '';
    },
    updateUserGameInfo(state, params) {
      state.serverId = params.serverId;
      state.serverName = params.serverName;
      state.roleId = params.roleId;
      state.roleName = params.roleName;
      state.roleLevel = params.level;
    },
    updateFullscreenState(state, res) {
      state.isfullscreen = res;
    },
    upateFullscreenClickState(state, res) {
      state.clickfullscreen = res;
    },
    updateCommonTips(state, { content = '', sure = '' }) {
      state.commonTipsContent = content;
      state.commonTipsSure = sure;
    },
    updateTermState(state, data) {
      state.serverState = data.server_terms == 1;
      state.privacyState = data.privacy_terms == 1;
    },
    updateAgePopupState(state, data) {
      state.agePopupState = data;
    },
    updateAsidePopupState(state, data) {
      state.asidePopupState = data;
    },
    updateQrCodeVersion(state) {
      state.qrCodeVersion = new Date().getTime();
    },
    updateVerifiedPopupState(state, data) {
      state.verifiedPopupState = data;
    },
  },

  actions: {
    _sleep({}, time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, +time * 1000);
      });
    },
    // 获取游戏链接
    getGameLink({ commit, state, dispatch }, isSecond) {
      return new Promise((resolve) => {
        if (!isSecond) {
          getGameLinkTimes = 0;
        }
        let _beginTime = new Date().getTime();
        let params = {
          game_id: state.gameId,
          is_fb:
            window.isFbCanvas || globalConfig.getHrefParam('fbcanvas') || '0',
          language: state.language.lang,
          appLanguage: state.language.lang,
          location: globalConfig.getHrefParam('location'),
        };
        if (globalConfig.getHrefParam('pt') === 'mobage') {
          params.uid = globalConfig.getHrefParam('uid') || '';
          params.accessToken = globalConfig.getHrefParam('accessToken') || '';
          if (!globalConfig.getHrefParam('isMobile') == 1) {
            params.mobagePC = 1;
          }
        }
        getJSON(ajaxUrl[state.platform].enter.enterGame, params).then((res) => {
          if (res.data.enterUrl) {
            commit('updateGameLink', res.data.enterUrl);
            resolve();
          } else {
            // console.error(`get_game_link_err_${getGameLinkTimes + 1}`);
            // 避免后端接口无法获取登录态（bug）隔一秒多请求一次
            if (!getGameLinkTimes) {
              getGameLinkTimes++;
              dispatch('_sleep', 1)
                .then(() => {
                  return dispatch('getGameLink', true);
                })
                .then(resolve, resolve);
            } else {
              resolve();
            }
          }
        });
      });
    },
    // 进入游戏回调
    enterServerReport({ state }, param) {
      return new Promise((resolve) => {
        getJSON(ajaxUrl[state.platform].enter.enterServer, {
          game_server_sid: param.serverId || '',
          language: state.language.lang,
          appLanguage: state.language.lang,
          game_id: state.gameId,
          roleId: param.roleId || '',
          roleName: param.roleName || '',
          roleLevel: param.level || '',
          loginName: state.login.userName,
          uid: state.login.userId,
        }).then(() => {
          resolve();
        });
      });
    },
    getTermState({ commit, state }) {
      return new Promise((resolve) => {
        if (!ajaxUrl[state.platform].other.getTermsState) {
          resolve();
          return;
        }
        getJSON(ajaxUrl[state.platform].other.getTermsState, {
          gameId: state.gameId,
          language: state.language.lang,
          appLanguage: state.language.lang,
        }).then((res) => {
          commit('updateTermState', res.data);
          resolve();
        });
      });
    },
    // 创角上报
    createRoleReport({ state }, param) {
      return new Promise((resolve) => {
        const timeStamp = Date.parse(new Date())
        getJSON(ajaxUrl[state.platform].other.createRole, {
          game_server_sid: param.serverId || '',
          language: state.language.lang,
          appLanguage: state.language.lang,
          game_id: state.gameId,
          roleId: param.roleId || '',
          roleName: param.roleName || '',
          loginName: state.login.userName,
          uid: state.login.userId,
          sign: md5(state.login.userName + state.login.userId + state.gameId + timeStamp),
          timeStamp: timeStamp
        }).then(() => {
          resolve();
        });
      });
    },
  },
  modules: {
    login,
    store,
    downAgift,
    language,
    other,
    gclidAnaly,
    mobage: globalConfig.getThirdPlatformType() === 'mobage' ? mobage : {},
  },
};
