<template>
  <div class="game-content no-margin">
    <!-- 遮罩开始 -->
    <transition name="fade">
      <em class="mask" v-show="asidePopupState"></em>
    </transition>
    <!-- 遮罩结束 -->

    <!-- 侧拉栏开始 -->
    <transition name="slide-fade">
      <AsidePopup v-show="asidePopupState">
        <template>
          <AsideMobage></AsideMobage>
        </template>
      </AsidePopup>
    </transition>
    <!-- 侧拉栏结束 -->

    <!-- 浮标开始 -->
    <FloatIcon v-if="showFloatIco"></FloatIcon>
    <!-- 浮标结束 -->

    <!-- 研发游戏界面开始 -->
    <iframe
      :src="gameLink"
      class="iframe j-gamepage-iframe"
      v-show="!!gameLink"
      ref="curGamePage"
      @load="gameLoad"
      id="game-iframe"
      title=""
    ></iframe>
    <!-- 研发游戏界面结束 -->

    <!-- 用于移动端的中间登录页 -->
    <Nbpf v-if="showNbpf"></Nbpf>

    <!-- 游戏开始前的统一协议 -->
    <MobageTips></MobageTips>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'
// mixin
import SdkListener from '../mixins/SdkListener'
import mobage from '../mixins/mobage'
import Init from '../mixins/Init'
// component
import Nbpf from './Nbpf'
import AsidePopup from './AsidePopup'
import AsideMobage from './commission/AsideMobage'
import FloatIcon from './FloatIcon'
import MobageTips from './commission/MobageTips'

export default {
  name: 'MobageIframe',
  mixins: [Init, SdkListener, mobage],
  components: {
    Nbpf,
    AsidePopup,
    AsideMobage,
    FloatIcon,
    MobageTips
  },
  data() {
    return {
      showFloatIco: false
    }
  },
  computed: {
    ...mapGetters([
      'showNbpf',
      'nbpfLoginStatus',
      'gameLink',
      'asidePopupState',
      'lang',
      'gameId',
      'letter',
      'userId'
    ])
  },
  methods: {
    updateLoginStatus() {
      let userInfo = {
        LOGIN_ACCOUNT: decodeURIComponent(
          globalConfig.getHrefParam('loginAccount')
        ),
        UID: decodeURIComponent(globalConfig.getHrefParam('uid'))
      }
      this.$store.commit('updateUserInfo', userInfo)
    },
    initFloatIcoState() {
      let thirdPlatformConfig = window.pageInfo.thirdPlatformConfig
      let { floatIcon } = thirdPlatformConfig.mobage || {
        floatIcon: false
      }
      this.showFloatIco = floatIcon
    }
  },
  watch: {
    // 移动端监听nbpf登录后就请求游戏链接
    nbpfLoginStatus(newVal) {
      globalConfig.log('nbpf是否登录了' + newVal)
      !this.IS_MOBAGE_PC && newVal && this.$store.dispatch('getGameLink')
    }
  },
  created() {
    if (this.IS_MOBAGE_PC) {
      // 经过mobage中xml的处理此时pc已经登录，初始化状态
      this.updateLoginStatus()
      this.$store.dispatch('getGameLink')
    } else {
      // 开始移动端登录流程
      this.$store.commit('updateShowNbpf', true)
    }
    this.initFloatIcoState()
  }
}
</script>

<style lang="scss" scoped></style>
