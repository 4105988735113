import Vue from 'vue'

let throttlingInterval = 800
export const DISABLE_EVENT = 'disableToClick'
const EventBus = new Vue()

Function.prototype.clickThrottling = function (node) {
  let time = throttlingInterval
  let oldFunction = this
  return function () {
    let args = Array.prototype.slice.call(arguments)
    let asyncEvent = node.elm.dataset['asyncebtn']
    if (asyncEvent !== undefined) {
      if (!node.elm.calling) {
        node.elm.calling = true
        node.elm.className += ` ${DISABLE_EVENT}`
        asyncEvent = asyncEvent || DISABLE_EVENT
        EventBus.$on(asyncEvent, function () {
          node.elm.calling = false
          node.elm.className = node.elm.className.replace(
            ` ${DISABLE_EVENT}`,
            ''
          )
        })
        return oldFunction.apply(this, args)
      }
    } else if (!node.elm.calling) {
      node.elm.calling = true
      node.elm.className += ' clicking'
      setTimeout(() => {
        node.elm.calling = false
        if (node.elm) {
          node.elm.className = node.elm.className.replace(' clicking', '')
        }
      }, time)
      return oldFunction.apply(this, args)
    }
  }
}

/**
 * 应为 vnode 是一个组件经过render 之后的整棵树，而不是其中一个节点。 为了给没个节点的click 事件做节流，
 * 需要遍历树的所有节点，并且判定该节点是否绑定了click 事件。
 * 下面使用的广度优先遍历的方式。 如果判定某个节点已经进行过节流，则，该节点和其所有的子节点讲
 * 不在处理和遍历，减少处理事件。
 */

let pathChildNode = function (vnode) {
  let nodes = [vnode]
  while (nodes.length > 0) {
    let node = nodes.shift()
    if (undefined !== node.children && !node.children.throttled) {
      nodes = nodes.concat(node.children)
    }
    if (
      node.data &&
      node.data.on &&
      'function' == typeof node.data.on.click &&
      !node.throttled
    ) {
      node.data.on.click = node.data.on.click.clickThrottling(node)

      node.throttled = true
    }
  }
}

export default {
  install(Vue) {
    let _oldRender = Vue.prototype._render
    /**
     *
     * 之所以选择_render，是 每次构建vnode 之后，新的节点都是新绑定oncick 事件
     * 因为vue 引入了宏任务和微任务，在处理双向绑定的时候，针对所有的methods
     * 都做了AOP 的处理，包装了其他功能。Vue.prototype.$on 处理的是vue 的自定义事件
     * 而不是dom 的事件，所以针对Vue.prototype.$on  起不到作用。
     *
     */
    Vue.prototype._render = function () {
      let args = Array.prototype.slice.call(arguments)
      let vnode = _oldRender.apply(this, args)
      pathChildNode(vnode)
      return vnode
    }

    Vue.prototype.$clearDisable = function (type) {
      clearDisable(type)
    }
  },
  setThrottlingTime(time) {
    throttlingInterval = time
  }
}

export let clearDisable = function (type) {
  EventBus.$emit(type || DISABLE_EVENT)
}
