<template>
  <div
    class="tp-wrap"
    :class="{ bgColor: pcIsHorizontal || mobileIsLandscape }"
  >
    <iframe
      :src="tpLink"
      class="tp-page"
      id="tp-page"
      ref="tpIframe"
      @load="setLoaded"
      :class="{ subWidth: pcIsHorizontal, subHeight: mobileIsLandscape }"
      title="tp"
    ></iframe>
    <Webview
      v-if="webview.openWebView"
      :link="webview.link"
      @closeWebview="webview.openWebView = false"
    ></Webview>
  </div>
</template>

<script>
import globalConfig, {
  H5_DEVICE_PLATE,
  EXE_DEVICE_PLATE
} from '../../utiles/globalConfig'
import {
  storeHideWithinDays,
  getHasOverOneDay,
  getMaxTimesInDay
} from '../../tca/action/actionSdkPopup/storager'
import Webview from './Webview'
import { mapGetters } from 'vuex'
import { sendStatistics } from '../../tca/action/actionSdkPopup/statistics'
import { checkIsElectron } from '../../utiles/index'
import Exe from '../../mixins/Exe'
import actionSdkPopup from '../../tca/action/actionSdkPopup'
export default {
  mixins: [Exe],
  props: ['tpLink', 'tpInitInfo', 'pcIsHorizontal', 'mobileIsLandscape'],
  data() {
    return {
      webview: {
        openWebView: false,
        link: ''
      },
      isElectron: checkIsElectron()
    }
  },
  computed: {
    ...mapGetters([
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'lang',
      'userName',
      'userId',
      'roleLevel',
      'letter',
      'gclid',
      'cid',
      'scid',
      'fbc',
      'fbp',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname'
    ]),
    packageName() {
      return (
        globalConfig.getHrefParam('packageName') || window.pageInfo.packageName
      )
    }
  },
  watch: {
    tpLink(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.sendStatistics({
          eventName: 'preload_window'
        })
      }
    }
  },
  created() {
    let self = this
    window.addEventListener('message', function (res) {
      if (!self.tpLink) {
        return
      }
      if (
        res.origin == new URL(self.tpLink).origin ||
        (self.tpInitInfo &&
          self.tpInitInfo.adList &&
          self.tpInitInfo.adList.length &&
          res.origin == new URL(self.tpInitInfo.adList[0].popupLink).origin)
      ) {
        let method = res.data.method
        if (method && self[method]) {
          if (res.data.param) {
            self.checkIsJson(res.data.param)
              ? self[method](JSON.parse(res.data.param))
              : self[method](res.data.param)
          } else {
            self[method]()
          }
        }
      }
    })
  },
  methods: {
    triggerBridgeReady() {
      this.tpMethodCallback({ callback: 'triggerBridgeReady' })
    },
    tpMethodCallback(param, callbackParams) {
      param &&
        param.callback &&
        this.$refs.tpIframe.contentWindow.postMessage(
          {
            processFn: 1,
            processFnName: param.callback,
            params: callbackParams || ''
          },
          '*'
        )
    },
    onPopupWindowFinished() {
      this.$emit('showTp')
      this.sendStatistics({
        popupIds: this.tpInitInfo.adList.map(item => item.popupId).join('|'),
        eventName: 'show_window'
      })
    },
    initPopupInfo(param) {
      this.tpMethodCallback(param, this.tpInitInfo)
    },
    openBrowser(param) {
      if (param && param.url) {
        const url = this.getOpenLink(param.url, !!param.appendParams || false)
        if (this.isElectron) {
          this.exeOpenNewWindow(this.dirHref(url))
        } else {
          window.open(url, '_blank')
        }
      }
    },
    openWebView(param) {
      if (param && param.url) {
        const url = this.getOpenLink(param.url, !!param.appendParams || false)
        window.open(url, '_blank')
      }
    },
    trackEvent(param) {
      if (
        param &&
        param.eventName &&
        param.eventName === 'custom_popup_window'
      ) {
        this.sendStatistics({
          ...param.eventValue,
          eventName: param.eventKey
        })
      }
    },
    notifyVisit(param) {
      this.sendStatistics({
        eventName: 'visit',
        ...param
      })
      actionSdkPopup.cancelAutoDismiss()
    },
    closeWebview() {
      this.$emit('hideTpPopup')
    },
    hideWindowWithinDays(param) {
      let isChecked = !!param.checked || false
      if (isChecked) {
        storeHideWithinDays(this.tpInitInfo.hideWithinDays)
        this.sendStatistics({
          ...param,
          eventName: 'within_days_hide',
          hide_days: param.days ? param.days : 0,
          show_times_in_a_day: getHasOverOneDay() ? getMaxTimesInDay() : 0 //计算上一次显示的时间，然后跟这一次的时间对比，如果是同一天，就+1，如果不是同一天，则重置为0
        })
      } else {
        storeHideWithinDays(0)
      }
    },
    getOpenLink(url, isAppendParams) {
      if (!url) {
        return ''
      }
      if (!isAppendParams) {
        return url
      }
      return globalConfig.hrefAddParams(url, {
        gameId: this.gameId,
        serverId: this.serverId,
        uid: this.userId,
        userId: this.userId,
        roleName: this.roleName,
        roleId: this.roleId,
        loginAccount: this.userName,
        userName: this.userName,
        language: this.lang,
        appLanguage: this.lang,
        devicePlate: this.isElectron ? EXE_DEVICE_PLATE : H5_DEVICE_PLATE,
        packageName: this.packageName,
        roleLevel: this.roleLevel,
        userMode: window.pageInfo['userMode'],
        isWebTp: 1
      })
    },
    copyText(param) {
      if (param && param.content) {
        if (this.isElectron) {
          if (window.ipcFn && window.ipcFn.copyText) {
            window.ipcFn.copyText(param.content).then(() => {
              alert(this.letter.copy_success)
            })
          }
        } else {
          this.$copyText(param.content).then(() => {
            alert(this.letter.copy_success)
          })
        }
      }
    },
    sendStatistics(params) {
      sendStatistics({
        ...params,
        eventName: params.eventName,
        loginAccount: this.userName,
        serverId: this.serverId,
        roleName: this.roleName,
        roleId: this.roleId,
        userId: this.userId,
        gclid: this.gclid,
        cid: this.cid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname,
        language: this.lang,
        appLanguage: this.lang,
        gameId: this.gameId,
        packageName: this.packageName,
        popupTcaActionId: this.tpInitInfo.popupTcaActionId || '',
        popupEvent: this.tpInitInfo.popupEvent || ''
      })
    },
    checkIsJson(str) {
      if (typeof str === 'string') {
        try {
          let obj = JSON.parse(str)
          return obj && typeof obj == 'object'
        } catch (e) {
          return false
        }
      }
      return false
    },
    setLoaded() {
      if (!!this.tpLink) {
        this.triggerBridgeReady()
      }
    }
  },
  components: {
    Webview
  }
}
</script>

<style scoped lang="scss">
.tp-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &.bgColor {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.tp-page {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: transparent;
  border: 0;
  &.subWidth {
    width: 40%;
  }
  &.subHeight {
    height: 250px;
  }
}
</style>
