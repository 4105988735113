<template>
  <div class="gm99-login-box">
    <div class="login-container" v-show="isLoginPannel">
      <div class="input-container">
        <em class="user icon"></em>
        <input
          type="text"
          class="input"
          placeholder="請輸入帳號"
          v-model="userAccount"
          @blur="blurUserAccount(false)"
        />
      </div>

      <div class="input-container">
        <em class="pass icon"></em>
        <input
          type="password"
          class="input pass-input"
          placeholder="請輸入密碼"
          v-model="userPassword"
          @blur="blurUserPassword(false)"
        />

        <div class="forget icon">
          <a
            target="_blank"
            class="forget-btn"
            href="//service.gm99.com/center?type=password"
          >
            忘記密碼
          </a>
        </div>
      </div>

      <div class="input-container" v-show="loginNeedCode">
        <em class="code icon"></em>
        <input
          v-model="userCode"
          type="text"
          class="input img"
          placeholder="請輸入驗證碼"
          @blur="blurUserCode(false)"
        />
        <img :src="loginCodeUrl" class="code-img" alt="" />
      </div>

      <button class="login-btn" @click="login">登入</button>
      <p class="type-tips">
        <span>沒有帳號？馬上</span>
        <button class="go-other-type" @click="switchToRegister">
          免費註冊
        </button>
      </p>
      <div class="line">
        <hr class="l" />
        <span>或者</span>
        <hr class="r" />
      </div>
      <div class="other-login">
        <a
          :href="'javascript:;'"
          @click="otherLogin(item)"
          class="link"
          :class="`${item} j-account-${item}-btn`"
          v-for="(item, key) in thirdList"
          :key="key"
        ></a>
      </div>
    </div>
    <div class="register-container" v-show="!isLoginPannel">
      <div class="input-container">
        <em class="user icon"></em>
        <input
          type="text"
          class="input"
          placeholder="請輸入帳號"
          v-model="userAccount"
          @blur="blurUserAccount(false)"
        />
      </div>

      <div class="input-container">
        <em class="pass icon"></em>
        <input
          type="password"
          class="input"
          placeholder="請輸入密碼"
          v-model="userPassword"
          @blur="blurUserPassword(false)"
        />
      </div>

      <div class="input-container">
        <em class="pass icon"></em>
        <input
          type="password"
          class="input"
          placeholder="再次輸入密碼"
          v-model="userRePassword"
          @blur="blurUserRePassword(false)"
        />
      </div>

      <div class="input-container" v-show="registerNeedCode">
        <em class="code icon"></em>
        <input
          type="text"
          class="input img"
          placeholder="請輸入驗證碼"
          v-model="userCode"
          @blur="blurUserCode(false)"
        />
        <img :src="registerCodeUrl" class="code-img" alt="" />
      </div>

      <p class="term">
        <em class="kuang" :class="isAgreeTerm ? 'checked' : 'uncheck'"></em>
        <span>我同意</span>
        <a class="link" target="_blank" :href="termUrl">G妹遊戲服務條款</a>
        <span>和</span>
        <a class="link" target="_blank" :href="privicyUrl">隱私條款</a>
      </p>
      <button class="login-btn register-btn" @click="register">註冊</button>
      <p class="type-tips">
        <span>已有帳號？</span>
        <button class="go-other-type" @click="switchToLogin">立即登錄</button>
      </p>
    </div>
    <transition name="fade">
      <div class="tips-box" v-show="ajaxTips">
        <p>{{ ajaxTips }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import AccountLogin from '../../mixins/AccountLogin.js'
import OtherLogin from '../../mixins/OtherLogin.js'
import LinkTermUrl from '../../mixins/LinkTermUrl'
export default {
  data() {
    return {
      isTouristLogin: window.pageInfo['isTourist']
    }
  },
  mixins: [AccountLogin, OtherLogin, LinkTermUrl],
  watch: {
    ajaxTips(newV, oldV) {
      if (newV !== oldV && newV) {
        setTimeout(() => {
          this.loginRequestTips = ''
          this.registerRequestTips = ''
          this.userAccountTips = ''
          this.userPasswordTips = ''
          this.userRePasswordTips = ''
          this.userCodeTips = ''
        }, 2000)
      }
    }
  },
  computed: {
    ajaxTips() {
      return (
        this.userAccountTips ||
        this.userPasswordTips ||
        this.userRePasswordTips ||
        this.userCodeTips ||
        this.loginRequestTips ||
        this.registerRequestTips
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
$inputWidth: 440vh;
.gm99-login-box {
  width: 580vh;
  height: 843vh;
  max-width: 99%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  background: url($img + 'login-bg.png') no-repeat top center;
  background-size: contain;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding-top: 250vh;
}
.login-container,
.register-container {
  width: 100%;
  text-align: center;
}
.login-container {
  .pass-input {
    width: 312vh !important;
    margin-right: 10vh;
  }

  .forget {
    position: relative;
    &:hover {
      cursor: pointer;
      .forget-btn {
        display: block;
      }
    }
  }

  .forget-btn {
    display: none;
    line-height: 53vh;
    color: $orange;
    position: absolute;
    right: 100%;
    top: -12vh;
  }

  .line {
    margin: 20vh auto 20vh;
    position: relative;
    span {
      font-size: 22vh;
      color: #ccc;
    }
    hr {
      position: absolute;
      width: 160vh;
      top: 50%;
      margin: 0;
      border: 1px solid #ddd;
    }
    .l {
      left: 76vh;
    }
    .r {
      right: 76vh;
    }
  }
  .other-login {
    .link {
      display: inline-block;
      vertical-align: middle;
      margin: auto 20vh;
    }
  }
}

.register-container {
  .term {
    width: $inputWidth;
    margin: auto auto 26vh;
    text-align: left;
    .kuang,
    span,
    .link {
      display: inline-block;
      vertical-align: middle;
      font-size: 16vh;
    }
    .kuang {
      margin-right: 10vh;
    }
    .link {
      color: $orange;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.input-container {
  background: white;
  width: $inputWidth;
  border: 1px solid $orange;
  border-radius: 8px;
  display: block;
  margin: auto auto 20vh;
  padding: 6vh 0;
  .icon,
  .input,
  .code-img {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    width: 36vh;
    height: 36vh;
  }
  .input {
    border: none;
    width: $inputWidth - 90vh;
    height: 44vh;
    margin-left: 10vh;
    outline: none;
    font-size: 20vh;
    color: #666;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #666;
  }
  .input.img {
    width: $inputWidth - 210vh;
  }
  .code-img {
    width: 120vh;
  }
}

.reg-tips {
  margin: 0 auto;
  height: 26vh;
  line-height: 26vh;
  font-size: 14vh;
  color: $orange;
  text-align: center;
  width: $inputWidth;
}

.login-btn {
  background: $orange;
  color: white;
  font-size: 22vh;
  text-align: center;
  width: 44%;
  line-height: 270%;
  border-radius: 100vh;
  margin-top: 10vh;
  &:hover {
    cursor: pointer;
  }
}
.type-tips {
  color: #aaa;
  font-size: 18vh;
  margin: 14vh auto;
  .go-other-type,
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .go-other-type {
    color: $orange;
    padding: 0;
    margin: 0;
    font-size: 18vh;
    &:hover {
      cursor: pointer;
    }
  }
}

.tips-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  p {
    margin: 10vh auto;
    padding: 0 10vh;
    color: white;
    text-align: center;
    font-size: 16vh;
  }
}

.user {
  background: url($img + 'userNameIcon.png') no-repeat top center;
  background-size: contain;
}

.pass {
  background: url($img + 'passwordIcon.png') no-repeat top center;
  background-size: contain;
}

.code {
  background: url($img + 'codeIcon.png') no-repeat top center;
  background-size: contain;
}

.forget {
  background: url($img + 'forget.png') no-repeat top center;
  background-size: contain;
  width: 27vh !important;
  height: 27vh !important;
}

.forget-btn {
  background: url($img + 'forget-bg.png') no-repeat top center;
  background-size: contain;
  width: 216vh;
  height: 53vh;
}

.facebook {
  background: url($img + 'fbLogin.png') no-repeat top center;
  background-size: contain;
  width: 181vh;
  height: 36vh;
}

.tourist {
  background: url($img + 'touristLogin.png') no-repeat top center;
  background-size: contain;
  width: 129vh;
  height: 36vh;
}

.uncheck {
  background: url($img + 'checkBox.png') no-repeat top center;
  background-size: contain;
  width: 28vh;
  height: 28vh;
}

.checked {
  background: url($img + 'checked.png') no-repeat top center;
  background-size: contain;
  width: 28vh;
  height: 28vh;
}
.register-btn {
  margin-top: 0 !important;
}
</style>
