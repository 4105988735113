import { encryption } from '../utiles'
import globalConfig from '../utiles/globalConfig'
import { getJSON } from './ultile'

import ajaxUrl from '../config/ajax'
import config from '../config/index'

import { getFingerprint } from '../utiles/thirdPartySdkFn'

let newRegisterUserFn = data => {
  let existObj = JSON.parse(window.localStorage.getItem('registerObj') || '{}')
  let isNew = +data.FIRST_PLAY === 1
  let userId = data.ID
  let now = Date.now()
  let curUser = existObj[userId]
  if (!curUser) {
    let timestamp =
      new Date(new Date().toLocaleDateString()).getTime() +
      24 * 60 * 60 * 1000 -
      1
    if (!isNew) {
      timestamp =
        new Date(
          new Date(data.REGISTER_TIME.replace(/-/g, '/')).toLocaleDateString()
        ).getTime() +
        24 * 60 * 60 * 1000 -
        1
    }
    if (isNew && now <= timestamp && window.dataLayer) {
      window.dataLayer.push({
        event: 'new_register',
        is_new: true,
        userName: data.LOGIN_ACCOUNT
      })
    }
    existObj[userId] = {
      isNew,
      isSend: true,
      registerTime: timestamp
    }
    window.localStorage.setItem('registerObj', JSON.stringify(existObj))
  } else {
    if (!curUser.isNew) {
      return
    }
    let registerTime = curUser.registerTime
    let isNew = now <= registerTime
    curUser.isNew = isNew
    existObj[userId] = curUser
    window.localStorage.setItem('registerObj', JSON.stringify(existObj))
  }
}

export default {
  state: {
    thirdList: [],

    appId: '',

    displayName: '',
    userName: '',
    userPhoto: '',
    userId: '',
    userFbName: '',

    loginNeedCode: false,
    registerNeedCode: false,

    codeTimestamp: new Date().getTime().toString(16),

    exeToken: ''
  },
  getters: {
    userId: state => state.userId,
    displayName: state => state.displayName,
    userName: state => state.userName,
    userFbName: state => state.userFbName,
    userPhoto: state => state.userPhoto,
    appId: state => state.appId,
    thirdList: state => {
      return state.thirdList.filter((item) => {
        // H5还没有开发支持的登录方式，先过滤掉
        return !['phone', 'email'].includes(item);
      })
    },

    loginNeedCode: state => state.loginNeedCode,
    registerNeedCode: state => state.registerNeedCode,

    codeTimestamp: state => state.codeTimestamp,
    exeToken: state => state.exeToken
  },
  mutations: {
    updateThirdList(state, list) {
      state.thirdList = list
    },
    // 更新用户信息
    updateUserInfo(state, data) {
      state.displayName = data.DISPLAY_NAME
      state.userName = data.LOGIN_ACCOUNT
      state.userId = data.ID || data.USER_ID || data.UID
      state.userFbName = data.FB_NAME || ''
      globalConfig.setIsFirstPlay(
        data.FIRST_PLAY || false,
        data.ID || data.USER_ID || data.UID
      )
      globalConfig.setUserName(data.LOGIN_ACCOUNT)
    },
    updateUserPhoto(state, data) {
      state.userPhoto = data
    },
    updateFbAppId(state, data) {
      state.appId = data.length > 0 ? data : window.pageInfo['appid']
    },
    updateCodeState(state, data) {
      state.loginNeedCode = !!data.H5_LOGIN_NEED_CKCODE
      state.registerNeedCode = !!data.H5_REG_NEED_CKCODE
    },
    updateCodeTimestamp(state) {
      state.codeTimestamp = new Date().getTime().toString(16)
    },
    resetUserInfo(state) {
      state.displayName = ''
      state.userName = ''
      state.userPhoto = ''
      state.userId = ''
    },
    updateExeToken(state, data) {
      state.exeToken = data
    }
  },
  actions: {
    // 检查登录态
    judgeIsLogin(
      { commit, dispatch, state, rootState },
      { isCheck, isFirst } = {}
    ) {
      return new Promise(resolve => {
        getJSON(ajaxUrl[rootState.platform].login.checkLogin, {
          h5_request: 1,
          done_login: isCheck ? 0 : 1,
          game_id: rootState.gameId
        }).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            window.loginAccount = res.res.data.LOGIN_ACCOUNT
            window.pageInfo['isAutoTouristLogin'] = false // 一旦登录过，关闭自动游客登录
            if (isFirst) {
              globalConfig.gaStatistics('login_success', {
                login_type: '自动登录'
              })
            }
            commit('updateVerifiedPopupState', +res.res.data.NEED_COMPLETE || 0)
            commit('updateUserInfo', res.res.data)
            if (rootState.platform == 'global') {
              newRegisterUserFn(res.res.data)
            }
            // if (isFirst) {
            //   try {
            //     globalConfig.gaStatistics('login_success', {
            //       login_type: '自动登录',
            //     });
            //   } catch (e) {}
            // }
          } else {
            commit('resetUserInfo')
            commit('resetGameLink')
            commit('updateAsidePopupState', false)
            try {
              globalConfig.gaStatistics('login_un')
            } catch (e) {}
          }
          // 登录态接口返回后，再开始请求二维码接口
          if (isCheck) {
            commit('updateQrCodeVersion')
          }
          resolve(parseInt(res.res.result, 10) === 1)
        })
      })
    },
    getThirdLoginList({ commit, rootState }) {
      return new Promise(resolve => {
        if (!ajaxUrl[rootState.platform].login.loginType) {
          commit(
            'updateThirdList',
            config[rootState.platform].login.loginTypeDefault
          )
          resolve()
          return
        }
        getJSON(ajaxUrl[rootState.platform].login.loginType, {
          gameId: rootState.gameId,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang
        }).then(res => {
          if (res.res.result == 1) {
            let list = []
            if (
              Object.prototype.toString.call(res.res.data) === '[object Array]'
            ) {
              res.res.data.map(item => {
                if (!/^(gamecenter|migrate_code)$/.test(item)) {
                  list.push(item)
                }
              })
            }
            commit('updateThirdList', list)
          }
          resolve()
        })
      })
    },
    getFBloginAppId({ commit, rootState }) {
      return new Promise(resolve => {
        if (!ajaxUrl[rootState.platform].login.getFbAppId) {
          commit(
            'updateFbAppId',
            config[rootState.platform].login.fbAppIdDefault
          )
          resolve()
          return
        }
        getJSON(ajaxUrl[rootState.platform].login.getFbAppId, {
          game_id: rootState.gameId
        }).then(res => {
          commit('updateFbAppId', res.data)
          resolve()
        })
      })
    },
    accountLogin(
      { rootState },
      { userAccount = '', userPassword = '', code = '', isRemember = true }
    ) {
      return new Promise(async (resolve, reject) => {
        const params = {
          uname: userAccount,
          password: encryption(userPassword),
          ckcode: code,
          forward: encodeURIComponent(window.location.href),
          // forward: param.forward || forward,
          // "g_recaptcha": param.token,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          remember: +!!isRemember,
          game_id: rootState.gameId,
          timeZone: encodeURIComponent(globalConfig.getTimeZone()),
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname,
          // 谷歌验证token
          google_recaptcha_token: window.googleVerificationToken || '',
          machineId: await getFingerprint()
        }
        getJSON(ajaxUrl[rootState.platform].login.accountLogin, params).then(
          ({ errmsg, res }) => {
            if (parseInt(res.result, 10) === 1) {
              globalConfig.gaStatistics('login_success', {
                login_type: 'account_login'
              })
              resolve()
            } else {
              globalConfig.gaStatistics('login_fail', {
                login_type: 'account_login',
                msg: errmsg.replace(/\([0-9]+\)/g, '')
              })
              reject({
                msg: errmsg
              })
            }
          }
        )
      })
    },
    accountRegister(
      { rootState },
      { userAccount = '', userPassword = '', userRePassword = '', code = '' }
    ) {
      return new Promise((resolve, reject) => {
        const params = {
          uname: userAccount,
          password: encryption(userPassword),
          repassword: encryption(userRePassword),
          ckcode: code,
          forward: encodeURIComponent(window.location.href),
          // "g_recaptcha": param.token,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          game_id: rootState.gameId,
          timeZone: encodeURIComponent(globalConfig.getTimeZone()),
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname
        }
        getJSON(ajaxUrl[rootState.platform].login.accountRegister, params).then(
          ({ errmsg, res }) => {
            if (parseInt(res.result, 10) === 1) {
              globalConfig.gaStatistics('login_success', {
                login_type: 'account_register'
              })
              resolve()
            } else {
              globalConfig.gaStatistics('login_fail', {
                login_type: 'account_register',
                msg: errmsg.replace(/\([0-9]+\)/g, '')
              })
              reject({
                msg: errmsg
              })
            }
          }
        )
      })
    },
    touristLogin({ rootState }, params) {
      return new Promise((resolve, reject) => {
        getJSON(ajaxUrl[rootState.platform].login.touristLogin, {
          game_id: rootState.gameId,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname,
          ...params
        }).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            try {
              globalConfig.gaStatistics('login_success', {
                login_type: 'tourist'
              })
            } catch (e) {}
            resolve()
          } else {
            reject({ type: 'tourist', msg: res.errmsg })
          }
        })
      })
    },
    fbLogin({ rootState }, params) {
      return new Promise((resolve, reject) => {
        getJSON(ajaxUrl[rootState.platform].login.fbLogin, {
          game_id: rootState.gameId,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname,
          ...params
        }).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            try {
              globalConfig.gaStatistics('login_success', {
                login_type: 'facebook'
              })
            } catch (e) {}
            resolve()
          } else {
            reject({ type: 'facebook', msg: res.errmsg })
          }
        })
      })
    },
    twiLogin({ rootState }, params) {
      return new Promise((resolve, reject) => {
        getJSON(ajaxUrl[rootState.platform].login.twiLogin, {
          game_id: rootState.gameId,
          uid: rootState.login.userId,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname,
          ...params
        }).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            try {
              globalConfig.gaStatistics('login_success', {
                login_type: 'twitter'
              })
            } catch (e) {}
            resolve()
          } else {
            reject({ type: 'twitter', msg: res.errmsg })
          }
        })
      })
    },
    googleLogin({ rootState }, params) {
      return new Promise((resolve, reject) => {
        getJSON(ajaxUrl[rootState.platform].login.gpLogin, {
          game_id: rootState.gameId,
          uid: rootState.login.userId,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname,
          ...params
        }).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            try {
              globalConfig.gaStatistics('login_success', {
                login_type: 'googleplay'
              })
            } catch (e) {}
            resolve()
          } else {
            reject({ type: 'googleplay', msg: res.errmsg })
          }
        })
      })
    },
    logout({ rootState, commit }) {
      return new Promise(resolve => {
        getJSON(ajaxUrl[rootState.platform].login.logout, {}).then(
          ({ errmsg, res }) => {
            if (res.result == 1) {
              commit('resetUserInfo')
              commit('resetGameLink')
              commit('updateAsidePopupState', false)
              globalConfig.gaStatistics('logout')
            }
            resolve()
          }
        )
      })
    },
    checkNeedCode({ rootState, commit }, userAccount) {
      return new Promise(resolve => {
        if (!ajaxUrl[rootState.platform].login.checkCode) {
          resolve()
          return
        }
        getJSON(ajaxUrl[rootState.platform].login.checkCode, {
          uname: userAccount
        }).then(res => {
          commit('updateCodeState', res.data)
          if (res.data.H5_LOGIN_NEED_CKCODE || res.data.H5_REG_NEED_CKCODE) {
            commit('updateCodeTimestamp')
          }
          resolve()
        })
      })
    },
    fbCanvasLogin({ commit, rootState }, { id, token }) {
      return new Promise(resolve => {
        commit(
          'updateUserPhoto',
          `https://graph.facebook.com/${id}/picture?type=square`
        )
        let params = {
          game_id: rootState.gameId,
          accessToken: token,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang,
          appId: window.appInfo.appId,
          // 行销广告参数
          gclid: rootState.gclidAnaly.gclid,
          cid: rootState.gclidAnaly.cid,
          scid: rootState.gclidAnaly.scid,
          fbc: rootState.gclidAnaly.fbc,
          fbp: rootState.gclidAnaly.fbp,
          adid: rootState.gclidAnaly.adid,
          adname: rootState.gclidAnaly.adname,
          camid: rootState.gclidAnaly.camid,
          camname: rootState.gclidAnaly.camname,
          adsetid: rootState.gclidAnaly.adsetid,
          adsetname: rootState.gclidAnaly.adsetname
        }

        getJSON(ajaxUrl[rootState.platform].fbCanvasLogin, params).then(res => {
          if (parseInt(res.res.result, 10) === 1) {
            commit('updateUserInfo', res.res.data)
          }
          resolve()
        })
      })
    },
    exeGetLoginInfo({ rootState, commit }) {
      return new Promise(resolve => {
        getJSON(ajaxUrl[rootState.platform].login.exeCheckLogin, {
          gameId: rootState.gameId,
          game_id: rootState.gameId
        }).then(res => {
          if (res.res.result == 1) {
            commit('updateExeToken', res.data.TOKEN)
          }
          resolve()
        })
      })
    }
  }
}
