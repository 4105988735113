<template>
  <div>
    <em class="h-r"></em>
    <div class="content-box">
      <p class="userInfo">
        <em class="user"></em>
        <span class="name">hi,{{ userName }}</span>
        <!-- 产品不肯所有游戏都加，如果以后其他游戏也要的话，就da产品吧，然后把这个设成配置项 -->
        <button
          class="copy-btn"
          ref="copyBtn"
          type="button"
          v-if="copy_id"
          v-clipboard:copy="userId"
          v-clipboard:success="onCopySucc"
          v-clipboard:error="onCopyError"
        >
          {{ letter.h5_click_copy }}
        </button>
        <span class="userId" v-if="copy_id">uid:{{ userId }}</span>
      </p>
      <a
        :href="getRealLink(item.link)"
        class="btn"
        target="_blank"
        v-for="(item, i) in asideBtnList"
        :key="i"
      >
        <img :src="item.icon" class="btn-icon" alt="" />
        <span class="btn-label">{{ item.label }}</span>
      </a>
      <a class="btn j-aside-service-btn" :href="serviceUrl" target="_blank">
        <img class="btn-icon" src="../../assets/image/service.png" alt="" />
        <span class="btn-label">{{ letter.serviceTitle }}</span>
      </a>
      <a
        class="btn j-aside-favour-btn"
        @click="favourFn"
        :href="'javascript:void(0);'"
      >
        <img class="btn-icon" src="../../assets/image/favour.png" alt="" />
        <span class="btn-label">{{ letter.favour }}</span>
      </a>

      <div class="other-box" v-if="shareList.length">
        <span class="title">{{ letter.share }}</span>
        <a
          href="javascript:void(0);"
          class="link"
          :class="`${item} j-aside-${item}-btn`"
          v-for="(item, key) in shareList"
          @click="share(item)"
          :key="key"
        ></a>
      </div>
      <div class="bottom-box">
        <button class="go-doing logout j-aside-logout-btn" @click="logout">
          {{ letter.logout }}
        </button>
        <p class="other-link-box">
          <a
            :href="termUrl"
            class="other-link"
            target="_blank"
            v-show="serverTermState"
          >
            {{ letter.fwtk }}
          </a>
          <span v-show="serverTermState && privacyTermState">|</span>
          <a
            :href="privicyUrl"
            class="other-link"
            target="_blank"
            v-show="privacyTermState"
          >
            {{ letter.yszc }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LinkTermUrl from '../../mixins/LinkTermUrl'
import Share from '../../mixins/Share'
import globalConfig from '../../utiles/globalConfig'
import Favour from '../../mixins/Favour'
import url from '../../config/url'
export default {
  data() {
    return {
      copy_id: window.pageInfo['copyId'],
      shareList:
        (window.pageInfo['share'] && window.pageInfo['share']['shareList']) ||
        '',
      shareInfo:
        (window.pageInfo['share'] && window.pageInfo['share']['info']) || '',
      asideBtnList: window.pageInfo['asideBtnList'] || []
    }
  },
  mixins: [LinkTermUrl, Share, Favour],
  computed: {
    serviceUrl() {
      return globalConfig.hrefAddParams(url.global.support, {
        gameId: this.gameId,
        serverId: this.serverId,
        userId: this.userId,
        loginAccount: this.userName,
        appLanguage: this.lang,
        language: this.lang,
        roleName: encodeURIComponent(this.roleName),
        roleId: this.roleId,
        packageName:
          globalConfig.getHrefParam('packageName') ||
          window.pageInfo.packageName,
        devicePlate: 'pc',
        remark: '',
        location: window.pageInfo['location'] || '',
        web: 1
      })
    },
    ...mapGetters([
      'userName',
      'userId',
      'letter',
      'serverId',
      'roleId',
      'userName',
      'roleName',
      'isLogin'
    ])
  },
  methods: {
    share(type) {
      globalConfig.gaStatistics('share', { type: type })

      let curData =
        this.shareInfo[this.lang] || this.shareInfo[window.pageInfo['lang']]
      switch (type) {
        case 'facebook':
          return this.facebookShare({
            title: curData.fbTitle,
            descript: curData.fbDescript,
            img: curData.fbImage,
            id: window.pageInfo['appid']
          })
        case 'twitter':
          return this.twitterShare(curData.twitterText)
        case 'line':
          return this.lineShare()
      }
    },
    onCopySucc() {
      alert(this.letter.copy_success)
    },
    onCopyError() {},
    getRealLink(link) {
      return globalConfig.hrefAddParams(link, {
        gameId: this.gameId,
        serverId: this.serverId,
        roleId: this.roleId,
        roleName: encodeURIComponent(this.roleName),
        userId: this.userId,
        loginAccount: this.userName,
        language: this.lang,
        appLanguage: this.lang,
        web: 1,
        devicePlate: window.isPhone ? 'wap' : 'pc'
      })
    },
    ...mapActions(['logout'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.h-r {
  top: 2vh !important;
  background-position: right;
  z-index: -1;
}

.userInfo {
  margin-top: 0;
  margin-bottom: 25vh;
  position: relative;
  overflow: hidden;
}
.userId {
  vertical-align: middle;
  float: right;
  font-size: 22vh;
  display: inline-block;
  color: #333;
}
.user {
  background: url($img + 'user.png') no-repeat center center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  width: 22vh;
  height: 22vh;
  margin-right: 10vh;
  float: left;
  position: relative;
  top: 5vh;
}

.copy-btn {
  border: 1px solid #cd8f17;
  color: #cd8f17;
  border-radius: 100vh;
  margin-left: 10vh;
  display: inline-block;
  vertical-align: middle;
  font-size: 18vh;
  padding: 0 16vh;
  line-height: 160%;
  box-sizing: border-box;
  text-decoration: none;
  float: right;
  &:hover {
    cursor: pointer;
  }
}

.name {
  font-size: 22vh;
  color: #333;
  vertical-align: middle;
  float: left;
}

.logout {
  margin-top: 30vh !important;
  background: url($img + 'logout.png') no-repeat center center;
  height: 110vh;
}

.other-box {
  padding-top: 30vh !important;
  .title {
    margin-top: 0;
    margin-bottom: 20vh;
    height: auto;
  }
}

.bottom-box {
  position: absolute;
  width: 100%;
  padding: 30vh 20vh 0;
  box-sizing: border-box;
  bottom: 100vh;
  left: 0;
}

.other-link-box {
  margin-top: 30vh;
  text-align: center;
  .other-link {
    font-size: 20vh;
    color: #333;
    text-decoration: none;
    padding: 0 18vh;
    transition: all 0.1s;
    &:last-child {
      border-right: none;
    }
    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }
  span {
    color: #333;
  }
}
</style>
