import {
  ADS_EVENTS_TYPE,
  VIDEO_END,
  VIDEO_LOADED,
  VIDEO_LOAD_FAILED,
  VIDEO_PLAY,
  VIDEO_REWARD
} from '../../ads/define'
import globalConfig from '../../utiles/globalConfig'
import {
  hasBindId,
  updateBindId,
  sendRewardeAdsCallback,
  sendCanShowRewardAdsCallback
} from '../../ads/gam/sendMessage'
import { mapGetters } from 'vuex'

// let _excShowAds = null
let rewardedSlot = null

export default {
  computed: {
    pointParams() {
      return {
        eventType: ADS_EVENTS_TYPE,
        loginAccount: this.userName,
        serverId: this.serverId,
        roleId: this.roleId,
        userId: this.userId,
        gclid: this.gclid,
        cid: this.cid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname
      }
    },
    ...mapGetters([
      'gameId',
      'lang',
      'userName',
      'userId',
      'gclid',
      'cid',
      'scid',
      'serverId',
      'roleId',
      'fbc',
      'fbp',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname'
    ])
  },
  methods: {
    // isRewardAdsReady() {
    //   // return typeof _excShowAds == 'function'
    // },
    canShowRewardAds(bindId) {
      // let res = this.isRewardAdsReady()
      updateBindId(bindId)
      sendCanShowRewardAdsCallback({
        res: 1
      })
    },
    createRewardAds() {
      googletag.cmd.push(() => {
        rewardedSlot = googletag
          .defineOutOfPageSlot(
            `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/rewarded`,
            googletag.enums.OutOfPageFormat.REWARDED
          )
          .addService(googletag.pubads())
        googletag.display(rewardedSlot)
      })
    },

    initRewardEvents() {
      let isFinishAds = false
      googletag.cmd.push(() => {
        googletag.pubads().addEventListener('rewardedSlotReady', evt => {
          console.log('广告准备好了2')
          globalConfig.ajaxStatistics({
            eventName: VIDEO_LOADED,
            ...this.pointParams
          })
          evt.makeRewardedVisible()
          globalConfig.ajaxStatistics({
            eventName: VIDEO_PLAY,
            ...this.pointParams
          })
        })

        googletag.pubads().addEventListener('rewardedSlotGranted', _evt => {
          isFinishAds = true
          globalConfig.ajaxStatistics({
            eventName: VIDEO_REWARD,
            ...this.pointParams
          })
          globalConfig.gaStatistics('get_ad_reward')
        })

        googletag.pubads().addEventListener('rewardedSlotClosed', _evt => {
          if (rewardedSlot) {
            googletag.destroySlots([rewardedSlot])
          }

          if (isFinishAds) {
            sendRewardeAdsCallback(1, 1)
          } else {
            sendRewardeAdsCallback(0, -2)
          }
          globalConfig.ajaxStatistics({
            eventName: VIDEO_END,
            ...this.pointParams
          })
          isFinishAds = false
        })
      })
    },

    excShowRewardAds(bindId) {
      if (hasBindId()) return
      updateBindId(bindId)
      this.createRewardAds()
    }

    // excShowAds() {
    //   _excShowAds()
    //   globalConfig.ajaxStatistics({
    //     eventName: VIDEO_PLAY,
    //     ...this.pointParams
    //   })
    // }
  }
}
