<template>
  <div class="slfuntap-vn-footer" :class="{ mobile: ismobile }">
    <img
      src="https://gimages.slfuntap.com/aws_s3/img?s=/platform/one_image/2020/09/1600398355820.png"
      class="logo"
      alt=""
    />
    <div>
      <p class="link-box">
        <a class="link" target="_blank" :href="supportUrl">Hỗ Trợ</a>
        |
        <a
          class="link"
          target="_blank"
          href="//www.slfuntap.com/sea/slvn/list.html?language=vi-VN&ids=325"
        >
          Cài đặt
        </a>
        |
        <a class="link" target="_blank" :href="termUrl">Điều khoản</a>
        |
        <a
          class="link"
          target="_blank"
          href="https://gpassport.slfuntap.com/center/ServicePrivacy/privacy?gameId=195"
        >
          Privacy Policy
        </a>
      </p>

      <p class="text">
        Hotline: 1900 636 452
        <br />
        Email: Hotro@funtap.vn
        <br />
        Bản quyền © {{ copyrightTime }} Funtap.
        <br />
        Thời gian:
        <span style="color: red">8:00 - 22:00 tất cả các ngày</span>
        (GMT+7)
        <br />
        Chơi quá 180 phút một ngày sẽ ảnh hưởng xấu đến sức khỏe
        <br />
        Giấy phép G1 số: 201/GP-BTTTT ngày 14/5/2018
        <br />
        Chịu trách nhiệm nội dung: Ông Nguyễn Quang Thịnh
        <br />
        Tầng 4 - Tòa nhà Tây Hà - 19 Tố Hữu - Phường Trung Văn - Quận Nam Từ
        Liêm - Hà Nội
      </p>
    </div>
    <img
      src="https://gimages.slfuntap.com/aws_s3/img?s=/platform/one_image/2020/09/16003984039670.png"
      class="j18"
      alt=""
    />
  </div>
</template>

<script>
import LinkTermUrl from '../../mixins/LinkTermUrl'
import { mapGetters } from 'vuex'
import globalConfig from '../../utiles/globalConfig'
const mainHost = globalConfig.getMainHost()
export default {
  data() {
    return {
      ismobile: window.isPhone,
      copyrightTime: window.copyrightTime
    }
  },
  computed: {
    supportUrl() {
      return `//gabres.${mainHost}/web/support.html?gameId=${this.gameId}&appLanguage=${this.lang}&language=${this.lang}&serverId=${this.serverId}&roleId=${this.roleId}&roleName=${this.roleName}`
    },
    ...mapGetters(['gameId', 'lang', 'serverId', 'roleId', 'roleName'])
  },
  mixins: [LinkTermUrl]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen.scss';
.slfuntap-vn-footer {
  background: black;
  padding: 40px 0 30px;
  text-align: center;
  position: relative;
  .text,
  .link-box {
    color: white;
    font-size: 13px;
    line-height: 200%;
    margin: 0;
    max-width: 100%;
  }
  .link {
    color: white;
    text-decoration: none;
    padding: 0 14px;
    position: relative;
    &:hover {
      color: #ff9500;
      cursor: pointer;
    }
  }
  .logo {
    position: absolute;
    width: 149px;
    height: 41px;
    top: 34px;
    left: 15%;
  }
  .j18 {
    position: absolute;
    width: 60px;
    height: 86px;
    top: 34px;
    right: 15%;
  }
  &.mobile {
    .link,
    .text {
      font-size: 12px;
    }
    .logo,
    .j18 {
      position: initial;
      display: block;
      margin: auto;
    }
    .logo {
      margin-bottom: 10px;
    }
  }
}
</style>
