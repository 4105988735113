import { mapGetters, mapActions } from 'vuex'
import globalConfig from '../utiles/globalConfig'
import md5 from 'md5'
import url from '../config/url'

export default {
  data() {
    let giftType = window.pageInfo['awardType'] || 'game'
    return {
      isDownloadAward: window.pageInfo['downloadAward']['isAward'] || false,
      isNormalGift: giftType === 'game',
      isNumberGift: giftType === 'number',
      // 判断是否使用新的下载链接
      isUseNewExeLink:
        window.pageInfo.useNewExeLink &&
        !/macintosh|macintel|mac os/g.test(
          navigator.userAgent.toLocaleLowerCase()
        )
    }
  },
  computed: {
    newDownloadName() {
      return md5(
        `gclid=${this.gclid}&cid=${this.cid}&scid=${this.scid}&fbp=${this.fbp}&fbc=${this.fbc}&adid=${this.adid}&adname=${this.adname}&camid=${this.camid}&camname=${this.camname}&adsetid=${this.adsetid}&adsetname=${this.adsetname}`
      )
    },
    downloadLink() {
      let oldLink =
        window.location.origin +
        window.location.pathname +
        `/download?cid=${this.cid}&scid=${this.scid}&location=${this.location}`

      let newLink = `${url[window.pageInfo.platform].newExeDownload}/${
        window.gameInfo.NAME
      }_install_${this.newDownloadName}.exe
      `

      return this.isUseNewExeLink ? newLink : oldLink
    },

    ...mapGetters([
      'isLogin',
      'downloadHasGift',
      'letter',
      'roleId',
      'location',
      'gclid',
      'cid',
      'scid',
      'fbp',
      'fbc',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname'
    ])
  },
  methods: {
    ...mapActions(['getAwardState', 'downloadUpload']),
    downloadFn() {
      try {
        globalConfig.gaStatistics('click_download')
      } catch (e) {}

      // 如果沒有發獎，就直接下载
      if (!this.isDownloadAward) {
        this.doDownload('', true)
        return
      }

      // 如果還沒進入遊戲，就提示要先進遊戲
      if (!(this.isLogin && this.roleId)) {
        this.doDownload(this.letter.h5_download_login, true)
        return
      }

      // 如果已經領過了，就提示已領
      if (this.downloadHasGift) {
        this.doDownload(this.letter.h5_download_repeat_get, true)
        return
      }

      this.doDownload('', true)
      this.$store.dispatch('getDownloadGift').then(res => {
        // 如果领奖失败
        if (res.result == '0') {
          // 兼容一下161遊戲的旧接口
          if (res.code == -20002) {
            this.doDownload(this.letter.h5_download_repeat_get, false)
          } else {
            this.doDownload(this.letter.h5_get_gift_fault, false)
          }
          return
        }

        // 判断一下发奖类型，来决定显示什么
        let realText = this.isNormalGift
          ? this.letter.h5_download_succ
          : res.data.card
        this.doDownload(realText, false, true)
        this.getAwardState()

        try {
          globalConfig.gaStatistics('download_gift')
        } catch (e) {}
      })
    },
    doDownload(text, isDown, judgeType) {
      if (isDown) {
        window.open(this.downloadLink, '_blank')
        // 如果是用新的微端，需要先上报一下
        if (this.isUseNewExeLink) {
          this.downloadUpload({
            md5gclid: this.newDownloadName,
            uploadParam: `gclid=${this.gclid}&cid=${this.cid}&scid=${this.scid}&fbp=${this.fbp}&fbc=${this.fbc}&adid=${this.adid}&adname=${this.adname}&camid=${this.camid}&camname=${this.camname}&adsetid=${this.adsetid}&adsetname=${this.adsetname}`
          })
        }
      }
      if (this.isNumberGift && judgeType) {
        this.$store.commit('updateGiftNumber', text)
      } else {
        if (text) {
          this.$store.commit('updateCommonTips', {
            content: text,
            sure:
              this.isNumberGift && this.isLogin && this.roleId ? 'giftList' : ''
          })
        }
      }
    }
  }
}
