//广告事件类型
export const ADS_EVENTS_TYPE = 'custom_iaa'
//广告加载成功
export const VIDEO_LOADED = 'video_loaded'
//广告加载失败
export const VIDEO_LOAD_FAILED = 'video_load_failed'
//广告播放
export const VIDEO_PLAY = 'video_play'
//广告播放结束
export const VIDEO_END = 'video_end'
//广告播放结束，需要发送奖励
export const VIDEO_REWARD = 'video_reward'

export const ADS_PLATFORM = {
  gam: 'GAM'
}
