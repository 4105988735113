<template>
  <div class="popup-mask store-pay-popup">
    <em class="popup-mask" @click="popupOperate"></em>
    <div class="popup-content">
      <div class="popup-title">
        <em
          class="popup-button"
          :class="type === 'ways' ? 'close' : 'back'"
          @click="popupOperate"
        ></em>
        <h1 class="title-text">{{ letter.h5_popup_title }}</h1>
        <!-- <em class="popup-button question" @click="jumpToLink"></em> -->
      </div>
      <div v-show="load && payWays.length == 0" class="loading">
        <em class="load load1"></em>
        <em class="load load2"></em>
        <em class="load load3"></em>
        <em class="load load4"></em>
        <em class="load load5"></em>
        <em class="load load1"></em>
        <em class="load load2"></em>
        <em class="load load3"></em>
        <em class="load load4"></em>
        <em class="load load5"></em>
      </div>
      <template v-if="type === 'ways'">
        <div class="pay-ways-item-box">
          <div
            class="pay-ways-item"
            v-for="item in payWays"
            :key="item.type"
            @click="selectWay(item)"
          >
            <!-- <em class="way-item-icon"></em> -->
            <img class="way-item-icon" :src="item | imageLink" alt="pay-icon" />
            <p class="way-item-text">
              <span class="text-span">
                {{ item.name }}
                <!-- <em class="last-way" v-if="item.last">{{letter.h5_popup_last_used}}</em> -->
              </span>
            </p>
            <div class="way-item-select"></div>
          </div>
        </div>
      </template>
      <template v-if="type === 'info'">
        <p class="product-price">
          <span class="product-number">
            {{ productInfo.TRANS_AMOUNT ? +productInfo.TRANS_AMOUNT : '' }}
          </span>
          &nbsp;{{ letter.h5_popup_currency_sign }}
        </p>
        <p class="product-name">{{ productInfo.PRODUCT_NAME }}</p>
        <div class="pay-ways-item" @click="changeWay">
          <!-- <em class="way-item-icon"></em> -->
          <img
            class="way-item-icon"
            :src="selectedWay | imageLink"
            alt="pay-icon"
          />
          <p class="way-item-text">
            <span class="text-span">
              {{ selectedWay.name }}
              <!-- <em
                class="last-way"
                v-if="selectedWay.last"
                :class="lang"
              >{{letter.h5_popup_last_used}}</em>-->
            </span>
          </p>
          <div class="way-item-select"></div>
        </div>
        <div class="bottom-content">
          <!-- 年龄询问 -->
          <!-- <div class="confirm-content" v-if="!hasSelect">
            <p class="confirm-tips">20歳未満は購入できません。20歳以上ですか？</p>
            <label class="confirm-label right">
              <input class="hidden-input" type="radio" name="over20" value="はい" checked />
              <em class="bui-radios"></em>はい
            </label>
            <label class="confirm-label left">
              <input class="hidden-input" type="radio" name="over20" value="いいえ" />
              <em class="bui-radios"></em>いいえ
            </label>
          </div>-->
          <div
            class="confirm-button j-store-popup-submit-btn j-goto-pay"
            :class="canClick ? false : 'disabled'"
            @click="confirmPay"
          >
            <em class="loading"></em>
            {{ letter.h5_popup_submit }}
          </div>
        </div>
      </template>
    </div>
    <div v-show="showDropInPopup" id="dropIn"></div>
    <div v-if="showCloseConfirm" class="popup-mask question">
      <div class="popup-content">
        <p class="close-tips">{{ letter.h5_popup_quit_tips }}</p>
        <div class="button-group">
          <div class="button-item" @click="closePopup">
            {{ letter.h5_popup_quit_check }}
          </div>
          <div class="button-item reject" @click="cancelClose">
            {{ letter.h5_popup_quit_cancel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { mapActions, mapGetters } from 'vuex'
import globalConfig from '../../utiles/globalConfig'
import { isEmptyObj } from '../../store/ultile'
import { STORE_PAGE_BASE_URL } from '../../utiles'
import host from '../../config/host'
const mainHost = globalConfig.getMainHost()
const OPEN_BROSWER_METHOD = 'openBrowser'
const SUCCESS_RESULT_CODE = 1
const PAY_REQUEST_GET = 1
const PAY_REQUEST_POST = 2
const SHOW_RESULT_PAGE = 5
const INVOKE_THIRD_JSSDK_FUNCTION = 9
const BROWSER_PAY = 11
const AIRWALLEX_FONTS_SRC =
  'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2'
const AIRWALLEX_JSSDK_URL =
  'https://checkout.airwallex.com/assets/elements.bundle.min.js'
let myWin = null

export default {
  name: 'StorePopupListGlobal',
  data() {
    return {
      type: 'ways',
      showCloseConfirm: false,
      selectedWay: {},
      hasSelect: true,
      canClick: true,
      pin: '',
      serial: '',
      load: true,
      showDropInPopup: false,
      isAirWallex: false
    }
  },
  watch: {
    load(newV, oldV) {
      if (!newV && oldV) {
        this.selectLastWay()
      }
    }
  },
  computed: {
    ...mapGetters({
      payWays: 'storeWay',
      gameId: 'gameId',
      tmpProductInfo: 'productInfo',
      lang: 'lang',
      serverList: 'serverList',
      serverId: 'ourServerId',
      gameServerId: 'serverId',
      priceObj: 'priceObj',
      roleId: 'roleId',
      roleName: 'roleName',
      userId: 'userId',
      letter: 'letter',
      sdkParam: 'sdkParams',
      cid: 'cid',
      scid: 'scid'
    }),
    productInfo() {
      if (isEmptyObj(this.selectedWay)) {
        return {}
      }
      if (!this.tmpProductInfo || isEmptyObj(this.tmpProductInfo)) {
        return {}
      }
      let curType = `${this.selectedWay.fType}@@${this.selectedWay.type}`
      let curPirce = this.priceObj[curType]
      let obj = Object.assign({}, this.tmpProductInfo, curPirce)
      return obj
    }
  },
  filters: {
    imageLink(payway) {
      return `//abres.octlib.com/commonImg/store/${payway.fType}/${payway.type}.png`
    }
  },
  created() {
    this.fetchProductInfo(this.sdkParam)
      .then(res => {
        this.load = false
      })
      .catch(err => {
        alert(err)
        this.load = false
      })
  },
  methods: {
    selectWay(item) {
      this.selectedWay = item
      this.type = 'info'
    },
    changeWay() {
      this.type = 'ways'
    },
    popupOperate() {
      if (this.type === 'info') {
        this.type = 'ways'
      } else {
        this.showCloseConfirm = true
      }
    },
    jumpToLink() {
      // window.open('https://www.google.com');
    },
    cancelClose() {
      this.showCloseConfirm = false
    },
    closePopup(isClick = true) {
      if (this.isAirWallex) {
        this.isAirWallex = false
        this.showDropInPopup = false
        this.showCloseConfirm = false
      } else {
        this.$store.commit('updateStorePopupState', false)
      }
    },
    injectScript(scriptPath, callback) {
      let scriptNode = document.createElement('SCRIPT')
      scriptNode.type = 'text/javascript'
      scriptNode.src = scriptPath

      let headNode = document.getElementsByTagName('head')
      if (headNode[0] != null) headNode[0].appendChild(scriptNode)
      if (callback != null) {
        scriptNode.onreadystagechange = callback
        scriptNode.onload = callback
      }
    },
    // type=1 GET方式跳转
    redirectToPay(data) {
      this.openLoadingPage()
      myWin.location.href = data.redirect_url
      this.closePopup(false)
    },
    // type=2 需要构造一个FORM表单 POST跳转
    postByForm(data) {
      this.openLoadingPage()
      let inputHtml = ''
      const postFields = data.post_fields
      for (let i in postFields) {
        inputHtml +=
          '<input type="hidden" name="' + i + '" value="' + postFields[i] + '">'
      }
      let formHtml =
        '<form method="post" id="J_pay_payListForm" style="display: none" action="' +
        data.redirect_url +
        '">' +
        inputHtml +
        '</form>'
      myWin.document.getElementById('J_pay_payListWrap').innerHTML = formHtml
      setTimeout(() => {
        myWin.document.getElementById('J_pay_payListForm').submit()
      }, 0)
      this.closePopup(false)
    },
    // type=5 直接在页面显示充值成功
    showPayResult(params, result, data) {
      this.openLoadingPage()
      let encodeParamStr = window.btoa(JSON.stringify(params))
      let gameName = (window.gameInfo && window.gameInfo.NAME) || ''
      let patam = {
        result,
        msg: result.msg,
        game_id: this.gameId,
        game_name: gameName,
        server_id: this.serverId,
        server_sid: this.sdkParam.serverId,
        order_id: data.order_id,
        exchange_timestamp: new Date().getTime(),
        store_url_params: encodeParamStr
      }
      let pasrams = qs.stringify(patam)
      let href = STORE_PAGE_BASE_URL + pasrams + '#/result'
      myWin.location.href = href
      this.closePopup(false)
    },
    // type=9 调用第三方JSSDK方法，AIRWALLEX渠道使用
    invokeThirdJssdkFunc(data) {
      this.isAirWallex = true
      this.showDropInPopup = true
      let __self = this
      if (data && data.payment_intent) {
        let airwallexConfig = data.payment_intent
        let env = airwallexConfig.env
        airwallexConfig.theme = {
          // Must provide theme to display the checkout page properly
          fonts: [
            // Customizes the font for the payment elements
            {
              src: AIRWALLEX_FONTS_SRC,
              family: 'AxLLCircular',
              weight: 400
            }
          ]
        }
        // 注入airwallex jssdk
        this.injectScript(AIRWALLEX_JSSDK_URL, function () {
          try {
            // 初始化Airwallex
            Airwallex.init({
              env: env,
              origin: window.location.origin
            })

            const dropIn = Airwallex.createElement('dropIn', airwallexConfig)
            dropIn.mount('dropIn')
          } catch (error) {
            console.error('There was an error', error)
          }

          window.addEventListener('onReady', event => {
            globalConfig.log('Element is ready', event.detail)
          })
          window.addEventListener('onSuccess', event => {
            // AirWallet 储值成功回调
            // globalConfig.log(JSON.stringify(event.detail));
            __self.isAirWallex = false
            __self.showDropInPopup = false
            __self.closePopup(false)
          })
          window.addEventListener('onError', event => {
            const { error } = event.detail
            __self.isAirWallex = false
            console.error('There was an error', error)
          })
        })
      }
    },
    // type=11 打开浏览器储值
    openBrowserToPay(data) {
      this.openLoadingPage()
      const redirect_url = data.redirect_url
      myWin.location.href = redirect_url
      this.closePopup(false)
    },
    // 新窗口打开loading页
    openLoadingPage() {
      const title = encodeURIComponent(document.title)
      myWin = window.open(
        `//h5.${mainHost}/loading.html?title=${title}`,
        '_blank'
      )
    },
    // 关闭新窗口打开的loading页
    closeLoadingPage() {
      myWin && myWin.close()
    },
    confirmPay() {
      if (isEmptyObj(this.productInfo)) {
        alert(this.letter.h5_popup_product_empty)
        return
      }
      this.canClick = false

      if (!this.serverId) {
        alert(this.letter.h5_popup_server_empty)
        this.canClick = true
        return
      }

      try {
        globalConfig.gaStatistics('exc_store_pay', {
          pid: this.productInfo.PRODUCT_ID,
          pay_type: this.selectedWay.type,
          ...this.sdkParam
        })
      } catch (e) {}

      // let allHrefParam = globalConfig.getHrefParamObj();
      let url = `//${host.global.store}${mainHost}/${this.selectedWay.fType}/submit`
      let params = {
        gameId: this.gameId,
        serverId: this.serverId || this.sdkParam.serverId,
        roleId: this.roleId || this.sdkParam.roleId,
        roleName: this.roleName || this.sdkParam.roleName,
        packageName:
          globalConfig.getHrefParam('packageName') ||
          window.pageInfo.packageName,
        userId: this.userId,
        productId: this.productInfo.PRODUCT_ID,
        cpProductId: this.productInfo.CP_PRODUCT_ID,
        devicePlate: 'pc',
        // 如果研发没有传，就从第三方接口获取的remark
        remark: this.sdkParam.remark || this.productInfo.REMARK,
        location:
          window.pageInfo['location'] || this.lang.replace(/^[a-z]{2}-/, ''),
        appLanguage: this.lang,
        language: this.lang,
        paytype: this.selectedWay.type,
        amount: this.productInfo.TRANS_AMOUNT,
        currency: this.productInfo.CURRENCY,
        amountDesc: this.productInfo.TRANS_AMOUNT,
        productDesc:
          this.productInfo.CP_PRODUCT_DESC || this.productInfo.PRODUCT_DESC,
        pin: this.pin,
        serial: this.serial,
        f_pay_type: this.selectedWay.fType,
        curReferer: `pay.${mainHost}`,
        // 后端需要的商品remark
        productRemark: this.productInfo.REMARK,
        // 传输sdk的remark参数
        sdkRemark: this.sdkParam.remark,
        // 用于结果页判断是否web端页面，修改一些按钮的显示隐藏和跳转地址
        isWeb: 1,
        // H5游戏储值推送给统计的参数：cid,scid
        cid: this.cid,
        scid: this.scid,
        isH5: 1
      }

      // show page inner loading
      this.submitPayType({ url, params })
        .then(response => {
          let res = response.data
          if (SUCCESS_RESULT_CODE != res.result) {
            let errmsg =
              (res && res.msg && res.msg.replace(/\[\-\d*\]/, '')) || ''
            this.canClick = true
            this.closeLoadingPage()
            setTimeout(() => {
              alert(errmsg)
            }, 100)
          } else {
            let { result, data } = res
            if (+result === 1) {
              switch (+data.type) {
                // type=1 GET方式跳转
                case PAY_REQUEST_GET:
                  this.redirectToPay(data)
                  break
                // type=2 需要构造一个FORM表单 POST跳转
                case PAY_REQUEST_POST:
                  this.postByForm(data)
                  break
                // type=5 直接在页面显示充值成功
                case SHOW_RESULT_PAGE:
                  this.showPayResult(params, result, data)
                  break
                // type=9 调用第三方JSSDK方法，AIRWALLEX渠道使用
                case INVOKE_THIRD_JSSDK_FUNCTION:
                  this.invokeThirdJssdkFunc(data)
                  break
                case BROWSER_PAY:
                  // type=11 打开浏览器储值
                  this.openBrowserToPay(data)
                  break
              }
            }
            this.canClick = true
            // if (!this.hasSelect) {
            //   window.localStorage.setItem("JSMR_SET_AGE", "1");
            // }
          }
        })
        .catch(() => {})
    },
    selectLastWay() {
      let lastWay = this.payWays.find(item => item.last)

      if (lastWay) {
        this.selectedWay = lastWay
        this.type = 'info'
      }
    },
    ...mapActions(['submitPayType', 'fetchProductInfo'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/_dimen.scss';

.store-pay-popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  position: fixed;
  .popup-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.8);
  }

  &.question {
    z-index: 100;
  }
  .tips-content {
    top: 50%;
    left: 50%;
    width: 525vh;
    height: auto;
    color: #fff;
    padding: 30vh;
    position: absolute;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
  .popup-content {
    top: 50%;
    left: 50%;
    height: auto;
    padding: 30vh;
    width: 525vh;
    max-width: 100%;
    background: #fff;
    border-radius: 10vh;
    position: absolute;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    .close-tips {
      font-size: 22vh;
      margin-top: 65vh;
      font-weight: 700;
      text-align: center;
      margin-bottom: 45vh;
    }
    .button-group {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .button-item {
        width: 178vh;
        height: 48vh;
        color: #fff;
        cursor: pointer;
        line-height: 48vh;
        text-align: center;
        border-radius: 24vh;
        background-color: #d670e1;
        &.reject {
          background-color: #9a9a9a;
        }
      }
    }
  }
  .popup-title {
    display: flex;
    align-items: center;
    margin-bottom: 28vh;
    justify-content: space-between;
    position: relative;
    .popup-button {
      width: 23vh;
      height: 23vh;
      flex: 0 0 23vh;
      cursor: pointer;
      background-size: 100% 100%;
      &.back {
        background-image: url($img + 'back.png');
      }
      &.close {
        position: absolute;
        left: 0;
        background-image: url($img + 'close.png');
      }
      &.question {
        background-image: url($img + 'question.png');
      }
    }
    .title-text {
      flex: 1;
      margin: 0;
      font-size: 22vh;
      font-weight: 700;
      text-align: center;
    }
  }
  .pay-ways-item-box {
    max-height: 600vh;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pay-ways-item {
    height: 80vh;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 10vh;
    align-items: center;
    padding-right: 20vh;
    box-sizing: border-box;
    border: 1px solid #b9b7b3;
    justify-content: space-between;
    .way-item-icon {
      flex: 0 0 104vh;
      width: 104vh;
    }
    .way-item-text {
      flex: 1;
      color: #000;
      font-size: 22vh;
      font-weight: 700;
      .text-span {
        position: relative;
        .last-way {
          top: 50%;
          left: 110%;
          width: 76vh;
          padding: 4vh;
          height: 16vh;
          color: #fff;
          font-size: 14vh;
          line-height: 16vh;
          border-radius: 3px;
          position: absolute;
          background: #58e078;
          text-align: center;
          transform: translateY(-50%);
          &.ko-KR {
            width: 100vh;
          }
        }
      }
    }
    .way-item-select {
      width: 22vh;
      height: 20vh;
      flex: 0 0 22vh;
      background-size: 100% 100%;
      background-image: url($img + 'select.png');
    }
  }
  .product-price {
    margin-top: 0;
    font-size: 24vh;
    color: #d670e1;
    text-align: center;
    margin-bottom: 5vh;
    .product-number {
      font-size: 36vh;
    }
  }
  .product-name {
    margin-top: 10vh;
    font-size: 18vh;
    color: #d670e1;
    text-align: center;
    margin-bottom: 30vh;
  }
  .bottom-content {
    margin-top: 100vh;
    .confirm-content {
      .confirm-tips {
        margin: 0;
        font-size: 14vh;
        color: #727171;
        text-align: center;
      }
      .confirm-label {
        width: 50%;
        height: 24vh;
        cursor: pointer;
        margin-top: 20vh;
        margin-bottom: 40vh;
        display: inline-block;
        box-sizing: border-box;
        &.right {
          text-align: right;
          padding-right: 17vh;
        }
        &.left {
          text-align: left;
          padding-left: 17vh;
        }
        .hidden-input {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          &:checked + .bui-radios {
            background: #58e078;
            &:after {
              background-size: 100% 100%;
              background-image: url($img + 'check.png');
            }
          }
        }
        .bui-radios {
          width: 24vh;
          height: 24vh;
          margin-right: 5vh;
          border-radius: 10px;
          position: relative;
          vertical-align: -3vh;
          background: #9a9a9a;
          display: inline-block;
          transition: all 0.3s linear;
          &:after {
            top: 7vh;
            left: 4vh;
            content: '';
            width: 16vh;
            height: 11vh;
            position: absolute;
            transition: all 0.3s linear;
          }
        }
      }
    }
    .confirm-button {
      height: 46vh;
      color: #fff;
      cursor: pointer;
      font-size: 22vh;
      line-height: 46vh;
      text-align: center;
      border-radius: 23vh;
      background-color: #d670e1;
      &.disabled {
        background-color: #9a9a9a;
        .loading {
          display: inline-block;
          vertical-align: middle;
          width: 30vh;
          height: 30vh;
          background-size: 100% 100%;
          background-image: url($img + 'loading.gif');
        }
      }
    }
  }
}

.loading {
  text-align: center;
  .load {
    display: inline-block;
    width: 2vh;
    height: 20vh;
    margin: 0 2vh;
    background: #9a9a9a;
  }
  .load1 {
    animation: loading 1s infinite;
  }
  .load2 {
    animation: loading 1s infinite;
    animation-delay: 0.2s;
  }
  .load3 {
    animation: loading 1s infinite;
    animation-delay: 0.4s;
  }
  .load4 {
    animation: loading 1s infinite;
    animation-delay: 0.6s;
  }
  .load5 {
    animation: loading 1s infinite;
    animation-delay: 0.8s;
  }
}

#dropIn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 540px;
  height: 600px;
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
}

#dropIn /deep/ iframe {
  height: 100% !important;
}

@keyframes loading {
  0% {
    background: #9a9a9a;
    transform: scaleY(1);
  }
  50% {
    background: #d670e1;
    transform: scaleY(1.4);
  }
  100% {
    background: #9a9a9a;
    transform: scaleY(1);
  }
}
</style>
