import { getJSON } from './ultile'
import axios from 'axios'
import qs from 'qs'
import ajaxUrl from '../config/ajax'

export default {
  state: {
    productInfo: {},
    storeWay: [],
    selectProductId: '',
    priceObj: {},
    ourServerId: '',
    sdkParams: {
      cpProductId: '',
      productId: '',
      productName: '',
      price: '',
      currency: '',
      order_no: '',
      remark: '',
      serverId: '',
      roleId: '',
      roleName: '',
      pid: ''
    },

    storePopupState: false
  },
  getters: {
    storeWay: state => state.storeWay,
    productInfo: state => state.productInfo,
    priceObj: state => state.priceObj,
    ourServerId: state => state.ourServerId,
    storePopupState: state => state.storePopupState,
    sdkParams: state => state.sdkParams
  },
  mutations: {
    // 商品信息
    updateProductInfo(state, payload) {
      let productList = payload.product_list || []
      productList = productList.reduce((arr, item) => {
        arr = arr.concat(item.list)
        return arr
      }, [])
      state.productInfo = productList.find(
        item => item.PRODUCT_ID === state.selectProductId
      )
    },
    updateShowPayWay(state, payload) {
      let lastPay = payload.paytype
      let priceObj = {}
      // const TRANS_AMOUNT = +state.productInfo.TRANS_AMOUNT;
      payload.paytype_list.forEach(item => {
        let priceIndex = item.price_list.findIndex(
          // 判断差价，检测是否存在定价一致的商品
          priceItem => +priceItem.SUB_AMOUNT == 0
        )
        if (priceIndex !== -1) {
          let key = `${item.f_pay_type}@@${item.pay_type}`
          priceObj[key] = JSON.parse(
            JSON.stringify(item.price_list[priceIndex])
          )
        }
      })
      let storeWay = payload.paytype_list.reduce((arr, item) => {
        let key = `${item.f_pay_type}@@${item.pay_type}`
        if (priceObj[key]) {
          let isLast =
            lastPay.f_pay_type === item.f_pay_type &&
            lastPay.pay_type === item.pay_type
          let obj = {
            fType: item.f_pay_type,
            type: item.pay_type,
            currency: item.currency,
            name: item.name,
            // 此为渠道类型，0普通，1点卡（仅需要一个输入框），2点卡（需要两个输入框）
            typeNumber: item.type,
            // 是否为上次储值渠道
            last: isLast
          }
          arr.push(obj)
        }
        return arr
      }, [])
      state.storeWay = storeWay
      state.priceObj = priceObj
      state.ourServerId = payload.server_id
    },
    updateStorePopupState(state, data) {
      state.storePopupState = data
    },
    updateSdkParams(
      state,
      {
        cpProductId = '',
        productId = '',
        productName = '',
        price = '',
        currency = '',
        order_no = '',
        remark = '',
        roleId = '',
        roleName = '',
        serverId = '',
        pid = ''
      }
    ) {
      state.sdkParams = {
        cpProductId: cpProductId,
        productId: productId,
        productName: productName,
        price: +price || 0,
        currency: currency,
        order_no: order_no,
        remark: remark,
        serverId: serverId,
        roleId: roleId,
        roleName: roleName,
        pid: pid
      }
    }
  },
  actions: {
    // 获取商品信息 && 储值渠道
    fetchProductInfo({ commit, state, rootState }, payload) {
      state.selectProductId = payload.productId
      return new Promise(resolve => {
        getJSON(ajaxUrl[rootState.platform].pay.payInit, {
          gameId: rootState.gameId,
          serverId: rootState.serverId || payload.serverId,
          roleId: rootState.roleId || payload.roleId,
          roleName: rootState.roleName || payload.roleName,
          userId: rootState.login.userId,
          location:
            window.pageInfo.location ||
            rootState.language.lang.replace(/^[a-z]{2}-/, ''),
          productId: payload.productId,
          remark: payload.remark,
          devicePlate: 'pc',
          h5: 1,
          language: rootState.language.lang,
          appLanguage: rootState.language.lang
        }).then(res => {
          commit('updateProductInfo', res.data)
          commit('updateShowPayWay', res.data)
          resolve()
        })
      })
    },
    // 确认储值
    submitPayType({}, payload) {
      const data = !payload.notStringify
        ? qs.stringify(payload.params)
        : payload.params
      return axios.post(payload.url, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    }
  }
}
