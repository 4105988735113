import { getBrowser, getPhoneModel, checkIsElectron, systemType } from './index'
export let isServerEnv = 'undefined' === typeof window
export const H5_DEVICE_PLATE = 'h5'
export const EXE_DEVICE_PLATE = 'pc'

const ENTRY_PAGE_ALL_PARAMS = 'entryPageAllPArams'
let getHrefParamFromLocation = function (paramName, newHref) {
  let href =
    newHref ||
    decodeURIComponent(
      (global.location && (global.location.search || global.location.hash)) ||
        ''
    ).substr(1)
  let reg = new RegExp('(^|&|\\?)' + paramName + '=([^&]*)(&|$)')
  let matchResult = href.match(reg)
  let param = matchResult ? decodeURIComponent(matchResult[2]) : ''
  return param.replace(/\%[235\%\/]{3,}$/, '') // (越南大天使出现奇怪的#/转码导致scid获取不正常)
}

let getHrefParamFromLocationUncode = function (paramName, newHref) {
  let href =
    newHref ||
    (
      (global.location && (global.location.search || global.location.hash)) ||
      ''
    ).substr(1)
  let reg = new RegExp('(^|&|\\?)' + paramName + '=([^&]*)(&|$)')
  let matchResult = href.match(reg)
  let param = matchResult ? decodeURIComponent(matchResult[2]) : ''
  return param.replace(/\%[235\%\/]{3,}$/, '') // (越南大天使出现奇怪的#/转码导致scid获取不正常)
}
// h5弹屏打点数据ext19传h5，微端传pc，入库到platform字段，用来区分h5和微端数据
const getTpPlatform = () => {
  return checkIsElectron() ? EXE_DEVICE_PLATE : H5_DEVICE_PLATE
}

let prevAction = 'init'
let staticEventLists = []
let sendImmediate = false
let immediateSendEvent = ['login_fail']
const sessionId = `ssid_${new Date().getTime()}`

export default {
  curentLang: '',
  query: null,
  route: null,
  isServerEnv: 'undefined' === typeof window,
  global: 'undefined' === typeof window ? global : window,
  isFirstPlay: true,
  userId: '',
  userName: '',
  isLog: false,
  isInitLog: false,
  log() {
    if (!this.isInitLog) {
      this.isInitLog = true
      this.isLog = !!this.getHrefParam('log')
    }
    if (this.isLog) {
      console.log.apply(console.log, arguments)
    }
  },
  setUserName(userName) {
    this.userName = userName
  },
  setIsFirstPlay(val, userId) {
    this.userId = userId
    let firstPlayObj = JSON.parse(
      window.localStorage.getItem('firstPlayObj') || '{}'
    )
    if (!val) {
      val = false
    }
    if (firstPlayObj[userId]) {
      val = this.judgeIsNewUser()
    }
    // 根据isFirst区别设置firstTime
    let tmpFirstTime = val ? Date.now() : Date.now() - 48 * 60 * 60 * 1000
    firstPlayObj.prev = userId
    firstPlayObj[userId] = {
      isFirst: val,
      firstPlayTime:
        (firstPlayObj[userId] && firstPlayObj[userId].firstPlayTime) ||
        tmpFirstTime
    }
    this.isFirstPlay = val
    window.localStorage.setItem('firstPlayObj', JSON.stringify(firstPlayObj))
  },
  judgeIsNewUser() {
    if (!this.userId) {
      return true
    }
    let firstPlayObj = JSON.parse(
      window.localStorage.getItem('firstPlayObj') || '{}'
    )
    let playerInfo = firstPlayObj[this.userId]
    let firstTime = Date.now()
    if (playerInfo) {
      firstTime = playerInfo.firstPlayTime
    }
    let date = new Date(firstTime)
    let now = Date.now()
    let playTime = Math.floor((now - date.getTime()) / (60 * 60 * 1000))
    let isNew = playTime < 48 ? true : false
    return isNew
  },
  init() {
    this.initQuery()
    this.initLanguage()
  },
  initQuery() {
    if (this.rootVm && this.route) {
      this.query = this.route.query
    }
  },

  setRoute(route) {
    this.route = route
  },
  initLanguage() {
    if (this.query) {
      this.curentLang = this.query['language']
    }
  },
  setRouterQuery(query) {
    this.query = query
  },
  getQuery() {
    return this.query
  },
  getParamFromRouter(paramName) {
    let paramVal = ''
    if (this.route) {
      paramVal = this.route.query[paramName]
    }
    return paramVal
  },
  getHrefParam(paramName) {
    if (isServerEnv) {
      return this.getParamFromRouter(paramName)
    }
    return getHrefParamFromLocation(paramName)
  },
  getHrefParamUndecode(paramName, href) {
    return getHrefParamFromLocationUncode(paramName, href)
  },
  getLanguages() {
    return this.getHrefParam('appLanguage') || this.getHrefParam('language')
  },
  getMainHost() {
    return window.location.host.replace(/^[a-zA-Z0-9]*\./, '')
  },
  getParamQuery() {
    let queryStr = ''
    if (isServerEnv || this.rootVm !== undefined) {
      queryStr = getHrefParamFromLocation(this.route.query)
    } else {
      let href = global.location.href
      queryStr = href.replace(/.*(?=\?)/, '').replace(/\#(w*\/)*/, '')
    }
    return queryStr
  },
  getCurrentHref() {
    let href = ''
    if (isServerEnv || this.rootVm !== undefined) {
      href = ''
    } else {
      href = global.location.href
    }
    return href
  },
  getTimeZone() {
    let offset_GMT = new Date().getTimezoneOffset() // 本地时间和格林威治的时间差，单位为分钟
    let offset_GMT_h = offset_GMT / 60
    if (offset_GMT_h) {
      return `GMT${
        offset_GMT_h > 0 ? '-' + offset_GMT_h : '+' + offset_GMT_h * -1
      }`
    } else {
      return 'GTM'
    }
  },
  getLocationSearch() {
    let search = ''
    if (isServerEnv || this.rootVm !== undefined) {
      search = ''
    } else {
      search = global.location.search
    }
    return search
  },
  getThirdPlatformType() {
    let thirdPlatformType = this.getHrefParam('pt')
    let thirdPlatform = window.pageInfo.thirdPlatform
    // 防止没有配置或者配置出错，导致获取第三方平台时逻辑出错
    if (/Array/i.test(thirdPlatform.constructor.toString())) {
      thirdPlatformType = thirdPlatform.includes(thirdPlatformType)
        ? thirdPlatformType
        : ''
    } else {
      thirdPlatformType = ''
    }
    return thirdPlatformType
  },
  getPlatformProductIconUrl(productId) {
    let platformType = this.getThirdPlatformType()
    let thirdPlatformConfig =
      window.pageInfo.thirdPlatformConfig[platformType] || {}
    let productIcon = thirdPlatformConfig.productIcon || []
    let defaultProductIcon = thirdPlatformConfig.defaultProductIcon || ''
    let boolProductIcon = productIcon.includes(productId)
    let showProductIcon = boolProductIcon ? productId : defaultProductIcon
    return `${window.location.origin}/${platformType}/${window.gameId}/${showProductIcon}.png`
  },
  getIsTest() {
    return !!this.getHrefParam('test')
  },
  getLclStorageItem(name) {
    let res = null
    if ('undefined' != typeof this.global.localStorage) {
      res = JSON.parse(this.global.localStorage.getItem(name))
      return res
    }
    return res
  },

  setLclStorageItem(name, val) {
    if (this.global.localStorage) {
      return this.global.localStorage.setItem(name, JSON.stringify(val))
    }
  },
  setCookie(name, value, time) {
    let foreverTime = new Date()
    foreverTime.setFullYear(foreverTime.getFullYear() + 3000)
    document.cookie = `${name}=${value};domain=${this.getMainHost()};expires=${
      time || foreverTime.toGMTString()
    }`
  },
  getCookie(name) {
    let arr,
      reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    if ((arr = document.cookie.match(reg))) return unescape(arr[2])
    else return null
  },
  stateRenderTime() {
    let startTime = parseInt(
      this.getHrefParam('timestamp') ||
        this.getHrefParam('timeStamp') ||
        this.getHrefParam('time_stamp')
    )
    if (!isServerEnv) {
      const _self = this
      this.global.addEventListener('load', function () {
        let completeTime = window.performance.timing.domComplete - startTime
        let interactiveTime =
          window.performance.timing.domInteractive - startTime
        _self.log('completeTime: ')
        _self.log(completeTime)
        _self.log('interactiveTime: ')
        _self.log(interactiveTime)
      })
    }
  },
  showMountedTime() {
    let startTime = parseInt(
      this.getHrefParam('timestamp') ||
        this.getHrefParam('timeStamp') ||
        this.getHrefParam('time_stamp')
    )
    let now = new Date().getTime()
    this.log('mountedTime: ')
    this.log(now - startTime)
  },
  storeEntryParams() {
    let storeParams = this.getAllWebviewEntryParams()
    if (null != storeParams) {
      return false
    }
    let params = {
      Isdblink: this.getHrefParam('Isdblink'),
      advertise: this.getHrefParam('advertise'),
      androidid: this.getHrefParam('androidid'),
      appLanguage: this.getHrefParam('appLanguage'),
      apps: this.getHrefParam('apps'),
      battery: this.getHrefParam('battery'),
      channelId: this.getHrefParam('channelId'),
      country: this.getHrefParam('country'),
      cpOrderId: this.getHrefParam('cpOrderId'),
      cpProductId: this.getHrefParam('cpProductId'),
      customUserId: this.getHrefParam('customUserId'),
      deepLinkURL: this.getHrefParam('deepLinkURL'),
      devicePlate: this.getHrefParam('devicePlate'),
      gameId: this.getHrefParam('gameId'),
      gpid: this.getHrefParam('gpid'),
      sdkToken: this.getHrefParam('sdkToken'),
      imei: this.getHrefParam('imei'),
      isFirst: this.getHrefParam('isFirst'),
      language: this.getHrefParam('language'),
      loginAccount: this.getHrefParam('loginAccount'),
      loginSign: this.getHrefParam('loginSign'),
      loginTimestamp: this.getHrefParam('loginTimestamp'),
      loginUID: this.getHrefParam('loginUID'),
      mac: this.getHrefParam('mac'),
      netType: this.getHrefParam('netType'),
      osVersion: this.getHrefParam('osVersion'),
      packageName:
        this.getHrefParam('packageName') || window.pageInfo.packageName,
      packageVersion: this.getHrefParam('packageVersion'),
      phoneModel: this.getHrefParam('phoneModel'),
      productId: this.getHrefParam('productId'),
      ptCode: this.getHrefParam('ptCode'),
      publishPlatForm: this.getHrefParam('publishPlatForm'),
      ratio: this.getHrefParam('ratio'),
      remark: this.getHrefParam('remark'),
      roleId: this.getHrefParam('roleId'),
      roleLevel: this.getHrefParam('roleLevel'),
      roleName: this.getHrefParam('roleName'),
      sdkVersion: this.getHrefParam('sdkVersion'),
      serverId: this.getHrefParam('serverId'),
      thirdPlatForm: this.getHrefParam('thirdPlatForm'),
      timeStamp: this.getHrefParam('timeStamp'),
      timeZone: this.getHrefParam('timeZone'),
      ueAndroidId: this.getHrefParam('ueAndroidId'),
      userId: this.getHrefParam('userId'),
      userMode: this.getHrefParam('userMode'),
      userName: this.getHrefParam('userName'),
      zone: this.getHrefParam('zone')
    }
    sessionStorage.setItem(ENTRY_PAGE_ALL_PARAMS, JSON.stringify(params))
  },
  getAllWebviewEntryParams() {
    let params = {}
    try {
      params = JSON.parse(sessionStorage.getItem(ENTRY_PAGE_ALL_PARAMS)) || {}
    } catch (e) {}
    return params
  },
  getHrefParamObj() {
    let paramsStr =
      window.location.search.substr(1).replace(/\%[235\%\/]{3,}$/, '') || //(越南大天使出现奇怪的#/转码导致scid获取不正常)
      window.location.hash
        .replace(/^#\/\??/, '') // 避免行销在#/后加参数：#/?cid=123
        .replace(/(\?)|(%3F)/g, '&') // 避免多个？和转码的情况
        .replace(/%3D/g, '=') // 避免转码
        .replace(/^#\/(#\/)+$/, '#/') // 避免多个#/(越南大天使出现奇怪的转码导致scid获取不正常)
    let res = {}
    let paramsArr = []
    if (paramsStr.indexOf('&') >= 0) {
      paramsArr = paramsStr.split('&')
    } else if (paramsStr) {
      paramsArr = [paramsStr]
    }

    paramsArr.forEach(item => {
      let result = item.split('=')
      if (!/\+/g.test(result[0])) {
        res[result[0]] = decodeURIComponent(result[1])
      }
    })

    return res
  },
  assignParamsObj(obj1, obj2) {
    let newObj1 = Object.assign({}, obj1)
    let newObj2 = Object.assign({}, obj2)
    return Object.assign(newObj1, newObj2)
  },
  objParamsToStrParams(obj) {
    let res = []
    for (let key in obj) {
      res.push(`${key}=${obj[key]}`)
    }
    return res.join('&')
  },
  hrefAddParam(url, key, value) {
    if (url.indexOf('?') >= 0) {
      let reg = new RegExp('(\\?|&)' + key + '=', 'g')
      reg.lastIndex = 0
      if (!reg.test(url)) {
        return url + `&${key}=${value}`
      } else {
        return url
      }
    } else {
      return url + `?${key}=${value}`
    }
  },
  hrefAddParams(url, obj) {
    let urlArr = url.split('#/')
    let href = urlArr[0]
    let hash = urlArr[1] ? '#/' + urlArr[1] : ''
    if (!~href.indexOf('?')) {
      href += '?'
    }
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] !== 'object' && typeof obj[key] !== 'function') {
        href += `&${key}=${decodeURIComponent(obj[key])}`
      }
      return
    })
    return href + hash
  },
  addEvenListener(callback, eventName) {
    let addEvenListenerFn = window.addEventListener || window.attatchEvent
    let messageEvent = window.addEventListener ? eventName : 'on' + eventName
    addEvenListenerFn(messageEvent, callback, false)
  },
  removeEventListener(callback, eventName) {
    let removeEvenListenerFn = window.removeEventListener || window.detachEvent
    let messageEvent = window.removeEventListener ? eventName : 'on' + eventName
    removeEvenListenerFn(messageEvent, callback, false)
  },
  gaStatistics(eventName, data) {
    this.log(eventName)
    this.log(data)
    let isNewUser = this.judgeIsNewUser()
    let curTime = window.now_time || new Date().getTime() || ''
    staticEventLists.push({
      event: eventName,
      is_new: isNewUser,
      prev: prevAction,
      user_id: this.userName,
      time: curTime,
      ab_test_version: this.getHrefParam('ab_test_version') || '',
      device: systemType(),
      ...data
    })
    if (eventName === 'click_close_window') {
      sendImmediate = true
    }
    if (immediateSendEvent.indexOf(eventName) !== -1) {
      sendImmediate = true
    }
    if (sendImmediate) {
      // 重置队列推入的打点归属
      staticEventLists = staticEventLists.map(item => {
        item.user_id = this.userName
        item.is_new = isNewUser
        return item
      })
      if (immediateSendEvent.indexOf(eventName) !== -1) {
        sendImmediate = false
      }
      if (window.dataLayer) {
        staticEventLists = staticEventLists.filter(item => !item.isSend)
        let length = staticEventLists.length
        if (!length) {
          return
        }
        for (let i = 0; i < length; i++) {
          let item = staticEventLists[i]
          let isSend = item.isSend
          if (isSend) {
            continue
          }
          window.dataLayer.push(item)
          item.isSend = true
        }
      }
    }
    if (eventName === 'login_success') {
      sendImmediate = true
    }

    prevAction = eventName
  },

  ajaxStatistics({
    eventName = '',
    eventValue = '',
    eventType = '',
    loginAccount = '',
    serverId = '',
    roleId = '',
    userId = '',
    gclid = '',
    cid = '',
    scid = '',
    fbc = '',
    fbp = '',
    adid = '',
    adname = '',
    camid = '',
    camname = '',
    adsetid = '',
    adsetname = '',
    isTpData = false
  }) {
    const isTest = window.getUrlParms('test') == 1
    const plat = window.pageInfo['platform']

    const statisticsApiKey = {
      global: isTest
        ? 'FXR71GLqSZOF7qFxbg5Rv9MFUseKTJRI'
        : 'nlXp9o2YfeBKQETB15HJQbvubmQhdNZN',
      gm99: isTest
        ? 'F8GxMxxDTyNQcJwOty1FuUaSQJn3YlhJ'
        : 'hPZ1JP6RR74pE88jpuPdR5pK6fA9rcoD'
    }

    const statisticsUrl = {
      global: `https://${
        isTest ? 'test-' : ''
      }h5callback-global.37games.com/api/v1/mglb_h5`,
      gm99: `https://${isTest ? 'test-' : ''}h5callback.gm99.com/api/v1/mtw_h5`
    }
    if (statisticsApiKey[plat] == void 0 || statisticsUrl[plat] == void 0)
      return

    // 如果是PWA打开的话cid和scid读localStorage存的。这个是在安装的时候存进去的
    if (this.getHrefParam('mode') === 'pwa') {
      try {
        const pwaSourceParams = window.localStorage.getItem('pwaSourceParams')
        const pwaSourceParamsObj = JSON.parse(pwaSourceParams)
        cid = pwaSourceParamsObj.cid
        scid = pwaSourceParamsObj.scid
      } catch (e) {
        console.error(e)
      }
    }

    let paramsArr = [
      `apikey=${statisticsApiKey[plat]}`,
      `gameID=${window.pageInfo.game_id}`,
      `event=${eventName}`,
      `loginAccount=${loginAccount.toLowerCase()}`, // 用户名转小写，这样和统计后台对应上。
      `gameServerID=${serverId || ''}`,
      `fbc=${fbc}`,
      `fbp=${fbp}`,
      `phoneModel=${getPhoneModel()}`,
      `deviceForm=${getBrowser()}`,
      `deviceCode=`,
      `ext1=${encodeURIComponent(window.location.href)}`,
      `ext2=${isTpData ? eventValue : JSON.stringify(this.getHrefParamObj())}`,
      `ext4=${plat}-h5`,
      `ext5=${window.gameInfo['ENNAME']}`,
      `ext6=${new Date().getTime()}`,
      `ext7=${eventValue || ''}`,
      `ext8=${eventType || ''}`,
      `ext10=${sessionId}`,
      `ext11=${cid}`,
      `ext12=${/[\u4e00-\u9fa5]/.test(scid) ? scid : encodeURIComponent(scid)}`,
      `ext13=${roleId}`,
      `ext14=${userId}`,
      `ext15=${document.referrer || ''}`,
      `ext16=${window.location.pathname}`,
      `ext17=${window.location.host}`,
      `ext18=${navigator.userAgent}`,
      `ext19=${isTpData ? getTpPlatform() : getPhoneModel()}`,
      `ext20=20220715`,
      `ext22=${gclid}`,
      `ext23=${
        this.getHrefParam('packageName') || window.pageInfo.packageName
      }`,
      `adid=${adid || encodeURIComponent(this.getHrefParam('adid'))}`,
      `adname=${adname || encodeURIComponent(this.getHrefParam('adname'))}`,
      `camid=${camid || encodeURIComponent(this.getHrefParam('camid'))}`,
      `camname=${camname || encodeURIComponent(this.getHrefParam('camname'))}`,
      `adsetid=${adsetid || encodeURIComponent(this.getHrefParam('adsetid'))}`,
      `adsetname=${
        adsetname || encodeURIComponent(this.getHrefParam('adsetname'))
      }`
    ]

    const statisticsImg = document.createElement('img')
    statisticsImg.src = `${statisticsUrl[plat]}?${paramsArr.join('&')}`
  }
}
