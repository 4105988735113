<template>
  <div class="guide-popup">
    <div class="guide-popup-shade" @click="hidePopup"></div>
    <div class="guide-popup-content">
      <p class="guide-popup-title">{{ letter.voucherAddHome }}</p>
      <p class="guide-popup-sub-title"></p>
      <div class="guide-popup-img-wrap">
        <div class="phone"></div>
        <img class="logo" :src="logo" alt="logo" />
        <div class="shade"></div>
        <img class="logo-big" :src="logo" alt="logo" />
      </div>
      <div class="guide-popup-tip">
        {{ letter.voucherAddHomeTipTwo }} <span class="share"></span>
      </div>
      <div class="guide-popup-tip tip2">
        {{ letter.voucherAddHomeTipThree }} <span class="mark"></span>
      </div>
      <a
        class="guide-popup-close j-click-coupon-close"
        href="javascript:;"
        @click="hidePopup"
        >&times;</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SafariPwaGuidePopup',
  emits: ['close'],
  data() {
    return {
      logo: `//${window.location.host}/h5/pwa/icon/icon${window.pageInfo.game_id}.png`
    }
  },
  computed: {
    ...mapGetters({
      letter: 'letter'
    })
  },
  methods: {
    hidePopup() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/_dimen.scss';

.guide-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;

  &-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    width: 354px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
  }
  
  &-title {
    margin-top: 19.5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    color: #333333;
  }
  
  &-sub-title {
    text-align: center;
    font-size: 13px;
    color: #666666;
    margin-bottom: 10px;
  }
  
  &-img-wrap {
    position: relative;
    background: url($img + 'phone.png') no-repeat;
    width: 182px; 
    height: 154px; 
    margin: 6px auto; 
    
    .phone {
      position: absolute;
      width: 92px; 
      height: 154px;
      left: 0;
      top: 0;
      background: url($img + 'phone.png') no-repeat;
    }
    
    .logo {
      position: absolute;
      width: 12px; 
      height: 12px; 
      left: 32px; 
      top: 66px;
      border-radius: 20px;
    }
    
    .shade {
      position: absolute;
      width: 116px;
      height: 98px; 
      left: 32px; 
      top: 26px; 
      background: url($img + 'juxing.png') no-repeat;
    }
    
    .logo-big {
      position: absolute;
      width: 96px; 
      height: 96px; 
      right: 0;
      top: 26px;
      border-radius: 20px; 
    }
  }
  
  &-tip {
    width: 100%;
    color: #333;
    font-size: 14px; 
    display: flex;
    align-items: center;
    justify-content: center;
    
    .share {
      width: 48px; 
      height: 48px; 
      background: url($img + 'safari-guide-send.png') no-repeat center/contain;
    }
    
    .mark {
      width: 52px; 
      height: 50px; 
      background: url($img + 'safari-guide-mark.png') no-repeat center/contain;
    }
    
    &.tip2 {
      margin-bottom: 18px;
    }
  }

  &-close {
    position: absolute;
    right: 16px; 
    top: 12px;
    width: 18px; 
    height: 18px; 
    text-align: center;
    line-height: 18px; 
    font-size: 18px;
    text-decoration: none;
    color: #333333;
  }
}
</style>
