import { mapActions, mapGetters } from 'vuex';
import { fbSdkGetStatus, fbSdkLogin } from '../utiles/thirdPartySdkFn';

export default {
  computed: {
    ...mapGetters(['letter']),
  },

  methods: {
    async excFbCanvasLogin() {
      await fbSdkGetStatus().then(async res => {
        if (res.status === 'connected') {
          await this.fbCanvasLogin({
            token: res.authResponse.accessToken,
            id: res.authResponse.userID,
          });
        } else {
          await fbSdkLogin().then(this.excFbCanvasLogin, () => {
            alert(this.letter.fb_canvas_login_fail);
          });
        }
      });
    },

    ...mapActions(['fbCanvasLogin']),
  },
};
