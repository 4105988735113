<template>
  <div class="aside-box">
    <button class="close" @click="closeAsidePopup"></button>
    <slot></slot>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      asideType: window.pageInfo['asidePopupContent'] || '',
    };
  },
  methods: {
    closeAsidePopup() {
      this.$store.commit('updateAsidePopupState', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_dimen';
@import '../assets/scss/_mixins';
$img: '../assets/image/';
.aside-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 580vh;
  max-width: 90%;
  height: 100%;
  background-color: white;
  z-index: 10;

  .close {
    position: absolute;
    width: 59vh;
    height: 258vh;
    top: 50%;
    left: -59vh;
    margin-top: -129vh;
    z-index: -1;
    background: url($img + 'close-btn.png') no-repeat center center;
    background-size: contain;
    @extend %hover;
  }
}
</style>
