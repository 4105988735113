export const isFn = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Function]';
};

export const isStr = function (obj) {
  return Object.prototype.toString.call(obj) === '[object String]';
};

export const isArr = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
};

export const isObj = function (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};
