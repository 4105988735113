<template>
  <div class="nbpf" :style="bgStyle">
    <template v-if="isOldLoginWay">
      <button
        class="nbpf-btn"
        :style="btnStyle"
        v-if="showLogin"
        @click="login"
      ></button>
    </template>
    <template v-else>
      <div class="game-start" v-if="showSimple || showLogin">
        <template v-if="showSimple">
          <p class="game-agreement">
            <a class="agree-text" @click="showAgreement('simple')"
              >会員規約等（必読）</a
            >に同意して
          </p>
          <button class="start-btn" v-if="showSimple" @click="login('simple')">
            新規かんたん会員で始める
          </button>
        </template>
        <p class="game-agree" v-if="showLogin">
          <a class="agree-text" @click="showAgreement">同意事項等（必読）</a
          >に同意して
        </p>
        <button class="start-btn" v-if="showLogin" @click="login">
          {{ btnText }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getJSON, getHrefParam } from '../store/ultile';
import { loadMobageSDK, mobageCommonParams } from '../mixins/mobage';
import ajax from '../config/ajax';
import globalConfig from '../utiles/globalConfig';

export default {
  name: 'nbpf',
  data() {
    return {
      IS_TEST_ENV: getHrefParam('test') == 1,
      gameId: window.gameId,
      loginType: 'login',
      bgStyle: '',
      btnStyle: '',
      isOldLoginWay: false,
      isLogin: false,
      showLogin: false,
      showSimple: false,
    };
  },
  computed: {
    ...mapGetters(['mobageState', 'platform']),
    btnText() {
      let obj = {
        login: 'モバゲーIDで始める',
        connect: 'ゲームを始める',
      };
      return obj[this.loginType];
    },
  },
  methods: {
    ...mapActions(['judgeIsLogin']),
    initParams() {
      let thirdPlatformConfig = window.pageInfo.thirdPlatformConfig;
      let { nbpfBg, nbpfBtn, oldLoginWay } = thirdPlatformConfig.mobage || {
        nbpfBg: '',
        nbpfBtn: '',
        oldLoginWay: false,
      };
      this.isOldLoginWay = oldLoginWay;
      this.bgStyle = `background-image: url(${nbpfBg})`;
      this.btnStyle = `background-image: url(${nbpfBtn})`;
    },
    showAgreement(type) {
      let obj = type === 'simple' ? { isEasyRegistration: true } : {};
      mobage.ui.show('client_agreement_menu', obj);
    },
    login(type) {
      let params = {
        state: this.mobageState,
        requestType: 'window', // 指定 ""window"" 后，便会依 Hybrid 流程发送授权请求
      };
      // 会员登录
      type === 'simple' && (params.easyRegistration = true); // 指定 true 后，便会与授权请求同时发行简易会员
      // 重新登录
      mobage.oauth.connect(params, (err, result) => {
        if (err) {
          globalConfig.log('login failed: error = ', err);
        } else {
          globalConfig.log('result:', result);
          globalConfig.log('mobage.oauth.connect is OK(login success)');
          this.sendToRedirectURI(result);
        }
      });
      /**
       * connect后的响应
        {
          connected:true,
          login:true,
          response: {
              code :"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
              state :"mobage-connect_XXXXXXXXXXXXXXXXXXXXXXX",
              session_state :"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          }
        }
       */
    },
    getMobageLoginStatus() {
      let params = {
        state: this.mobageState,
        responseType: ['code', 'token'],
      };
      mobage.oauth.getConnectedStatus(params, (err, result) => {
        globalConfig.log(err, result);
        if (result) {
          // 已登录
          this.isLogin = true;
          globalConfig.log('已登录 getConnectedStatus OK');
          globalConfig.log('getConnectedStatus:' + result);
          this.sendToRedirectURI(result);
        } else {
          this.isLogin = false;
          // 没有登录显示nbpf
          globalConfig.log('未登录 getConnectedStatus failed');
          if (!this.isOldLoginWay) {
            err.login ? (this.loginType = 'connect') : (this.showSimple = true);
          }
          this.showLogin = true;
          // 未登录，显示该登录组件给用户点击跳转平台登录页
          this.$store.commit('updateShowNbpf', true);
        }
      });
    },
    sendToRedirectURI(result) {
      let url = this.IS_TEST_ENV
        ? ajax.mobage.login.nbpfLoginSandbox
        : ajax.mobage.login.nbpfLogin;
      let res = result.response;
      getJSON(url, {
        code: res.code,
        state: res.state,
        session_state: res.session_state,
        cid: getHrefParam('cid') || '250',
        scid: getHrefParam('scid') || 'web',
        ...mobageCommonParams,
      })
        .then(data => {
          globalConfig.log(data);
          this.$store.dispatch('initMobageSessionListener', {
            state: data.data.MOBAGE_SESSION_STATE,
          });
          this.judgeIsLogin();
          // 登录完成，关闭该登录弹窗组件
          this.$store.commit('updateNbpfLoginStatus', true);
          this.$store.commit('updateShowNbpf', false);
        })
        .catch(err => {
          console.error(err);
          alert(err);
        });
    },
    async getMobageState() {
      let params = {
        game_id: this.gameId,
        cid: getHrefParam('cid') || '250',
        scid: getHrefParam('scid') || 'web',
        ...mobageCommonParams,
      };
      let { errmsg, data, res } = await getJSON(ajax.mobage.login.auth, params);
      globalConfig.log(data.state);
      // 这个state是mobage的token
      this.$store.commit('updateMobageState', data.state);
    },
  },
  beforeCreate() {
    // 加载Mobage的SDK
    loadMobageSDK();
  },
  async created() {
    this.initParams();
    await this.getMobageState();
    window.mobageReady
      ? this.getMobageLoginStatus()
      : document.addEventListener('mobageReady', () => {
          this.getMobageLoginStatus();
        });
  },
};
</script>
<style lang="scss" scoped>
.nbpf {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #ccc;

  &-btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20vh;
    width: 90%;
    height: 33%;
    padding: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.game-start {
  z-index: 15;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  bottom: 0;
  width: 86%;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  transform: translate(-50%);
  @media screen and (orientation: landscape) {
    transform: translate(-50%) scale(0.8);
  }
  .game-agreement {
    width: 100%;
    margin: 10px auto 5px;
    color: #333;
  }
  .start-btn {
    width: 100%;
    height: 50px;
    background: #32c9ec;
    line-height: 50px;
    font-weight: bold;
    font-size: 120%;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 2px #08c9e8;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  .game-agree {
    width: 100%;
    margin: 15px auto 5px;
    color: #333;
  }
  .agree-text {
    color: #ff3b00;
    cursor: pointer;
  }
}
</style>
