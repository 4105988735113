<template>
  <div class="game-box" :style="gameStyle.box + contentHeight">
    <!-- 全屏按钮开始 -->
    <a
      href="javascript:;"
      @click="fullscreen"
      class="fullscreen j-fullscreen"
      v-if="ispc && isShowFullscreen"
    >
      <img :src="fullscreenImg" class="normal" alt="" />

      <img
        :src="fullscreenHoverImg"
        v-if="fullscreenHoverImg"
        class="hover"
        alt=""
      />
    </a>
    <iframe
      :src="termLink"
      title="terms"
      class="iframe game-content terms-iframe"
      v-if="!showGame && showTerm"
    ></iframe>
    <!-- 全屏按钮结束 -->
    <div
      class="game-content"
      ref="gamepage"
      :style="gameStyle.content"
      id="j-game-content"
      v-show="showGame"
    >
      <!-- 退出全屏按钮开始 -->
      <a
        href="javascript:;"
        @click="exitfullscreen"
        class="exitfullscreen"
        v-if="ispc && isShowFullscreen"
        v-show="isfullscreen"
      >
        <img :src="exitfullscreenImg" class="normal" alt="" />
        <img
          :src="exitfullscreenHoverImg"
          v-if="exitfullscreenHoverImg"
          class="hover"
          alt=""
        />
      </a>
      <!-- 退出全屏按钮结束 -->

      <!-- 研发游戏界面开始 -->
      <iframe
        :src="gameLink"
        class="iframe"
        v-show="!!gameLink"
        ref="curGamePage"
        @load="gameLoad"
        id="game-iframe"
        :title="gameName"
        data-google-interstitial="false"
      ></iframe>
      <!-- 研发游戏界面结束 -->

      <!-- 登录窗口开始 -->
      <transition name="fade">
        <template v-if="!isLogin && canShowLoginPannel">
          <template v-if="loginType == 'account'">
            <LoginPopupAccountGlobal v-if="isGlobal"></LoginPopupAccountGlobal>
            <LoginPopupAccountGm99 v-if="isGm99"></LoginPopupAccountGm99>
          </template>
          <template v-else-if="loginType == 'thirdParty'">
            <LoginPopupThirdPartyGlobal></LoginPopupThirdPartyGlobal>
          </template>
          <template v-else-if="loginType == 'fbcanvas'">
            <LoginPopupFbCanvasCommon></LoginPopupFbCanvasCommon>
          </template>
        </template>
      </transition>
      <!-- 登录窗口结束 -->

      <!-- 侧拉栏开始 -->
      <transition name="slide-fade">
        <AsidePopup v-show="asidePopupState">
          <template>
            <AsideCommonGlobal
              v-if="asideType === 'default'"
            ></AsideCommonGlobal>
            <AsideUserCenter
              v-if="asideType === 'userCenter'"
            ></AsideUserCenter>
          </template>
        </AsidePopup>
      </transition>
      <!-- 侧拉栏结束 -->

      <!-- 储值开始 -->
      <transition name="fade">
        <template v-if="storePopupState">
          <StorePopupListGlobal
            v-if="storeType === 'popup'"
          ></StorePopupListGlobal>
          <StorePopupFbCanvas
            v-else-if="storeType === 'fbcanvas'"
          ></StorePopupFbCanvas>
          <StorePopupCommon v-else></StorePopupCommon>
        </template>
      </transition>
      <!-- 储值结束 -->

      <!-- 遮罩开始 -->
      <transition name="fade">
        <em
          class="mask"
          v-show="!isLogin || (isLogin && (asidePopupState || storePopupState))"
        ></em>
      </transition>
      <!-- 遮罩结束 -->

      <!-- 提示框开始 -->
      <transition name="fade">
        <template>
          <TipsCommon
            v-show="commonTipsContent"
            v-if="!isFbCanvas"
          ></TipsCommon>
          <TipsFb v-show="commonTipsContent" v-if="isFbCanvas"></TipsFb>
        </template>
      </transition>
      <!-- 提示框结束 -->

      <!-- 序号发奖相关提示框开始 -->
      <template v-if="giftType == 'number'">
        <GiftListPopup v-show="giftListPopupState"></GiftListPopup>
        <GiftGetPopup v-show="giftNumber"></GiftGetPopup>
      </template>
      <!-- 序号发奖相关提示框结束 -->

      <!-- 浮标开始 -->
      <FloatIcon v-if="!this.isFbCanvas && this.showFloatIcon"></FloatIcon>
      <!-- 浮标结束 -->

      <!-- 弹屏 -->
      <transition name="fade">
        <template v-if="isOpenTca">
          <div v-show="tpShow">
            <TpIframe
              :tpLink="tpLink"
              :tpInitInfo="tpInitInfo"
              @hideTpPopup="hideTp"
              @showTp="showTp"
              :pcIsHorizontal="pcIsHorizontal"
              :mobileIsLandscape="mobileIsLandscape"
            ></TpIframe>
          </div>
        </template>
      </transition>

      <!-- tca全局loading -->
      <transition name="fade">
        <div class="loading-mask" v-if="showTcaLoading">
          <div class="load">
            <em class="loading load1"></em>
            <em class="loading load2"></em>
            <em class="loading load3"></em>
          </div>
        </div>
      </transition>
      <!-- 越南大掌柜引导下载app弹屏 -->
      <transition name="fade">
        <div v-if="showGuideTp" class="guide-tp">
          <div class="guide-tp-content">
            <div class="guide-tp-asset" v-if="ispc"></div>
            <div class="guide-tp-asset m" @click="clickDownload" v-else></div>
            <div class="guide-tp-close" @click="showGuideTp = false"></div>
          </div>
        </div>
      </transition>
      <!-- 提示框结束 -->
    </div>
  </div>
</template>

<script>
import AsidePopup from './AsidePopup'
import TipsFb from './commission/TipsFb'
import TipsCommon from './commission/TipsCommon'
import { mapActions, mapGetters } from 'vuex'
import { scrollTop } from '../utiles/index'
import {
  fbSdkInit,
  googleSdkInit,
  getFingerprint
} from '../utiles/thirdPartySdkFn'
import { pxToVh, getRealGameContentWidthPX } from '../utiles/gameWidth'
import globalConfig from '../utiles/globalConfig'
import GiftListPopup from './GiftListPopup'
import GiftGetPopup from './GiftGetPopup'
import Init from '../mixins/Init'
import Fullscreen from '../mixins/Fullscreen'
import CanvasLogin from '../mixins/CanvasLogin'
import Platform from '../mixins/Platform'
import SdkListener from '../mixins/SdkListener'
import { agePopupCookieName } from './AgePopup'
import FloatIcon from './FloatIcon'
import LoginPopupFbCanvasCommon from './commission/LoginPopupFbCanvasCommon'
import LoginPopupAccountGlobal from './commission/LoginPopupAccountGlobal'
import LoginPopupThirdPartyGlobal from './commission/LoginPopupThirdPartyGlobal'
import LoginPopupAccountGm99 from './commission/LoginPopupAccountGm99'
import StorePopupListGlobal from './commission/StorePopupListGlobal'
import StorePopupCommon from './commission/StorePopupCommon'
import AsideCommonGlobal from './commission/AsideCommonGlobal'
import AsideUserCenter from './commission/AsideUserCenter'
import Exe from '../mixins/Exe'
import TpIframe from './tanping/TpIframe'
import Tca from '../mixins/Tca'
import Tanping from '../mixins/Tanping'
import { getHrefParam, getJSON } from '../store/ultile'
import md5 from 'md5'
export default {
  name: 'gameIframe',
  data() {
    return {
      storePageSrc: '',
      canShowLoginPannel: false,
      ispc: !window.isPhone,
      isFbCanvas: window.isFbCanvas,
      fbLoginClick: false,
      loginType: window.pageInfo['loginType'],
      storeType: window.pageInfo['storeType'],
      asideType: window.pageInfo['asidePopupType'],
      giftType: window.pageInfo['awardType'],
      showFloatIcon: window.pageInfo['showFloatIcon'],
      gameName: window.gameInfo.NAME || '',
      showGuideTp: false,
      eventName: '',
      showGame: true, // 是否展示H5游戏，韩国大掌柜先展示协议确认弹窗
      showTerm: window.gameInfo.GAME_CODE == 'dzgkr',
      fingerText: ''
    }
  },
  watch: {
    userId(newV, oldV) {
      if (newV && newV !== oldV) {
        this.getGameLink()
        if (
          !this.isFbCanvas &&
          ((window.pageInfo['favourAward'] &&
            window.pageInfo['favourAward']['isAward']) ||
            (window.pageInfo['downloadAward'] &&
              window.pageInfo['downloadAward']['isAward']))
        ) {
          this.getAwardState()
        }
        scrollTop()

        this.getCurGclid().then(() => {
          globalConfig.ajaxStatistics({
            eventName: 'login',
            eventType: 'custom_loss',
            loginAccount: this.userName,
            userId: this.userId,
            gclid: this.gclid,
            cid: this.cid,
            scid: this.scid,
            fbc: this.fbc,
            fbp: this.fbp,
            adid: this.adid,
            adname: this.adname,
            camid: this.camid,
            camname: this.camname,
            adsetid: this.adsetid,
            adsetname: this.adsetname
          })
        })
        if (!!this.isElectronExe) {
          this.exeGetLoginInfo()
        }
      }
    }
  },
  computed: {
    gameLink() {
      return this.$store.state.gameLink
    },
    termLink() {
      return `https://gabres.${globalConfig.getMainHost()}/platform/terms.html?gameId=${
        this.gameId
      }&machineId=${this.machineId || this.fingerText}&isH5=1#/`
    },
    contentHeight() {
      return this.storePopupState || this.asidePopupState ? 'z-index:4;' : ''
    },
    gameStyle() {
      const isHorizontal = this.isHorizontal
      const widthSize = this.widthSize
      const marginTop = window.pageInfo['marginTop']
      const gameWidth = window.pageInfo['gameWidth']
      let boxWidthStyle = ''
      let contentWidthStyle = ''
      if (!window.isPhone) {
        if (isHorizontal && widthSize === 'big') {
          boxWidthStyle = 'width: 100vw'
          contentWidthStyle = gameWidth
            ? `width: ${gameWidth}vh`
            : boxWidthStyle
        } else {
          boxWidthStyle = contentWidthStyle = `width:${pxToVh(
            getRealGameContentWidthPX(isHorizontal, widthSize)
          )}vh;`
        }
      }
      const marginStyle =
        !!marginTop && !window.isPhone
          ? `margin-top:${pxToVh(marginTop)}vh; margin-bottom:${pxToVh(
              76 - marginTop
            )}vh;`
          : ''
      return {
        box: boxWidthStyle + marginStyle,
        content: contentWidthStyle + marginStyle
      }
    },
    ...mapGetters([
      'widthSize',
      'isHorizontal',
      'letter',
      'isLogin',
      'gameId',
      'lang',
      'userName',
      'isfullscreen',
      'commonTipsContent',
      'appId',
      'location',
      'giftListPopupState',
      'giftNumber',
      'storePopupState',
      'asidePopupState',
      'userId',
      'gclid',
      'cid',
      'scid',
      'serverId',
      'roleId'
    ])
  },
  mixins: [
    Init,
    Fullscreen,
    Platform,
    SdkListener,
    CanvasLogin,
    Exe,
    Tca,
    Tanping
  ],
  components: {
    AsidePopup,
    TipsFb,
    TipsCommon,
    GiftListPopup,
    GiftGetPopup,
    FloatIcon,
    LoginPopupFbCanvasCommon,
    LoginPopupAccountGlobal,
    LoginPopupThirdPartyGlobal,
    LoginPopupAccountGm99,
    StorePopupCommon,
    StorePopupListGlobal,
    AsideCommonGlobal,
    AsideUserCenter,
    TpIframe
  },
  async created() {
    // 2024.05.17 邓智轩：韩国大掌柜先展示协议确认弹窗
    if (
      this.showTerm &&
      parseInt(globalConfig.getLclStorageItem('termsAgree'), 10) != 1
    ) {
      this.showGame = false
      window.addEventListener('message', e => {
        if (e.origin != `https://gabres.${globalConfig.getMainHost()}`) {
          return
        }
        if (e.data === 'close') {
          this.showGame = true
          globalConfig.setLclStorageItem('termsAgree', 1)
        }
      })
    }
    if (!this.isFbCanvas) {
      // 判断是否登录
      await this.judgeIsLogin({
        isCheck: true,
        isFirst: true
      })

      if (
        !this.isLogin &&
        window.pageInfo.agePopup &&
        !globalConfig.getCookie(agePopupCookieName)
      ) {
        this.$store.commit('updateAgePopupState', true)
      }

      // 更新第三方登录列表
      this.getThirdLoginList()

      this.canShowLoginPannel = true
      googleSdkInit()
      this.getTermState()
      await this.getFBloginAppId()
    }
    fbSdkInit(this.appId, async () => {
      if (this.isFbCanvas) {
        await this.excFbCanvasLogin()
        this.canShowLoginPannel = true
      }
    })
    getFingerprint().then(uuid => {
      this.fingerText = uuid
    })
  },
  methods: {
    ...mapActions([
      'getTermState',
      'getAwardState',
      'judgeIsLogin',
      'getThirdLoginList',
      'getFBloginAppId',
      'getGameLink',
      'getCurGclid',
      'exeGetLoginInfo'
    ]),
    handleDownloadGuideTp(eventName) {
      this.eventName = eventName
      this.sendTpData('reach')
      this.checkHasGetReward().then(hasGetReward => {
        !hasGetReward && this.showDownloadGuideTp()
      })
    },
    async checkHasGetReward() {
      let hasGetReward = 0
      let { res, data } = await getJSON(
        `//gactivityapi.${globalConfig.getMainHost()}/activity/execute?name=dcqh5dlapp&action=isShow`,
        {
          params: {
            loginAccount: this.userName,
            uid: this.userId
          }
        }
      )
      if (res && res.result && +res.result === 1) {
        hasGetReward = (data && +data.isShow) || 0
        this.sendTpData('isGetReward:' + hasGetReward)
        return Boolean(hasGetReward)
      }
      return true
    },
    showDownloadGuideTp() {
      this.showGuideTp = true
      let loginAccount = this.userName
      let timestamp = Date.parse(new Date())
      let uid = this.userId
      getJSON(
        `//gactivityapi.${globalConfig.getMainHost()}/activity/execute?name=dcqh5dlapp&action=record`,
        {
          params: {
            loginAccount: loginAccount,
            uid: uid,
            t: timestamp,
            sign: md5(
              'N4TzcZ3MJp' + this.gameId + loginAccount + uid + timestamp
            )
          }
        }
      )
      this.sendTpData('show')
    },
    sendTpData(eventValue) {
      globalConfig.ajaxStatistics({
        eventName: this.eventName,
        eventValue: eventValue,
        eventType: 'custom_tp',
        loginAccount: this.userName,
        serverId: this.serverId,
        roleId: this.roleId,
        userId: this.userId,
        gclid: this.gclid,
        cid: this.cid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname
      })
    },
    clickDownload() {
      window.open('https://dzgvn.onelink.me/Alwc/game', '_blank')
      this.sendTpData('clickDownload')
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-iframe {
  background-color: rgba(0, 0, 0, 0.5);
}
.game-box {
  margin: auto;
  position: relative;
  z-index: 3;
}
.fullscreen {
  position: absolute;
  top: 0;
  left: calc(100% + 44vh);
  z-index: 2;
}

.exitfullscreen {
  position: absolute;
  top: 0;
  right: 0vh;
  z-index: 21;
}

.fullscreen,
.exitfullscreen {
  .hover {
    display: none;
  }
  &:hover {
    .hover {
      display: block;
    }
    .normal {
      display: none;
    }
  }
}
.loading-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}
.load {
  position: fixed;
  left: 50%;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  .loading {
    display: inline-block;
    margin: auto 2px;
    border: 2px solid #c9a117;
    width: 10px;
    height: 30px;
    background: #ffca12;
    animation: loading 1s infinite;
  }
  .load2 {
    animation-delay: 0.1s;
  }
  .load3 {
    animation-delay: 0.2s;
  }
}
@keyframes loading {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.4;
    transform: scale(0.5);
  }
}

.guide-tp {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  $img: '../assets/image/';
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 425px;
  }
  &-asset {
    width: 100%;
    height: 100%;
    background: url($img + 'dzg_tp.png') no-repeat center center;
    background-size: contain;
    &.m {
      background: url($img + 'dzg_tp_m.png') no-repeat center center;
      background-size: contain;
    }
  }
  &-close {
    position: absolute;
    top: -46px;
    right: -34px;
    width: 47px;
    height: 49px;
    background: url($img + 'dzg_tp_close.png') no-repeat center center;
    background-size: contain;
    cursor: pointer;
  }
}
</style>
