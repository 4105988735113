<template>
  <div class="passport-sdk">
    <div class="passport-sdk-header">
      <a class="back" @click="iframeForward" href="javascript:;"></a>
      <img :src="logoUrl" class="logo" alt="" />
      <a class="x" @click="closeAsidePopup" href="javascript:;"></a>
    </div>
    <iframe
      :src="iframePassportLink"
      class="passport-iframe"
      id="j-passport-iframe"
      ref="webPassportIframe"
      title=""
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoUrl from '../../mixins/LogoUrl'
import url from '../../config/url'
import { getLoginId } from '../../utiles/thirdPartySdkFn'
import globalConfig from '../../utiles/globalConfig'

export default {
  data() {
    return {
      ismgcode: window.pageInfo['showMgcode'],
      userMode: window.pageInfo['userMode'],
      showDeleteAccountBtn: window.pageInfo['showDeleteAccount'],
      iframePassportLink: '',
      firstOpenCount: 0
    }
  },
  mixins: [LogoUrl],
  watch: {
    asidePopupState(newVal) {
      // 监听中心中心开关，如果关闭了回到用户中心首页
      if (this.iframePassportLink && !newVal) {
        this.postMessageToPassportIframe('toHome')
      }
    },
    passportLink(newVal, oldVal) {
      // 已登录 且 没有进入游戏 时，第一次打开用户中心给iframe的src赋值，加载用户中心
      if (this.firstOpenCount === 1 && newVal) {
        this.iframePassportLink = newVal
        return
      }
      // 给iframe的src为空时 或者 与上一次的src相同时，不给iframe赋值，避免重新加载用户中心
      if (newVal === '' || newVal === oldVal) {
        return
      }
      // 用户数中心链接的参数更新了 或 进入游戏了，加载用户中心
      this.iframePassportLink = newVal
    }
  },
  computed: {
    passportLink() {
      let link = globalConfig.hrefAddParams(url[this.platform].userCenter, {
        gameId: this.gameId,
        serverId: this.serverId,
        userId: this.userId,
        roleName: this.roleName,
        roleId: this.roleId,
        loginAccount: this.userName,
        language: this.lang,
        appLanguage: this.lang,
        ismgcode: +this.ismgcode,
        showDeleteAccountBtn: +this.showDeleteAccountBtn,
        userMode: this.userMode,
        loginId: getLoginId(),
        device: globalConfig.getHrefParam('device'), // 微端打开则为exe，其它环境打开默认为空
        packageName:
          globalConfig.getHrefParam('packageName') ||
          window.pageInfo.packageName,
        showPwaInstall: this.showPwaInstall ? 1 : 0 // 表示是否显示PWA安装提示
      })
      // return 用户中心的 src 给 watch 处理，中断给 iframe 的 src 赋值，每次赋值即使相同都会导致重新加载
      // 没有登录时不加载用户中心
      if (!this.isLogin) {
        return ''
      }
      // 进入游戏后加载用户中心
      if (!(this.serverId === '' || this.roleId === '')) {
        return link
      }
      // 已登录未进入游戏时 && 用户点开了用户中心 -> 加载用户中心
      if (this.asidePopupState) {
        this.firstOpenCount++
        return link
      }
      // 已登录未进入游戏时 && 用户没开用户中心 -> 不加载用户中心
      return ''
    },
    ...mapGetters([
      'isLogin',
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'userId',
      'userName',
      'lang',
      'platform',
      'asidePopupState',
      'showPwaInstall'
    ])
  },
  methods: {
    postMessageToPassportIframe(signal) {
      this.$refs.webPassportIframe &&
        this.$refs.webPassportIframe.contentWindow.postMessage(signal, '*')
    },
    closeAsidePopup() {
      this.$store.commit('updateAsidePopupState', false)
    },
    iframeForward() {
      this.postMessageToPassportIframe('toHome')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.passport-sdk {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .passport-iframe {
    width: 100%;
    height: calc(100% - 54vh);
    overflow: hidden;
    border: none;
  }
  &-header {
    height: 54vh;
    width: 100%;
    background-color: white;
    font-size: 0;
    margin: 0;
    position: relative;
    .back,
    .x {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
    .back {
      background: url($img + 'aside-back.png') no-repeat center center;
      background-size: contain;
      width: 54vh;
      height: 54vh;
      left: 0;
    }
    .x {
      background: url($img + 'aside-close.png') no-repeat center center;
      background-size: contain;
      width: 54vh;
      height: 54vh;
      right: 0;
    }
    .logo {
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
