import { mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'
import ajax from '../config/ajax'
import { getJSON, getHrefParam } from '../store/ultile'

const FROM_MOBAGE = globalConfig.getThirdPlatformType() === 'mobage'
const MOBAGE_CONFIG = window.pageInfo.thirdPlatformConfig['mobage']
const IS_TEST_ENV = globalConfig.getIsTest()
export const SHOW_MOBAGE_TIP = FROM_MOBAGE

const postMessageToParent = data => {
  if (window.parent === window) {
    return
  }
  window.parent &&
    window.parent.postMessage &&
    typeof window.parent.postMessage === 'function' &&
    window.parent.postMessage(data, '*')
}

export const mobageCommonParams = {
  language: getHrefParam('language') || 'en-US',
  appLanguage: getHrefParam('appLanguage') || 'en-US'
}

export const loadMobageSDK = () => {
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://cdn${
    getHrefParam('test') == 1 ? '-sb' : ''
  }-connect.mobage.jp/jssdk/mobage-jssdk-client.3.10.2.min.js`
  document.getElementsByTagName('script')[0].parentNode.appendChild(script)
  document.addEventListener('mobageReady', function () {
    window.mobageReady = true
    mobage.init({
      clientId: MOBAGE_CONFIG && MOBAGE_CONFIG.clientId + '-4',
      redirectUri:
        'https:' +
        (IS_TEST_ENV
          ? ajax.mobage.login.nbpfLoginSandbox
          : ajax.mobage.login.nbpfLogin)
    })
  })
}

export const uploadEnterGame = data => {
  data = Object.assign({}, data, { type: 'enterGame' })
  postMessageToParent(data)
}

export const uploadCreateRole = data => {
  data = Object.assign({}, data, { type: 'createRole' })
  postMessageToParent(data)
}

export default {
  data() {
    return {
      IS_TEST_ENV: IS_TEST_ENV,
      IS_MOBAGE_PC: !window.isPhone
    }
  },
  computed: {
    ...mapGetters(['sdkParams', 'roleLevel'])
  },
  methods: {
    // // 开启客诉页面
    // openThirdPlatformUserCenter(type) {
    //   if (this.IS_MOBAGE_PC) {
    //     window.open(`http://${this.IS_TEST_ENV ? 'sb.' : ''}yahoo-mbga.jp/game/${MOBAGE_CONFIG.clientId}}/detail?fourth`, '_blank'); // 正式环境链接
    //     return;
    //   }
    //   type === 'service' && mobage.ui.show('inquiry');
    // },
    // // 开启平台规约
    // openCommercialLaw(type) {
    //   if (this.IS_MOBAGE_PC) {
    //     window.open(`http://${this.IS_TEST_ENV ? 'sb.' : ''}yahoo-mbga.jp/game/${MOBAGE_CONFIG.clientId}/detail?third`, '_blank'); // 正式环境链接
    //     return;
    //   }
    //   type === 'commercial_transaction' && mobage.ui.show('commercial_transaction');
    // },
    // // 开启活动注意事项
    // openSettingChange(type) {
    //   if (this.IS_MOBAGE_PC) {
    //     this.IS_TEST_ENV ?
    //       window.open('//sb.yahoo-mbga.jp/config/mygame', '_blank') : // 沙盒
    //       window.open('//yahoo-mbga.jp/config/mygame', '_blank'); // 正式
    //     return;
    //   }
    //   if (type === 'client_configuration') {
    //     mobage.ui.open(
    //       'client_configuration',
    //       {
    //         requiredMessage: 1,
    //         requiredMygame: 1,
    //       },
    //       function (error, result) {
    //         globalConfig.log(error, result);
    //       }
    //     );
    //   }
    // },
    // // 开启活动注意事项
    // openVoucherNotes() {
    //   let url = this.IS_MOBAGE_PC ? 'http://yahoo-mbga.jp/page/kiyaku/voucher.html' : 'http://sp.mbga.jp/voucher/notes.html';
    //   window.open(url, '_blank'); // 正式环境链接
    // },
    // // 开启梦宝币规则
    // openMobacoinTerms() {
    //   let url = this.IS_MOBAGE_PC ? 'http://yahoo-mbga.jp/page/kiyaku/voucher.html' : 'http://sp.mbga.jp/payment/coin/kiyaku';
    //   window.open(url, '_blank'); // 正式环境链接
    // },
    // // 开启雅虎规则
    // openYahooTerms() {
    //   window.open('http://yahoo-mbga.jp/page/kiyaku/', '_blank');
    // },
    // // 开启会员连接同意规则
    // openMembershipAgreement() {
    //   window.open('http://sp.mbga.jp/kiyaku/', '_blank');
    // },
    // // 开启mobage规则
    // openMobageRule() {
    //   let url = this.IS_MOBAGE_PC ? 'http://yahoo-mbga.jp/page/rule/' : 'http://sp.mbga.jp/rule/';
    //   window.open(url, '_blank'); // 正式环境链接
    // },
    // // 特殊事件处理函数
    // thirdPlatformHandleUserBehave(sdkParam) {
    //   switch (sdkParam.type) {
    //     case 'commercial law':
    //       this.openCommercialLaw('commercial_transaction');
    //       break;
    //     case 'setting_change':
    //       this.openSettingChange('client_configuration');
    //       break;
    //     case 'voucher_notes':
    //       this.openVoucherNotes();
    //       break;
    //     case 'mobacoin_terms':
    //       this.openMobacoinTerms();
    //       break;
    //     case 'yahoo_terms':
    //       this.openYahooTerms();
    //       break;
    //     case 'membership_agreement':
    //       this.openMembershipAgreement();
    //       break;
    //     case 'mobage_rule':
    //       this.openMobageRule();
    //       break;
    //   }
    // },
    // nbpf储值调用
    mobagePay() {
      /**
       sdkParam = {
        action: "pay"
        param: {
          order_no: "pay2|95|953640|1637822321045"  日本平台中江山美人在mobage平台储值中的nbpf储值模式没有给到这个参数
          price: "610"
          productId: "kingdom.app.gold2"
          productName: "黄金"
          remark: "pay2|95|953640|1637822321045|h5_37_jp"
          roleId: "953640"
          roleName: "春巻献"
          serverId: "95"
        }
      }
      */
      if (FROM_MOBAGE && !this.IS_MOBAGE_PC) {
        // nbpf储值
        this.refreshMobageLoginStatus(this.sdkParams)
      } else {
        // pc储值
        this.getMobageSkuInfo(this.sdkParams)
      }
    },
    // pc的储值模式
    getMobageSkuInfo(params) {
      const skuURL = ajax.mobage.pay.productInfo
      getJSON(skuURL, { game_id: this.gameId, product_id: params.productId })
        .then(res => {
          if (res.errmsg) {
            alert(res.errmsg)
            return
          }
          let data = res.data
          postMessageToParent({
            gameId: this.gameId,
            serverId: params.serverId,
            roleId: params.roleId,
            roleName: params.roleName,
            roleLevel: this.roleLevel,
            skuId: +data.SKU_ID,
            remark: params.remark,
            price: +data.TRANS_AMOUNT,
            productName: data.PRODUCT_DESC,
            productImg: globalConfig.getPlatformProductIconUrl(
              params.productId
            ),
            amount: +data.TRANS_AMOUNT,
            type: 'pay',
            cpProductId: params.cpProductId || ''
          })
        })
        .catch(err => {
          alert(err.errmsg || err.msg || err)
        })
      try {
        globalConfig.gaStatistics('exc_store_pay', {
          pid: params.productId,
          pay_type: 'mobage_pc_pay',
          ...this.sdkParams
        })
      } catch (e) {}
    },
    // 刷新我们平台登录态
    async refreshMobageLoginStatus(sdkParam) {
      let refreshSuccess = await this.refreshMobageToken()
      refreshSuccess && this.fetchProductInfo(sdkParam)
    },
    // 发起刷新我们平台登录态的请求
    async refreshMobageToken() {
      return new Promise((resolve, reject) => {
        const url = this.IS_TEST_ENV
          ? ajax.mobage.login.nbpfRefreshLoginSandbox
          : ajax.mobage.login.nbpfRefreshLogin
        getJSON(url, { game_id: this.gameId })
          .then(res => {
            if (res.errmsg) {
              alert(res.errmsg)
              resolve(false)
              return
            }
            resolve(true)
          })
          .catch(err => {
            resolve(false)
          })
      })
    },
    // 获取mobage商品的信息
    async fetchProductInfo(params) {
      let list = await this.$store.dispatch('getMobageProductInfo', params)
      let curProduct = list.find(item => item.PRODUCT_ID === params.productId)
      if (!curProduct) {
        alert('Error')
        return
      }
      this.mobagePaySubmit(curProduct)
    },
    // nbpf提单
    mobagePaySubmit(productInfo) {
      let url = this.IS_TEST_ENV
        ? ajax.mobage.pay.submitSandbox
        : ajax.mobage.pay.submit
      let params = {
        gameId: parseInt(this.gameId, 10),
        serverId: this.sdkParams.serverId,
        roleName: encodeURIComponent(this.sdkParams.roleName),
        roleId: this.sdkParams.roleId,
        paytype: 'mobage_nbpf',
        productId: this.sdkParams.productId,
        amount: productInfo.TRANS_AMOUNT,
        remark: this.sdkParams.remark || this.sdkParams.price || '',
        currency: productInfo.CURRENCY,
        devicePlate: window.isPhone ? 'wap' : 'pc',
        is_sdk_view: '',
        language: 'jp',
        location: 'JP',
        productDesc: (productInfo.PRODUCT_DESC || '').replace(/.*(?:\=)/, ''),
        isJMH5: 1,
        cpProductId: this.sdkParams.cpProductId || ''
      }
      // 日本大掌柜添加特殊参数
      if (+this.gameId === 209) {
        params.sdkRemark = params.remark
        params.productRemark = params.remark
      }
      this.$store
        // 请求确认支付状态
        .dispatch('submitPayType', { url, params })
        .then(response => {
          const res = response.data
          if (+res.result !== 1) {
            alert(res.msg)
            return
          }
          window.mobageReady
            ? this.excMobagePay(res.data)
            : document.addEventListener('mobageReady', () => {
                this.excMobagePay(res.data)
              })
        })
        .catch(() => {
          alert('failed to initialize payment procedures.')
        })
      try {
        globalConfig.gaStatistics('exc_store_pay', {
          pid: this.sdkParams.productId,
          pay_type: 'mobage_nbpf_pay',
          ...this.sdkParams
        })
      } catch (_e) {
        console.error(_e)
      }
    },
    // 判断登录态，请求支付梦宝币
    excMobagePay(order) {
      this.widget = mobage.ui.open(
        'payment',
        {
          transactionId: order.transaction_id,
          orderId: order.order_id
        },
        (error, result) => {
          // Request GameServer to update OrderDB status.
          // The status on OrderDB should be updated from authorized to canceled/error.
          error
            ? this.cancelMobagePay(order)
            : this.fulfillItemOrder(result.response, result.signedResponse)
        }
      )
    },
    // 支付失败，调用失败接口
    cancelMobagePay(order) {
      let url = this.IS_TEST_ENV
        ? ajax.mobage.pay.failSandbox
        : ajax.mobage.pay.fail
      this.$store
        // 确认储值的接口
        .dispatch('submitPayType', { url, params: order })
        .then(_response => {
          // Clear transaction from Client Storage.
          mobage.bank.clearPaymentBacklog(order.transaction_id)
        })
        .catch(_err => {
          console.error(_err)
        })
    },
    // 支付成功，调用发货接口
    fulfillItemOrder(response, signedResponse) {
      let url = this.IS_TEST_ENV
        ? ajax.mobage.pay.successSandbox
        : ajax.mobage.pay.success
      this.$store
        // 确认储值接口
        .dispatch('submitPayType', {
          url,
          params: signedResponse,
          notStringify: true
        })
        .then(res => {
          res = res.data
          ;+res.result !== 1
            ? alert('アイテム付与処理に失敗しました')
            : mobage.bank.clearPaymentBacklog(response.result.payment.id)
        })
        .catch(_err => {
          console.error(_err)
        })
    }
  }
}
