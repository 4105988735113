import host from './host'
import globalConfig from '../utiles/globalConfig'
const mainhost = globalConfig.getMainHost()

export default {
  gm99: {
    userCenter: `//m.${mainhost}/user?isH5=1&pc=1`,
    store: `//${host.gm99.store}${mainhost}/`,
    storeGame: `//${host.gm99.store}${mainhost}/`,
    newStoreGame: `//${host.gm99.store}${mainhost}/`,
    fbPay: `//${host.gm99.store}${mainhost}/h5FbCanvas.html`,
    fbPayPage: `//${host.gm99.store}${mainhost}/facebook_local_money/product_items/app_id/`,
    support: `//${host.gm99.service}${mainhost}/#/wo`,
    login: `//${host.gm99.passort}${mainhost}/`,
    serviceUrl: `//${host.gm99.msupport}${mainhost}/About/termsofuse`,
    privicyUrl: `//${host.gm99.msupport}${mainhost}/About/privacypolicy`,
    findPassword: `//${host.gm99.service}${mainhost}/center?type=password&`,
    code: `//${host.gm99.passort}${mainhost}/verify_image`, //驗證碼接口
    qrcodeLogin: `//${host.gm99.passort}${mainhost}/ajax/h5_qrcode`,
    qrcodeDownload: ``,
    newExeDownload: `//${host.gm99.event}gm99.com/exe/${window.gameInfo.ENNAME}`
  },
  global: {
    userCenter: `//${host.global.abres}${mainhost}/platform/webPassport.html?devicePlate=pc&h5=1&m=1&web=1`,
    store: `//${host.global.pay}${mainhost}?scid=h5`,
    storeGame: `//${host.global.pay}${mainhost}/platform/store.html?scid=h5#/web`,
    newStoreGame: `//${host.global.pay}${mainhost}/platform/officialWebStore.html?scid=h5#/`,
    fbPay: `//${host.global.store}${mainhost}/h5FbCanvas.html`,
    fbPayPage: `//${host.global.store}${mainhost}/facebook_local_money/product_items/app_id/`,
    support: `//${host.global.abres}${mainhost}/platform/webSupport.html`,
    login: `//${host.global.abres}${mainhost}/platform/login.html`,
    serviceUrl: `//${host.global.passort}${mainhost}/center/servicePrivicy/service`,
    privicyUrl: `//${host.global.passort}${mainhost}/center/servicePrivicy/privicy`,
    findPassword: `//${host.global.pay}${mainhost}/platform/passport.html#/findPwd`,
    code: ``,
    qrcodeLogin: `//${host.global.passort}${mainhost}/center/ajax/h5_qrcode`,
    qrcodeDownload: `//${host.global.h5}${mainhost}/h5/playGames/qrcode`,
    newExeDownload: `//${host.global.event}37games.com/exe/${window.gameInfo.ENNAME}`
  },
  mobage: {
    // mobage的特殊性，需要使用http
    pcHomePage: 'http://yahoo-mbga.jp',
    mobileHomePage: 'http://sp.mbga.jp/_t?_from=globalfooter',
    pcSettingPage: 'http://yahoo-mbga.jp/config/mygame',
    pcSettingSandboxPage: 'http://sb.yahoo-mbga.jp/config/mygame',
    pcLawPage: clientId => {
      return `http://yahoo-mbga.jp/game/${clientId}/detail?third`
    },
    pcLawSandboxPage: clientId => {
      return `http://sb.yahoo-mbga.jp/game/${clientId}/detail?third`
    },
    pcUserCenterPage: clientId => {
      return `http://yahoo-mbga.jp/game/${clientId}/detail?fourth`
    },
    pcUserCenterSandboxPage: clientId => {
      return `http://sb.yahoo-mbga.jp/game/${clientId}/detail?fourth`
    }
  }
}
