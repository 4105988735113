<template>
  <div
    class="fb-canvas-login-popup"
    :class="`fb-canvas-login-popup-${this.platform}`"
  >
    <p class="tip">{{ letter.fb_canvas_root_tip }}</p>
    <a class="go" @click="excFbCanvasLogin">{{ letter.login }}</a>
  </div>
</template>

<script>
import CanvasLogin from '../../mixins/CanvasLogin';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['letter', 'platform']),
  },

  mixins: [CanvasLogin],
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.fb-canvas-login-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400vh;
  height: 241vh;
  margin-top: -120vh;
  margin-left: -200vh;
  z-index: 10;
  padding-top: 50vh;
  border-radius: 20vh;
  box-sizing: border-box;
  text-align: center;
  background: white;
  .go {
    display: block;
    margin: 40vh auto auto;
    width: 160vh;
    height: 40vh;
    line-height: 40vh;
    border-radius: 200px;
    color: white;
    font-size: 18vh;
    &:hover {
      filter: brightness(1.05);
      cursor: pointer;
    }
  }
}
.fb-canvas-login-popup-global {
  border: 2px solid #cbd4da;

  .go {
    background: $blue;
  }
}
.fb-canvas-login-popup-gm99 {
  border: 2px solid $orange;

  .go {
    background: $orange;
  }
}
</style>
