import jsonp from 'jsonp'
import axios from 'axios'
import globalConfig from '../utiles/globalConfig'
import languages from './languages'
import { convertParamToString } from '../utiles'
import { clearDisable } from '../plugins/clickThrottling'

export const DEFAULT_LANGUAGE = 'en-US'
import qs from 'qs'
const SUCCESS_RESULT_CODE = 1

export const isEmptyObj = function (obj) {
  return !Object.keys(obj).length
}

export let post = function (action, params, config = {}) {
  languages.init()

  return new Promise((resolve, reject) => {
    axios
      .post(action, qs.stringify(params), config)
      .then(function (response) {
        clearDisable()
        let res = response.data
        if (SUCCESS_RESULT_CODE != res.result) {
          let errmsg =
            (res && res.msg && res.msg.replace(/\[\-\d*\]/, '')) || ''
          errmsg = languages.get(res.code) || errmsg
          resolve({
            errmsg: errmsg,
            data: [],
            res: response
          })
        } else {
          resolve({
            errmsg: '',
            data: res.data,
            res: res
          })
        }
      })
      .catch(function (error) {
        globalConfig.log(error)
      })
  })
}

const appendPageParams = function (obj) {
  let linkParam = globalConfig.getHrefParamObj()
  // 当参数名相同时，传入的参数会覆盖链接上的参数
  // 添加统一默认参数
  return Object.assign(
    {
      packageName: window.pageInfo.packageName
    },
    linkParam,
    obj
  )
}

export let getJSON = function (action, params) {
  params = appendPageParams(params)
  return getJSONP(action, params)
}

export let getJSONWithoutEntryParam = function (action, params) {
  languages.init()

  if ('undefined' != typeof window) {
    return getJSONP(action, params)
  } else {
    return get(action, params)
  }
}

export let get = function (action, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(action, {
        params
      })
      .then(function (response) {
        clearDisable()
        let res = response.data
        if (SUCCESS_RESULT_CODE != res.result) {
          let errmsg =
            (res && res.msg && res.msg.replace(/\[\-\d*\]/, '')) || ''
          errmsg = languages.get(res.code) || errmsg
          resolve({
            errmsg: errmsg,
            data: [],
            res: response
          })
        } else {
          resolve({
            errmsg: '',
            data: res.data,
            res: res
          })
        }
      })
      .catch(function (error) {
        globalConfig.log(error)
      })
  })
}

let getJSONP = function (action, params) {
  return new Promise((resolve, reject) => {
    let paramStr = convertParamToString(params || {})
    jsonp(
      action,
      {
        prefix: 'jsonpCallback',
        param: paramStr
      },
      (err, response) => {
        clearDisable()
        if (err || SUCCESS_RESULT_CODE != response.result) {
          let errmsg =
            response && response.msg && response.msg.replace(/\[\-\d*\]/, '')
          errmsg =
            response && response.code !== undefined
              ? languages.get(response.code) || errmsg
              : errmsg
          resolve({
            errmsg: errmsg,
            data: [],
            res: response
          })
        } else {
          resolve({
            errmsg: '',
            data: response.data,
            res: response
          })
        }
      }
    )
  })
}

export let getHrefParam = function (paramName) {
  return globalConfig.getHrefParam(paramName)
}

/*
  去除字符串前后的空格
 */
export let trim = function (str) {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

export const loadConverstData = function (loadData, map) {
  let result = {}
  for (let key in map) {
    let mapKey = map[key]
    let val = loadData[mapKey] || ''
    result[key] = val
  }
  return result
}

export let getGameId = function () {
  return getHrefParam('game_id') || getHrefParam('gameid')
}

export const formatTextAreaVal = function (inputVal) {
  //匹配换行符==> <br/>，空白字符==>&nbsp;
  return inputVal.replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')
}

let formatTimeBit = function (time) {
  if (time < 10) {
    time = '0' + time
  }
  return time
}

//得到标准时区的时间的函数
export let getLocalTime = function (i, timestamp) {
  //参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
  if (typeof i !== 'number') return
  let d = new Date()
  //得到1970年一月一日到现在的秒数
  let len = d.getTime(timestamp)
  //本地时间与GMT时间的时间偏移差
  let offset = d.getTimezoneOffset() * 60000
  //得到现在的格林尼治时间
  let utcTime = len + offset
  return new Date(utcTime + 3600000 * i)
}
export let mapDateTime = function (time) {
  let date = getLocalTime(9, time)
  return (
    date.getFullYear() +
    '-' +
    formatTimeBit(date.getMonth() + 1) +
    '-' +
    formatTimeBit(date.getDate())
  )
}
