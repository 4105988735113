let interstitialSlot = null

export const createPopupAds = () => {
  googletag.cmd.push(() => {
    interstitialSlot = googletag.defineOutOfPageSlot(
      `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/popup`,
      googletag.enums.OutOfPageFormat.INTERSTITIAL
    )

    // Slot returns null if the page or device does not support interstitials.
    if (interstitialSlot) {
      // Enable optional interstitial triggers and register the slot.
      interstitialSlot.addService(googletag.pubads()).setConfig({
        interstitial: {
          triggers: {
            navBar: true,
            unhideWindow: true
          }
        }
      })
    }
  })
}

export const displayPopupAds = () => {
  googletag.cmd.push(() => {
    // Ensure the first call to display comes after static ad slot
    // divs are defined. If you do not have any static ad slots, this
    // call can be made immediately after services are enabled.
    googletag.display(interstitialSlot)
  })
}
