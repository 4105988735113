import { getJSON } from './ultile'
import ajaxUrl from '../config/ajax'
import globalConfig from '../utiles/globalConfig'

const getCurParam = name => {
  let linkValue = globalConfig.getHrefParam(name)
  // 1、微端先获取真正的gclid，然后带链接上给h5
  // 2、投h5广告的时候，gclid直接在链接上了
  // 3、微端小包gclid是md5值,在请求接口的时候判断一下长度是不是32位，是的话默认就是md5，需要重新获取真正的gclid
  // if (name == 'gclid') {
  //   linkValue = linkValue.substring(0, 32)
  // }
  if (linkValue) {
    globalConfig.setCookie(name, linkValue, 90 * 24 * 60 * 60)
    return linkValue
  }
  let res = ''
  try {
    res = globalConfig.getCookie(name)
    if (!res) {
      res = 0
    }
  } catch (error) {}
  return res
}

const getExtraParams = () => {
  let fbc = globalConfig.getCookie('_fbc')
  if (!fbc) {
    let fbclid = globalConfig.getHrefParam('fbclid')
    fbc = fbclid ? `fb.1.${new Date().getTime()}.${fbclid}` : ''
    globalConfig.setCookie(`_fbc`, fbc, 30)
  }
  let fbp = globalConfig.getCookie('_fbp') || ''
  if (fbp == 'null') {
    fbp = ''
  }
  return {
    fbc,
    fbp
  }
}

const extraParams = getExtraParams()

export default {
  state: {
    gclid: getCurParam('gclid'),
    cid: getCurParam('cid'),
    scid: getCurParam('scid'),
    fbc: getCurParam('fbc') || extraParams.fbc,
    fbp: getCurParam('fbp') || extraParams.fbp,
    adid: getCurParam('adid'),
    adname: getCurParam('adname'),
    camid: getCurParam('camid'),
    camname: getCurParam('camname'),
    adsetid: getCurParam('adsetid'),
    adsetname: getCurParam('adsetname')
  },
  getters: {
    gclid: state => state.gclid,
    cid: state => state.cid,
    scid: state => state.scid,
    fbc: state => state.fbc,
    fbp: state => state.fbp,
    adid: state => state.adid,
    adname: state => state.adname,
    camid: state => state.camid,
    camname: state => state.camname,
    adsetid: state => state.adsetid,
    adsetname: state => state.adsetname
  },
  mutations: {
    updateGclid(state, value) {
      state.gclid = value
    },
    updateCid(state, value) {
      state.cid = value
    },
    updateScid(state, value) {
      state.scid = value
    },
    updateFbc(state, value) {
      state.fbc = value
    },
    updateFbp(state, value) {
      state.fbp = value
    },
    updateAdid(state, value) {
      state.adid = value
    },
    updateAdname(state, value) {
      state.adname = value
    },
    updateCamid(state, value) {
      state.camid = value
    },
    updateCamname(state, value) {
      state.camname = value
    },
    updateAdsetid(state, value) {
      state.adsetid = value
    },
    updateAdsetname(state, value) {
      state.adsetname = value
    }
  },

  actions: {
    getCurGclid({ commit }) {
      return new Promise(resolve => {
        let urlGclid = getCurParam('gclid')
        if (!urlGclid || urlGclid.length !== 32) return Promise.resolve()
        getJSON(ajaxUrl[window.pageInfo['platform']].other.getCurGclid, {
          key: urlGclid
        }).then(res => {
          let gclid,
            cid,
            scid,
            fbc,
            fbp,
            adid,
            adname,
            camid,
            camname,
            adsetid,
            adsetname
          if (res.res.result == 1) {
            if (res.data.GCLID) {
              gclid = res.data.GCLID
              cid = res.data.CID
              scid = res.data.SCID
              fbc = res.data.FBC
              fbp = res.data.FBP
              adid = res.data.AD_ID
              adname = res.data.AD_NAME
              camid = res.data.CAM_ID
              camname = res.data.CAM_NAME
              adsetid = res.data.AD_SET_ID
              adsetname = res.data.AD_SET_NAME
              commit('updateGclid', gclid)
              commit('updateCid', cid)
              commit('updateScid', scid)
              commit('updateFbc', fbc)
              commit('updateFbp', fbp)
              commit('updateAdid', adid)
              commit('updateAdname', adname)
              commit('updateCamid', camid)
              commit('updateCamname', camname)
              commit('updateAdsetid', adsetid)
              commit('updateAdsetname', adsetname)
              globalConfig.setCookie('gclid', gclid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('cid', cid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('scid', scid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('fbc', fbc, 90 * 24 * 60 * 60)
              globalConfig.setCookie('fbp', fbp, 90 * 24 * 60 * 60)
              globalConfig.setCookie('adid', adid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('adname', adname, 90 * 24 * 60 * 60)
              globalConfig.setCookie('camid', camid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('camname', camname, 90 * 24 * 60 * 60)
              globalConfig.setCookie('adsetid', adsetid, 90 * 24 * 60 * 60)
              globalConfig.setCookie('adsetname', adsetname, 90 * 24 * 60 * 60)
            } else {
              commit('updateGclid', '')
            }
          }
          resolve(
            gclid,
            cid,
            scid,
            fbc,
            fbp,
            adid,
            adname,
            camid,
            camname,
            adsetid,
            adsetname
          )
        })
      })
    },
    downloadUpload({ state }, { md5gclid, uploadParam }) {
      if (!state.cid && !state.scid && !state.gclid) return
      getJSON(ajaxUrl[window.pageInfo['platform']].other.addAdparams, {
        key: md5gclid,
        adParams: window.btoa(uploadParam)
      })
    }
  }
}
