import globalConfig from '../utiles/globalConfig'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      tpLink: '',
      tpShow: false,
      tpInitInfo: ''
    }
  },
  computed: {
    ...mapGetters([
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'lang',
      'userName',
      'userId',
      'roleLevel',
      'letter',
      'gclid',
      'cid',
      'scid',
      'fbc',
      'fbp',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname'
    ]),
    pcIsHorizontal() {
      return (
        !window.isPhone &&
        window.pageInfo.tp &&
        !!window.pageInfo.tp.pcIsHorizontal
      )
    },
    mobileIsLandscape() {
      return (
        window.isPhone &&
        window.pageInfo.tp &&
        !!window.pageInfo.tp.mobileIsLandscape
      )
    }
  },
  methods: {
    loadTp(tpData) {
      this.tpInitInfo = tpData
      this.tpLink = this.addParamLink(tpData.popupLink)
    },
    hideTp() {
      this.tpShow = false
      this.tpLink = ''
    },
    showTp() {
      this.tpShow = true
    },
    addParamLink(url) {
      if (!url) {
        return ''
      }
      return globalConfig.hrefAddParams(url, {
        gameId: this.gameId,
        serverId: this.serverId,
        uid: this.userId,
        userId: this.userId,
        roleName: this.roleName,
        roleId: this.roleId,
        loginAccount: this.userName,
        userName: this.userName,
        language: this.lang,
        appLanguage: this.lang,
        roleLevel: this.roleLevel,
        userMode: window.pageInfo['userMode'],
        isWebTp: 1,
        hideBg: this.pcIsHorizontal || this.mobileIsLandscape ? 1 : 0
      })
    }
  }
}
