import globalConfig from '../utiles/globalConfig';
import url from '../config/url';

export default {
  data() {
    return {
      IS_TEST_ENV: globalConfig.getIsTest(),
      IS_MOBAGE_PC: !window.isPhone,
      homeUrl: window.isPhone
        ? url.mobage.mobileHomePage
        : url.mobage.pcHomePage,
      clientId:
        window.pageInfo.thirdPlatformConfig.mobage &&
        window.pageInfo.thirdPlatformConfig.mobage.clientId,
    };
  },
  methods: {
    mobageOpenHome() {
      window.open(this.homeUrl, '_blank');
    },
    mobageOpenSetting() {
      if (this.IS_MOBAGE_PC) {
        let openUrl = this.IS_TEST_ENV
          ? url.mobage.pcSettingSandboxPage
          : url.mobage.pcSettingPage;
        window.open(openUrl, '_blank');
        return;
      }
      mobage.ui.open(
        'client_configuration',
        {
          requiredMessage: 1,
          requiredMygame: 1,
        },
        function (error, result) {
          globalConfig.log(error, result);
        }
      );
    },
    mobageOpenLaw() {
      if (this.IS_MOBAGE_PC) {
        let openUrl = this.IS_TEST_ENV
          ? url.mobage.pcLawSandboxPage(this.clientId)
          : url.mobage.pcLawPage(this.clientId);
        window.open(openUrl, '_blank');
        return;
      }
      mobage.ui.show('commercial_transaction');
    },
    mobageOpenUserCenter() {
      if (this.IS_MOBAGE_PC) {
        let openUrl = this.IS_TEST_ENV
          ? url.mobage.pcUserCenterSandboxPage(this.clientId)
          : url.mobage.pcUserCenterPage(this.clientId);
        window.open(openUrl, '_blank');
        return;
      }
      mobage.ui.show('inquiry');
    },
  },
};
