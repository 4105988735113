<template>
  <div class="tips-popup">
    <a class="close" @click="close"></a>
    <p>{{ commonTipsContent }}</p>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['commonTipsContent']),
  },
  methods: {
    close() {
      this.$store.commit('updateCommonTips', {});
    },
  },
};
</script>

<style lang="scss" scoped>
$img: '../../assets/image/';
.tips-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  background: url($img + 'fb-pay-popup.png') no-repeat top center;
  background-size: contain;
  margin-top: -121vh;
  margin-left: -228vh;
  z-index: 101;
  padding: 36vh 0 40vh;
  box-sizing: border-box;
  width: 456vh;
  height: 243vh;
  p {
    line-height: 150%;
    padding: 0 20vh;
    font-size: 20vh;
    text-align: center;
  }
  .close {
    background: url($img + 'cancle.png') no-repeat top center;
    background-size: contain;
    width: 54vh;
    height: 54vh;
    position: absolute;
    top: -27vh;
    right: -27vh;
  }
}
</style>
