const REWARD_ADS_CODE = {
  1: '广告观看完毕',
  '-1': '广告加载失败',
  '-2': '中途关闭广告',
  '-100': '其他错误'
}

let bindId = ''

const _postmessage = data => {
  document.getElementById('game-iframe').contentWindow.postMessage(data, '*')
  bindId = ''
}

export const sendRewardeAdsCallback = (res, code) => {
  _postmessage({
    action: 'showRewardedAds',
    param: {
      res,
      msg: REWARD_ADS_CODE[code] || REWARD_ADS_CODE['-100'],
      code
    },
    bindId
  })
}

export const updateBindId = value => {
  bindId = value
}

export const sendCanShowRewardAdsCallback = res => {
  _postmessage({
    action: 'canShowRewardedAds',
    param: res,
    bindId
  })
}

export const hasBindId = () => bindId != ''
