import globalConfig from '../../../utiles/globalConfig'
const TP_CACHE_KEY_PREFIX = `tp_${window.gameId}_`
const TP_CACHE_KEY = {
  LAST_SHOW_TIME: 'lastShowTime',
  MAX_TIMES_IN_DAY: 'maxTimesInDay',
  HIDE_WITHIN_DAYS: 'hideWithinDays',
  HAS_OVER_ONE_DAY: 'hasOverOneDay'
}

export const storeMaxTimesInDay = times => {
  try {
    globalConfig.setLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.MAX_TIMES_IN_DAY}`,
      times
    )
  } catch (e) {
    console.log('tca:设置maxTimesInDay localStorage失败')
  }
}

export const storeLastShowTime = time => {
  try {
    globalConfig.setLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.LAST_SHOW_TIME}`,
      time || new Date()
    )
  } catch (e) {
    console.log('tca:设置lastShowTime localStorage失败')
  }
}

export const storeHideWithinDays = days => {
  try {
    globalConfig.setLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.HIDE_WITHIN_DAYS}`,
      days
    )
  } catch (e) {
    console.log('tca:设置hideWithinDays localStorage失败')
  }
}

export const storeHasOverOneDay = has => {
  try {
    globalConfig.setLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.HAS_OVER_ONE_DAY}`,
      has
    )
  } catch (e) {
    console.log('tca:设置HasOverOneDay localStorage失败')
  }
}

export const getMaxTimesInDay = () => {
  let hasShowTime = 0
  try {
    hasShowTime = +globalConfig.getLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.MAX_TIMES_IN_DAY}`
    )
  } catch (e) {}
  return hasShowTime
}

export const getLastShowTime = () => {
  let lastShowTime = ''
  try {
    lastShowTime = globalConfig.getLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.LAST_SHOW_TIME}`
    )
  } catch (e) {}
  return lastShowTime
}

export const getHideWithinDays = () => {
  let hideWithinDays = 0
  try {
    hideWithinDays = +globalConfig.getLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.HIDE_WITHIN_DAYS}`
    )
  } catch (e) {}
  return hideWithinDays
}

export const getHasOverOneDay = () => {
  let hasOverOneDay = 0
  try {
    hasOverOneDay = +globalConfig.getLclStorageItem(
      `${TP_CACHE_KEY_PREFIX}${TP_CACHE_KEY.HAS_OVER_ONE_DAY}`
    )
  } catch (e) {}
  return hasOverOneDay
}
