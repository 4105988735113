<template>
  <div class="verified-popup" @click="popupShow = true" v-if="bg && btn">
    <em class="mask" v-show="popupShow"></em>
    <div class="content-box" v-show="popupShow">
      <img :src="bg" :style="bgCss" class="" alt="" />
      <div class="content">
        <p :style="fontcolor">
          Căn cứ vào pháp quy bản địa, sau khi hoàn thiện thông tin cá nhân mới
          được vào game.Tất cả tài liệu chỉ dành cho xét duyệt, không bị dùng
          cho mục đích khác
        </p>
        <a class="sure" href="javascript:;" @click.stop="hidePopup">
          <img :src="btn" :style="btnCss" alt="" />
          <span :style="surecolor" class="sure-text">Đóng</span>
        </a>
        <a class="sure" href="javascript:;" @click.stop="openProfilePage">
          <img :src="btn" :style="btnCss" class="sure-bg" alt="" />
          <span :style="surecolor" class="sure-text">Hoàn thiện</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadConfigImg from '../mixins/LoadConfigImg'
import globalConfig from '../utiles/globalConfig'

export default {
  data() {
    return {
      popupInfo: window.pageInfo['popup'],
      bg: '',
      bgCss: '',
      close: '',
      closeCss: '',
      btn: '',
      btnCss: '',
      fontcolor: `color:${window.pageInfo['normalColor']}`,
      surecolor: `color:${window.pageInfo['dNormalColor']}`,
      popupShow: true,
      // profileShow: false,
      registProfileSrc: `//gabres.${globalConfig.getMainHost()}/platform/passport.html?appLanguage=vi-VN#/registProfile`
    }
  },
  mixins: [LoadConfigImg],
  computed: {
    ...mapGetters(['letter'])
  },

  mounted() {
    if (this.popupInfo) {
      if (this.popupInfo.popupBg) {
        this.loadImg(this.popupInfo.popupBg, 'bg', true, true)
      }
      if (this.popupInfo.popupClose) {
        this.loadImg(this.popupInfo.popupClose, 'close', true, true)
      }
      if (this.popupInfo.popupBtn) {
        this.loadImg(this.popupInfo.popupBtn, 'btn', true, true)
      }
    }
  },
  methods: {
    hidePopup() {
      this.popupShow = false
    },
    openProfilePage() {
      window.open(this.registProfileSrc, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.verified-popup {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  p {
    text-align: left;
    width: 80%;
    margin: 70vh auto auto;
  }
}
.sure {
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin: 20vh 10vh auto;
  img {
    display: block;
  }

  &-text {
    position: absolute;
    font-size: 14vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
