const DEFAULT_V_WIDTH = 640;
const DEFAULT_H_WIDTH = 1400;

const SMALL_V_WIDTH = 540;

const CSS_BASIC_NUM = 1113;

export const getRealGameContentWidthPX = function (isHorizontal, widthSize) {
  if (isHorizontal) {
    return DEFAULT_H_WIDTH
  } else {
    return widthSize === 'small' ? SMALL_V_WIDTH : DEFAULT_V_WIDTH
  }
}

export const pxToVh = function (num) {
  return ((num * 100) / CSS_BASIC_NUM).toFixed(1);
};
