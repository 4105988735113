<template>
  <div class="common-tips" :style="bgCss" v-if="bg && close">
    <img :src="bg" :style="bgCss" alt="" />
    <a class="close" @click="closePopup" href="javascript:;">
      <img :src="close" :style="closeCss" alt="" />
    </a>
    <div class="content">
      <p :style="fontcolor">{{ commonTipsContent }}</p>
      <a
        class="sure"
        @click="closePopup"
        href="javascript:;"
        v-show="showSureBtn"
        v-if="btn"
      >
        <img :src="btn" :style="btnCss" class="sure-bg" alt="" />
        <span :style="surecolor" class="sure-text">
          {{ letter.mywork_confirm }}
        </span>
      </a>
      <a
        class="sure"
        @click="excShowGiftListPopup"
        href="javascript:;"
        v-show="showGiftListBtn"
        v-if="btn"
      >
        <img :src="btn" :style="btnCss" alt="" />
        <span :style="surecolor" class="sure-text">
          {{ letter.h5_check_gift_list_text }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import LoadConfigImg from '../../mixins/LoadConfigImg'
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      popupInfo: window.pageInfo['popup'],
      bg: '',
      bgCss: '',
      close: '',
      closeCss: '',
      btn: '',
      btnCss: '',
      fontcolor: `color:${window.pageInfo['normalColor']}`,
      surecolor: `color:${window.pageInfo['dNormalColor']}`
    }
  },
  watch: {
    commonTipsContent(newV, oldV) {
      if (newV && newV !== oldV) {
        // 如果没有弹窗背景图，就直接alert提示，然后关掉弹窗
        if (!this.popupInfo.popupBg) {
          this.closePopup()
          alert(newV)
        }
      }
    }
  },
  mixins: [LoadConfigImg],
  computed: {
    showSureBtn() {
      return this.commonTipsSure === ''
    },
    showGiftListBtn() {
      return this.commonTipsSure === 'giftList'
    },
    ...mapGetters(['commonTipsContent', 'commonTipsSure', 'lang', 'letter'])
  },
  mounted() {
    if (this.popupInfo) {
      if (this.popupInfo.popupBg) {
        this.loadImg(this.popupInfo.popupBg, 'bg', true, true)
      }
      if (this.popupInfo.popupClose) {
        this.loadImg(this.popupInfo.popupClose, 'close', true, true)
      }
      if (this.popupInfo.popupBtn) {
        this.loadImg(this.popupInfo.popupBtn, 'btn', true, true)
      }
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('updateCommonTips', {})
    },
    excShowGiftListPopup() {
      this.getGiftList()
      this.closePopup()
      this.$store.commit('toggleGiftListPopup', true)
    },
    ...mapActions(['getGiftList'])
  }
}
</script>

<style lang="scss" scoped>
.common-tips {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
      cursor: pointer;
    }
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 22vh;
    text-align: center;
    p {
      padding: 0 20vh;
      line-height: 150%;
      word-break: break-word;
    }
  }
  .sure {
    display: block;
    margin: auto;
    position: relative;
    font-size: 0;
    &:hover {
      cursor: pointer;
    }
  }
  .sure-text {
    font-size: 22vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
  }
}
</style>
