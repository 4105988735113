<template>
  <div class="store-popup" :class="`store-popup-${this.platform}`">
    <em class="close" @click="closeStorePopup"></em>
    <p class="tip">{{ letter.goStoreTip }}</p>
    <a href="javascript:;" class="go j-goto-pay" @click="gotoPay">
      {{ letter.go }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import globalConfig from '../../utiles/globalConfig'
import url from '../../config/url'
import Exe from '../../mixins/Exe'
export default {
  name: 'storePopupCommon',
  data() {
    return {}
  },
  mixins: [Exe],
  computed: {
    storePageSrc() {
      // 2024.1.9 王室传奇H5特殊处理跳转新版代币支付页:这个游戏只能买代币，没有适配买商品的旧版第三方
      // 2024.5.17 大千全球版H5跳转新版代币支付页
      // 2024.6.26 霸业2.0台港澳版H5跳转新版代币支付页
      // 2024.8.16 越南大千H5跳转新版代币支付页
      const newStoreGameArr = [310, 339, 379, 349]
      return globalConfig.hrefAddParams(
        newStoreGameArr.includes(+this.gameId)
          ? url[this.platform].newStoreGame
          : url[this.platform].storeGame,
        {
          gameId: this.gameId,
          serverId: this.serverId,
          roleId: this.roleId,
          roleName: encodeURIComponent(this.roleName),
          language: this.lang,
          appLanguage: this.lang,
          userId: this.userId,
          loginAccount: this.userName,

          productId: this.sdkParams.productId,
          remark: (() => {
            return this.gameId > 183
              ? encodeURIComponent(this.sdkParams.remark || '')
              : encodeURIComponent(
                  this.sdkParams.remark || this.sdkParams.order_no || ''
                )
          })(),

          devicePlate: window.isPhone ? 'wap' : 'pc',
          packageName:
            globalConfig.getHrefParam('packageName') ||
            window.pageInfo.packageName,
          cpProductId: this.sdkParams.cpProductId || '',
          location: window.pageInfo['location'] || '',
          web: 1,
          pageSource: 'h5',
          pid: this.sdkParams.pid
        }
      )
    },
    ...mapGetters([
      'letter',
      'gameId',
      'platform',
      'lang',
      'serverId',
      'roleId',
      'roleName',
      'sdkParams',
      'userName',
      'userId'
    ])
  },
  methods: {
    closeStorePopup() {
      this.$store.commit('updateStorePopupState', false)
    },
    gotoPay() {
      if (this.isElectronExe) {
        this.exeOpenNewWindow(this.dirHref(`https:${this.storePageSrc}`))
      } else {
        window.open(this.storePageSrc, '_blank')
      }

      globalConfig.gaStatistics('exc_store_pay', {
        pid: this.sdkParams.productId,
        pay_type: 'store_page',
        ...this.sdkParams
      })

      this.closeStorePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.store-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 7;
  .close {
    position: absolute;
    cursor: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .tip {
    text-align: center;
    color: #666;
    font-size: 24vh;
    padding: 0 20vh;
  }
  .go {
    display: block;

    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 24vh;
  }
}
.store-popup-global {
  width: 480vh;
  height: 340vh;
  margin-top: -170vh;
  margin-left: -240vh;
  background: url($img + 'tips-bg.png') no-repeat center center;
  background-size: contain;
  .tip {
    margin-top: 100vh;
  }
  .close {
    top: -27vh;
    right: -27vh;
    background: url($img + 'cancle.png') no-repeat center center;
    background-size: contain;
    width: 54vh;
    height: 54vh;
  }
  .go {
    background: url($img + 'sure-btn.png') no-repeat center center;
    background-size: contain;
    width: 289vh;
    height: 62vh;
    line-height: 60vh;
    margin: 60vh auto auto;
  }
}
.store-popup-gm99 {
  background: white;
  border: 2px solid $orange;
  width: 380vh;
  height: 240vh;
  margin-top: -120vh;
  margin-left: -190vh;
  border-radius: 20vh;
  .tip {
    margin: 70vh auto auto;
  }
  .close {
    background: url($img + 'x.png') no-repeat top center;
    background-size: contain;
    width: 57vh;
    height: 57vh;
    right: -26vh;
    top: -26vh;
  }
  .go {
    width: 200vh;
    height: 50vh;
    line-height: 50vh;
    background: $orange;
    border-radius: 50vh;
    margin: 20vh auto auto;
  }
}
</style>
