import url from '../config/url';
import globalConfig from '../utiles/globalConfig';
import { mapGetters } from 'vuex';

// 二维码每隔5分钟自动刷新一次
// 点击刷新

export default {
  computed: {
    qrcodeSrc() {
      if (window.pageInfo['pcCodeImg']) {
        return window.pageInfo['pcCodeImg'] || '';
      }
      // 后端需求：原接口直接返回图片，就需要服务端请求服务端读取图片，多了一步消耗，改为smarty返回数据
      if (window.pageInfo['needDownload']) {
        return window.gameInfo['QR_CODE'] || '';
      }
      if (this.gameId && this.qrCodeVersion) {
        return globalConfig.hrefAddParams(url[this.platform].qrcodeLogin, {
          game_id: this.gameId,
          v: this.qrCodeVersion,
        });
      }
      return '';
    },
    ...mapGetters(['letter', 'isLogin', 'gameId', 'platform', 'qrCodeVersion']),
  },
  watch: {
    isLogin(newValue, oldVal) {
      if (newValue !== oldVal) {
        this.$store.commit('updateQrCodeVersion');
      }
    },
  },
};
