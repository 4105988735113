<template>
  <div
    class="page"
    :class="{
      horizontal: isHorizontal,
      bigsize: isFull,
      autoheight: isThirdPlatform
    }"
  >
    <Bg v-if="!isThirdPlatform"></Bg>
    <HeaderCommon v-if="!isThirdPlatform">
      <template slot="account">
        <HeaderAccountGlobal v-if="isGlobal"></HeaderAccountGlobal>
        <HeaderAccountGm99 v-if="isGm99"></HeaderAccountGm99>
      </template>
    </HeaderCommon>
    <GameIframe v-if="!isThirdPlatform"></GameIframe>
    <div :id="ADS_ASIDE_LEFT_ID" :class="ADS_ASIDE_LEFT_ID"></div>
    <div :id="ADS_ASIDE_RIGHT_ID" :class="ADS_ASIDE_RIGHT_ID"></div>
    <MobageIframe v-if="thirdPlatformType === 'mobage'"></MobageIframe>
    <a
      v-if="!isThirdPlatform && !!payBtnImg"
      :href="storeUrl"
      target="_blank"
      class="pay-btn j-page-pay-btn"
      :style="payBtnStyle"
    >
      <img :src="payBtnImg" alt="" />
    </a>
    <Qrcode v-if="!isThirdPlatform && isCode"></Qrcode>
    <AwardFloat
      v-if="!isThirdPlatform && floatBoxShow && !isExe"
      v-show="awardFloatState"
      @closeAwardFloat="closeAwardFloat"
    ></AwardFloat>

    <img
      class="right-float-img"
      :src="item.img"
      :style="getStyle(item)"
      v-for="(item, index) in computedRightFloat"
      :key="`float-${index}`"
      alt=""
    />
    <template v-if="!isThirdPlatform && isFooter">
      <FooterSlfuntapVn v-if="footerState == 'slfuntapVn'"></FooterSlfuntapVn>
      <FooterCommonGlobal
        v-else-if="(isGlobal && !footerState) || footerState == 'commonGlobal'"
      ></FooterCommonGlobal>
      <FooterCommonGm99
        v-else-if="(isGm99 && !footerState) || footerState == 'commonGm99'"
      ></FooterCommonGm99>
    </template>
    <AgePopup v-if="!isThirdPlatform && agePopupState"></AgePopup>
    <VerifiedPopup v-if="verifiedPopupState"></VerifiedPopup>
  </div>
</template>

<script>
import HeaderCommon from '../components/commission/HeaderCommon'
import HeaderAccountGlobal from '../components/commission/HeaderAccountGlobal'
import HeaderAccountGm99 from '../components/commission/HeaderAccountGm99'
import Qrcode from '../components/Qrcode'
import AwardFloat from '../components/AwardFloat'
import Download from '../mixins/Download.js'
import GameIframe from '../components/GameIframe'
import MobageIframe from '../components/MobageIframe'
import FooterCommonGlobal from '../components/commission/FooterCommonGlobal'
import FooterSlfuntapVn from '../components/commission/FooterSlfuntapVn'
import FooterCommonGm99 from '../components/commission/FooterCommonGm99'
import Bg from '../components/Bg'
import AgePopup from '../components/AgePopup'
import StoreUrl from '../mixins/StoreUrl'
import globalConfig from '../utiles/globalConfig'
import Platform from '../mixins/Platform'
import { pxToVh, getRealGameContentWidthPX } from '../utiles/gameWidth'
import { mapGetters, mapActions } from 'vuex'
import VerifiedPopup from '../components/VerifiedPopup'
import Exe from '../mixins/Exe'
import { ADS_ASIDE_LEFT_ID, ADS_ASIDE_RIGHT_ID } from '../ads/gam/aside'
export default {
  name: 'h5pc',
  data() {
    return {
      payBtnImg: window.pageInfo['pcPay'] || '',
      awardFloatUserState: true,
      isCode: window.pageInfo['pcCode'] === 'page',
      rightFloat: window.pageInfo['rightFloatList'],
      isFooter: window.pageInfo['footer'],
      footerState:
        window.pageInfo['footerType'][window.h5ip] ||
        window.pageInfo['footerType'][this.lang] ||
        window.pageInfo['footerType'].default ||
        '',
      ADS_ASIDE_LEFT_ID,
      ADS_ASIDE_RIGHT_ID
    }
  },
  mixins: [Download, StoreUrl, Platform, Exe],
  components: {
    GameIframe,
    MobageIframe,
    HeaderCommon,
    Qrcode,
    AwardFloat,
    FooterCommonGlobal,
    FooterSlfuntapVn,
    FooterCommonGm99,
    AgePopup,
    HeaderAccountGlobal,
    HeaderAccountGm99,
    Bg,
    VerifiedPopup
  },
  computed: {
    isFull() {
      return this.isHorizontal && this.widthSize === 'big'
    },
    computedRightFloat() {
      return this.isThirdPlatform ? [] : this.rightFloat
    },
    floatBoxShow() {
      return window.pageInfo['awardFloatBox'].info[this.lang]
    },
    payBtnStyle() {
      let realL =
        getRealGameContentWidthPX(this.isHorizontal, this.widthSize) / 2 + 9
      return `margin-left:${pxToVh(realL)}vh`
    },
    awardFloatState() {
      if (window.pageInfo['awardFloatBox'].login) {
        if (window.pageInfo['awardFloatBox'].can) {
          return (
            this.isLogin &&
            (!this.favourHasGift || !this.downloadHasGift) &&
            this.awardFloatUserState
          )
        } else {
          return this.isLogin && this.awardFloatUserState
        }
      } else {
        return this.awardFloatUserState
      }
    },
    ...mapGetters([
      'widthSize',
      'isHorizontal',
      'thirdPlatformType',
      'isLogin',
      'gameId',
      'lang',
      'serverId',
      'roleId',
      'roleName',
      'favourSelecePopupState',
      'isShowDownAwardsWindow',
      'isShowDownWayWindow',
      'isShowDownLoginWindow',
      'isShowGetDownloadAwards',
      'agePopupState',
      'favourHasGift',
      'downloadHasGift',
      'verifiedPopupState',
      'gclid',
      'cid',
      'scid'
    ])
  },

  mounted() {
    // pc端二维码更新：5分钟刷一次
    // 仅在二维码是登录二维码的情况下才执行刷新
    if (!window.pageInfo['pcCodeImg'] && !window.pageInfo['needDownload']) {
      setInterval(() => {
        this.$store.commit('updateQrCodeVersion')
      }, 1000 * 60 * 5)
    }
    if (this.isElectronExe) {
      window.alert = message => {
        this.exeSendToIPC('openAlert', message)
      }
      this.exeOnIPC('reloadHtmlPage', () => {
        window.location.reload()
      })
    }
  },
  created() {
    this.getCurGclid().then(() => {
      globalConfig.ajaxStatistics({
        eventName: 'launch',
        eventType: 'custom_loss',
        cid: this.cid,
        gclid: this.gclid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname
      })
    })
  },
  methods: {
    ...mapActions(['getCurGclid']),
    closeAwardFloat() {
      this.awardFloatUserState = false
    },
    getStyle(item) {
      return `height:${item.height}%;right:${pxToVh(item.right)}vh;top:${pxToVh(
        item.top
      )}vh`
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/h5.scss';

.page {
  background-color: black;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  font-family: 'SourceHanSansCN-Light';
  &.autoheight {
    height: auto;
  }
}

.game-content {
  height: calc(960vh - 3px);
  margin: 76vh auto 0;
  display: block;
  position: relative;
  z-index: 2;
  &.no-margin {
    margin: 0 auto;
  }
}

.right-float-img {
  position: absolute;
  z-index: 2;
}

.bigsize {
  .game-content {
    height: 1032vh;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.pay-btn {
  width: 276vh;
  height: 132vh;
  position: absolute;
  top: 610vh;
  left: 50%;
  z-index: 1;
  text-align: center;
  img {
    width: 100%;
  }
}

.ads-aside-left,
.ads-aside-right {
  position: absolute;
  top: $headerHeight;
  z-index: 5;
}

.ads-aside-left {
  left: 0;
}

.ads-aside-right {
  right: 0;
}
</style>
