import { getJSON } from './ultile'
import globalConfig from '../utiles/globalConfig'
import host from '../config/host'
import ajaxUrl from '../config/ajax'
let mainHost = globalConfig.getMainHost()
const EVENTS_HOST = `//${host.global.eventapi}${mainHost}/`

export default {
  state: {
    // 收藏獎勵是否已領
    favourHasGift: false,
    // 下載獎勵是否已領
    downloadHasGift: false,
    giftListPopupState: false,
    giftNumber: '',
    giftList: []
  },

  getters: {
    favourHasGift: state => state.favourHasGift,
    downloadHasGift: state => state.downloadHasGift,
    giftList: state => state.giftList,
    giftListPopupState: state => state.giftListPopupState,
    giftNumber: state => state.giftNumber
  },
  mutations: {
    getAwardState(state, data) {
      state.favourHasGift = data.like_award
        ? data.like_award.is_award
        : data.likeAward
      state.downloadHasGift = data.download_award
        ? data.download_award.is_award
        : data.downloadAward
    },
    updateGiftList(state, params) {
      state.giftList = params
    },
    toggleGiftListPopup(state, res) {
      state.giftListPopupState = res
    },
    updateGiftNumber(state, res) {
      state.giftNumber = res
    }
  },
  actions: {
    // 判断是否能领奖
    getAwardState({ commit, dispatch, rootState }) {
      // 以前的孽缘
      if (rootState.gameId == 161 && rootState.platform === 'global') {
        return Promise.all([
          dispatch('getAward161FavourState'),
          dispatch('getAward161DownloadState')
        ])
      }
      return new Promise(resolve => {
        // 判断领奖方式，来决定用什么接口什么参数
        let url =
          ajaxUrl[rootState.platform].gift[window.pageInfo['awardType']]
            .checkState
        let data = {
          params: {
            gameId: rootState.gameId
          },
          language: rootState.language.lang,
          appLanguage: rootState.language.lang
        }

        getJSON(url, data).then(res => {
          if (res.res.result == '1') {
            commit('getAwardState', res.data)
          }
          resolve()
        })
      })
    },
    // 兼容161游戏的收藏奖励状态查询接口，以后都不会用了
    getAward161FavourState({ commit }) {
      return new Promise(resolve => {
        getJSON(
          `${EVENTS_HOST}activity/execute?name=rlwjsclj&action=basic`,
          {}
        ).then(res => {
          if (res.res.result == '1') {
            commit('getAwardState', {
              likeAward: res.data.is_award
            })
          }
          resolve()
        })
      })
    },
    // 兼容161游戏的下载奖励状态查询接口，以后都不会用了
    getAward161DownloadState({ commit }) {
      return new Promise(resolve => {
        getJSON(
          `${EVENTS_HOST}activity/execute?name=rlwjwdlj&action=getBaseConfig`,
          {}
        ).then(res => {
          if (res.res.result == '1') {
            commit('getAwardState', {
              downloadAward: res.data.isAward
            })
          }
          resolve()
        })
      })
    },
    // 领收藏的奖
    getFavourGift({ rootState }) {
      return new Promise(resolve => {
        let ajax =
          ajaxUrl[rootState.platform].gift[window.pageInfo['awardType']]
            .getFavour
        let data = {
          sid: rootState.serverId,
          roleId: rootState.roleId,
          serverID: rootState.serverId,
          giftType: 'like',
          params: {
            sid: rootState.serverId,
            roleId: rootState.roleId,
            gameId: rootState.gameId,
            giftType: 'like'
          },
          language: rootState.language.lang,
          appLanguage: rootState.language.lang
        }
        // 兼容161旧接口
        if (rootState.gameId == '161' && rootState.platform === 'global') {
          ajax = `${EVENTS_HOST}activity/execute?name=rlwjsclj&action=award`
        }
        getJSON(ajax, data).then(res => {
          resolve(res.res)
        })
      })
    },
    // 领下载的奖
    getDownloadGift({ rootState }) {
      return new Promise(resolve => {
        // 一般发奖是游戏内发奖
        let ajax =
          ajaxUrl[rootState.platform].gift[window.pageInfo['awardType']]
            .getDownload
        let data = {
          sid: rootState.serverId,
          serverID: rootState.serverId,
          roleId: rootState.roleId,
          giftType: 'download',
          params: {
            sid: rootState.serverId,
            roleId: rootState.roleId,
            gameId: rootState.gameId,
            giftType: 'download'
          },
          language: rootState.language.lang,
          appLanguage: rootState.language.lang
        }
        // 兼容161旧接口
        if (rootState.gameId == '161' && rootState.platform === 'global') {
          ajax = `${EVENTS_HOST}activity/execute?name=rlwjwdlj&action=reward`
        }
        getJSON(ajax, data).then(res => {
          resolve(res.res)
        })
      })
    },
    getGiftList({ commit, rootState }) {
      return new Promise(resolve => {
        getJSON(ajaxUrl[rootState.platform].gift.number.checkState, {
          params: {
            gameId: rootState.gameId
          }
        }).then(res => {
          commit('updateGiftList', res.data)
          resolve()
        })
      })
    }
  }
}
