<template>
  <div>
    <em
      class="bg"
      :class="{
        hasFooterBg:
          isFooter && (!isHorizontal || (isHorizontal && widthSize == 'normal'))
      }"
      :style="decorateBg"
    ></em>
    <transition name="fade">
      <em
        class="bg"
        :style="smallBg"
        :class="{ hasFooterBg: isFooter }"
        v-show="!bgState"
        v-if="smallBg"
      ></em>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      decorateBg: '',
      smallBg: `background-image:url(${window.pageInfo['smallBg'] || ''})`,
      bgState: !window.pageInfo['smallBg'],
      isFooter: window.pageInfo['footer'],
      hasLoadOtherBg: false
    }
  },
  methods: {
    loadBg(bg, finishLoad) {
      if (this.lang == 'vi-VN' && window.pageInfo['specialVnBg']) {
        bg = window.pageInfo['specialVnBg'] || bg;
      }
      if (window.pageInfo['smallBg'] && !finishLoad) {
        let img = new Image();
        let self = this;
        img.onload = function () {
          self.decorateBg = `background-image:url(${ bg })`;
          setTimeout(() => {
            self.bgState = true;
          }, 100)
        }
        img.src = bg;
        self.bgState = img.complete;
      } else {
        this.decorateBg = `background-image:url(${ bg })`;
      }
    }
  },
  created() {
    this.loadBg(this.bg);
  },
  watch: {
    bg(newVal) {
      this.loadBg(newVal, this.hasLoadOtherBg);
      this.hasLoadOtherBg = true;
    }
  },
  computed: {
    ...mapGetters(['widthSize', 'isHorizontal', 'bg', 'lang'])
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 375vw;
  width: 2560vh;
  height: 1113vh;
  margin-left: -1280vh;
  z-index: 1;
}

.hasFooterBg.bg {
  width: calc(2560vh - 750vw + 100%);
}
</style>
