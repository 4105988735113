import ajaxUrl from '../config/ajax'
import { mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'
import md5 from 'md5'
export default {
  data() {
    let _isExe = globalConfig.getHrefParam('device') == 'exe'
    return {
      isExe: _isExe,
      isElectronExe: _isExe && !!window.ipcFn,
      machineId: (window.userConf && window.userConf.machineId) || ''
    }
  },
  computed: {
    ...mapGetters(['platform', 'exeToken', 'gameId', 'cid', 'scid'])
  },
  methods: {
    dirHref(href) {
      const date = new Date()
      const timestamp = date.getTime()
      const secretKey = '7djs$ss*^shdztmslg@37global.com'
      const sign = md5(this.exeToken + timestamp + secretKey)
      return `https:${ajaxUrl[this.platform].login.exeDirLogin}?token=${
        this.exeToken
      }&gameId=${this.gameId}&cid=${this.cid}&scid=${
        this.scid
      }&timestamp=${timestamp}&sign=${sign}&to=forward&forward=${encodeURIComponent(
        href
      )}`
    },
    exeSendToIPC(event, data) {
      try {
        window.ipcFn.send(event, data)
      } catch (error) {
        console.log(error)
      }
    },
    exeOnIPC(event, fn) {
      try {
        window.ipcFn.on(event, fn)
      } catch (error) {
        console.log(error)
      }
    },

    exeOpenNewWindow(href) {
      console.log(href)
      try {
        window.electronFn.openNewWindow(href)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
