import { isFn } from '../utiles/judgeType'
import Fingerprint2 from 'fingerprintjs2'
import globalConfig from '../utiles/globalConfig'
import config from '../config/index'
import href from '../config/url'

const mainHost = globalConfig.getMainHost()
const UUID = 'uuid' // 浏览器生成的指纹id
export const N_UUID = 'n_uuid' // 后端返回的指纹id

let fbState = null
let isOnloadCheck = false

export const fbSdkInit = function (appid, callback) {
  window.fbAsyncInit = function () {
    FB.init({
      appId: appid || window.pageInfo['appid'],
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.3',
      status: true
    })
    if (isFn(callback)) {
      callback()
    }
    fbSdkGetStatus().then(() => {
      isOnloadCheck = true
    })
  }
  ;(function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = `https://connect.facebook.net/en_US/sdk.js`
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

export const fbSdkGetStatus = function () {
  return new Promise(resolve => {
    // 2021-08-17 避免异步时间过长，采用页面加载即检测
    if (isOnloadCheck) {
      resolve(fbState)
    } else {
      FB.getLoginStatus(response => {
        fbState = response
        isOnloadCheck = true
        resolve(response)
        // 添加避免缓存参数
      }, true)
    }
  })
}

export const fbSdkGetUserInfo = function () {
  return new Promise(resolve => {
    FB.api('/me', response => {
      resolve({
        id: response.id,
        name: response.name,
        avatar: `https://graph.facebook.com/${response.id}/picture`
      })
    })
  })
}

export const fbSdkLogin = function () {
  return new Promise((resolve, reject) => {
    FB.login(response => {
      fbState = response
      isOnloadCheck = true
      if (response.status === 'connected') {
        resolve(response)
      } else {
        reject(response)
      }
    })
  })
}

export const fbSdkShare = function (href) {
  return new Promise((resolve, reject) => {
    FB.ui(
      {
        method: 'share',
        href: href,
        display: 'popup'
      },
      function (response) {
        if (!response.error_message) {
          resolve()
        } else {
          reject()
        }
      }
    )
  })
}

export const fbSdkPay = function (order_id) {
  return new Promise((resolve, reject) => {
    FB.ui(
      {
        method: 'pay',
        action: 'purchaseitem',
        product: `https:${href[window.pageInfo['platform']].fbPayPage}${
          window.appInfo.APP_ID
        }/order_id/${order_id}`,
        request_id: order_id
      },
      res => {
        if (res && !res['error_code']) {
          resolve(res)
        } else {
          reject(res)
        }
      }
    )
  })
}

export const googleSdkInit = function () {
  if (!config[window.pageInfo['platform']].login.googleId) {
    return
  }
  window.googleOnload = function () {
    gapi.load('auth2', function () {
      window.googleAuth = gapi.auth2.init({
        client_id: config[window.pageInfo['platform']].login.googleId,
        cookiepolicy: 'single_host_origin',
        scope: 'profile'
      })
    })
  }
  let js = document.getElementsByTagName('script')[0]
  let gjs = document.createElement('script')
  gjs.id = 'google-jssdk'
  gjs.src = 'https://apis.google.com/js/platform.js?onload=googleOnload'
  js.parentNode.insertBefore(gjs, js)
}

export const googleSdkLogin = function () {
  return window.googleAuth.signIn()
}

export const getFingerprint = function () {
  return new Promise(resolve => {
    let locaUUid = globalConfig.getLclStorageItem(UUID)
    let cookUUid = globalConfig.getCookie(UUID)
    let saveUuid = locaUUid || cookUUid

    let nlUUid = globalConfig.getLclStorageItem(N_UUID)
    let ncUUid = globalConfig.getCookie(N_UUID)
    let nsaveUUid = nlUUid || ncUUid

    // loginid优先级：N_UUID 》 UUID 》 新生成
    if (nsaveUUid) {
      if (!ncUUid || (nlUUid && nlUUid !== ncUUid)) {
        globalConfig.setCookie(N_UUID, nsaveUUid)
      }
      if (!nlUUid) {
        globalConfig.setLclStorageItem(N_UUID, nsaveUUid)
      }
      resolve(nsaveUUid)
    } else if (saveUuid) {
      if (!cookUUid || (locaUUid && locaUUid !== cookUUid)) {
        globalConfig.setCookie(UUID, saveUuid)
      }
      if (!locaUUid) {
        globalConfig.setLclStorageItem(UUID, saveUuid)
      }
      resolve(saveUuid)
    } else {
      Fingerprint2.getV18(
        {
          excludes: {
            language: true
          }
        },
        function (result) {
          let timestamp = new Date().valueOf()
          let realUuid = result + timestamp
          globalConfig.setLclStorageItem(UUID, realUuid)
          globalConfig.setCookie(UUID, realUuid)
          resolve(realUuid)
        }
      )
    }
  })
}

export const getLoginId = function () {
  let locaUUid = globalConfig.getLclStorageItem(UUID)
  let cookUUid = globalConfig.getCookie(UUID)
  let nlUUid = globalConfig.getLclStorageItem(N_UUID)
  let ncUUid = globalConfig.getCookie(N_UUID)
  return nlUUid || ncUUid || locaUUid || cookUUid || ''
}
