import { mapGetters } from 'vuex';
import globalConfig from '../utiles/globalConfig';

export default {
  data() {
    return {
      isShowFullscreen:
        (window.pageInfo['fullscreen']['full'] &&
          window.pageInfo['fullscreen']['exit']) ||
        (window.isFbCanvas && window.pageInfo['fullscreen']['exit']),
      fullscreenImg: window.pageInfo['fullscreen']['full'],
      exitfullscreenImg: window.pageInfo['fullscreen']['exit'],
      fullscreenHoverImg:
        window.pageInfo['fullscreen']['fullHover'] ||
        window.pageInfo['fullscreen']['full'],
      exitfullscreenHoverImg:
        window.pageInfo['fullscreen']['exitHover'] ||
        window.pageInfo['fullscreen']['exit'],
    };
  },
  watch: {
    isfullscreen(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        globalConfig.addEvenListener(this.keyboardExitfullscreen, 'resize');
      } else {
        globalConfig.removeEventListener(this.keyboardExitfullscreen, 'resize');
      }
    },
  },
  computed: {
    ...mapGetters(['isfullscreen', 'clickfullscreen']),
  },
  methods: {
    fullscreen() {
      let element = document.getElementById('j-game-content');
      this.excFullscreen(element);
      setTimeout(() => {
        this.$store.commit('updateFullscreenState', true);
      }, 300);
    },
    exitfullscreen() {
      this.$store.commit('updateFullscreenState', false);
      this.$store.commit('upateFullscreenClickState', false);
      this.excExitFullScreen();
    },
    excExitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },

    excFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    keyboardExitfullscreen() {
      if (!this.isfullscreen) {
        return;
      }
      if (this.clickfullscreen) {
        this.exitfullscreen();
      } else {
        this.$store.commit('updateFullscreenState', false);
        this.$store.commit('upateFullscreenClickState', false);
      }
    },
  },
};
