<template>
  <div class="award_float_box" :style="boxCss" v-if="body && close">
    <a
      href="javascript:;"
      class="close j-page-award-box-close"
      :style="closeBtnCss"
      @click="closeFloatBox"
    >
      <img :src="close" :style="closeCss" alt="" />
    </a>
    <img :src="body" :style="bodyCss" alt="" />
    <p class="action-box" :style="actionCss">
      <a class="favour j-page-favour-btn" v-if="favour" @click="favourFn">
        <img :src="favour" :style="favourCss" alt="" />
      </a>
      <a
        class="download j-page-download-btn"
        v-if="download"
        @click="downloadFn"
      >
        <img :src="download" :style="downloadCss" alt="" />
      </a>
      <a class="check j-page-check-btn" @click="excGetGiftList" v-if="check">
        <img :src="check" :style="checkCss" alt="" />
      </a>
    </p>
  </div>
</template>

<script>
import { pxToVh } from '../utiles/gameWidth'
import Favour from '../mixins/Favour'
import Download from '../mixins/Download'
import LoadConfigImg from '../mixins/LoadConfigImg'
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      floatBox: window.pageInfo['awardFloatBox'],
      boxCss: `${window.pageInfo['awardFloatBox'].direc}:${pxToVh(
        window.pageInfo['awardFloatBox'].direcvalue
      )}vh;bottom:${pxToVh(window.pageInfo['awardFloatBox'].bottom)}vh;`,
      actionCss: `bottom:${pxToVh(
        window.pageInfo['awardFloatBox'].actionBottom
      )}vh`,
      body: '',
      bodyCss: '',
      download: '',
      downloadCss: '',
      favour: '',
      favourCss: '',
      check: '',
      checkCss: '',
      close: '',
      closeCss: '',
      closeBtnCss: ''
    }
  },
  mixins: [Favour, Download, LoadConfigImg],
  mounted() {
    this.loadAwardFloatImg()
  },
  watch: {
    lang(newV, oldV) {
      if (newV && newV !== oldV) {
        this.loadAwardFloatImg()
      }
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    loadAwardFloatImg() {
      if (this.floatBox) {
        let curInfo = this.floatBox.info[this.lang]
          ? this.floatBox.info[this.lang]
          : this.floatBox.info[window.pageInfo['lang']]
        if (curInfo.body) {
          this.loadImg(curInfo.body, 'body', true, true)
        }
        if (curInfo.download) {
          this.loadImg(curInfo.download, 'download', true, true)
        }
        if (curInfo.favour) {
          this.loadImg(curInfo.favour, 'favour', true, true)
        }
        if (this.floatBox.close) {
          this.loadImg(this.floatBox.close, 'close', true, true)
        }
        if (curInfo.check) {
          this.loadImg(curInfo.check, 'check', true, true)
        }
      }
    },
    updateSpecialSize(type, img) {
      if (type === 'close') {
        this.closeBtnCss = `right:-${pxToVh(img.width)}vh;top:-${pxToVh(
          img.height
        )}vh;`
      }
    },
    closeFloatBox() {
      this.$emit('closeAwardFloat')
    },
    excGetGiftList() {
      this.getGiftList()
      this.$store.commit('toggleGiftListPopup', true)
    },
    ...mapActions(['getGiftList'])
  }
}
</script>

<style lang="scss" scoped>
.award_float_box {
  position: fixed;
  bottom: 0;
  z-index: 3;
  .close {
    position: absolute;
    top: 0;
    right: -100%;
    cursor: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .action-box {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    .favour,
    .download {
      display: inline-block;
      vertical-align: middle;
      margin: 0 6vh 10vh;
      font-size: 0;
      &:hover {
        filter: brightness(1.1);
        cursor: pointer;
      }
    }
    .check {
      &:hover {
        filter: brightness(1.1);
        cursor: pointer;
      }
    }
  }
}
</style>
