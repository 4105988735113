import { mapGetters } from 'vuex'
import { getFingerprint } from '../utiles/thirdPartySdkFn'
import globalConfig from '../utiles/globalConfig'
import Exe from '../mixins/Exe'

let gameLinkSave = '' // 用来避免研发奇怪的重定向和跳转

export default {
  computed: {
    ...mapGetters(['letter'])
  },
  mixins: [Exe],
  beforeCreate() {
    getFingerprint()
    if (!this.isElectronExe) {
      window.onbeforeunload = function () {
        return true
      }
    }

    this.$store.commit('updateGameId', +window.pageInfo['game_id'])

    if (!window.canPlay) {
      alert(this.letter.lowerIE)
    }
  },
  mounted() {
    globalConfig.addEvenListener(this.sdkMessageHandler, 'message')
    if (!this.isElectronExe) {
      window.onbeforeunload = () => {
        globalConfig.gaStatistics('click_close_window')
        return this.letter.leaveTip
      }
    }
  },
  methods: {
    gameLoad() {
      if (this.gameLink && this.gameLink !== gameLinkSave) {
        gameLinkSave = this.gameLink
        globalConfig.gaStatistics('game_load')
      }
    }
  }
}
