export const getIntervalHours = date => {
  return (new Date() - new Date(date)) / (60 * 60 * 1000)
}

export const checkInValidTime = (startTime, endTime) => {
  let now = new Date()

  if (!startTime && !endTime) {
    return true
  }

  if (!startTime && endTime) {
    return now <= new Date(endTime)
  }

  if (startTime && !endTime) {
    return now >= new Date(startTime)
  }

  return now >= new Date(startTime) && now <= new Date(endTime)
}
