import { mapActions, mapGetters } from 'vuex';
import globalConfig from '../utiles/globalConfig';
import {
  fbSdkGetStatus,
  fbSdkLogin,
  googleSdkLogin,
  getFingerprint,
} from '../utiles/thirdPartySdkFn';
import { isObj, isFn } from '../utiles/judgeType';
import LinkTermUrl from './LinkTermUrl';

const mainHost = globalConfig.getMainHost();

export default {
  data() {
    return {
      showAboutPopup: false,
      otherRemenber: true,
      loginText: {
        guest: 'Guest',
        facebook: 'Facebook',
        googleplay: 'Google',
        twitter: 'Twitter',
        line: 'Line',
      },
      saveTwResolve: null,
      twiTimer: null,
      termState: true,
      loginType: null,
    };
  },
  mixins: [LinkTermUrl],
  computed: {
    // thirdList() {
    // 	return this.$store.state.login.thirdList;
    // },
    // isRemember() {
    // 	return !!this.$store.state.login.isRemember;
    // },
    ...mapGetters(['appId', 'gameId', 'letter', 'thirdList']),
  },
  methods: {
    toggleOtherAgreeTerm() {
      this.termState = !this.termState;
    },
    toggleOtherRemember() {
      this.otherRemenber = !this.otherRemenber;
      // this.$store.dispatch(UPDATE_REMEMBER);
    },
    otherLogin(type) {
      if (!this.termState) {
        this.$store.commit('updateCommonTips', {
          content: this.letter.checkServiceTips,
        });
        return;
      }
      this.excOtherLogin(type).then(this.judgeIsLogin, this.loginFail);
    },
    excOtherLogin(type) {
      try {
        globalConfig.gaStatistics('login_type', {
          login_type: type,
        });
      } catch (e) {}

      this.loginType = type;

      switch (type) {
        case 'tourist':
          return this.doTouristLogin();
        case 'facebook':
          return this.doFbLogin();
        case 'twitter':
          return this.doTwiLogin();
        case 'googleplay':
          return this.doGoogleLogin();
        default:
          return this.emptyPro();
      }
    },
    doTouristLogin() {
      return getFingerprint().then(
        uuid => {
          return this.touristLogin({
            machineId: uuid,
            loginId: uuid,
            remenber: this.otherRemenber,
          });
        },
        e => {
          this.loginFail(e, 'tourist');
        }
      );
    },
    doFbLogin() {
      return fbSdkGetStatus().then(async (res) => {
        if (res.status === 'connected') {
          return this.fbLogin({
            accessToken: res.authResponse.accessToken,
            appId: this.appId,
            remenber: this.otherRemenber,
            machineId: await getFingerprint(),
          });
        } else {
          return fbSdkLogin().then(this.doFbLogin, e => {
            this.loginFail(e, 'facebook');
          });
        }
      });
    },
    doTwiLogin() {
      return new Promise((resolve, reject) => {
        this.saveTwResolve = null;
        this.saveTwReject = null;
        globalConfig.addEvenListener(this.doTwiLoginCallback, 'message');
        // page参数指定postmessage发送的域名
        let twiPopup = window.open(
          `https://gpassport.${mainHost}/h5_twitter/auth?page=h5&game_id=${this.gameId}`,
          '_blank'
        );
        this.saveTwResolve = resolve;
        this.saveTwReject = reject;
        this.startTwiLoginTimer(twiPopup);
      });
    },
    async doTwiLoginCallback(e) {
      let res = e.data;
      if (isObj(res) && res.action === 'twCallback') {
        clearInterval(this.twiTimer);
        this.twiTimer = null;
        return this.twiLogin({ ...res, remenber: this.otherRemenber, machineId: await getFingerprint() }).then(
          res => {
            globalConfig.removeEventListener(
              this.doTwiLoginCallback,
              'message'
            );
            if (isFn(this.saveTwResolve)) {
              this.saveTwResolve(res);
            }
          },
          e => {
            setTimeout(() => {
              this.saveTwReject({ msg: e.msg || e, type: 'twitter' });
            }, 800);
          }
        );
      }
    },
    startTwiLoginTimer(winEle) {
      if (this.twiTimer) {
        clearInterval(this.twiTimer);
        this.twiTimer = null;
      }
      this.twiTimer = setInterval(() => {
        if (winEle.closed) {
          clearInterval(this.twiTimer);
          this.twiTimer = null;
          globalConfig.removeEventListener(this.doTwiLoginCallback, 'message');
          this.saveTwReject({ msg: 'user_close', type: 'twitter' });
        }
      }, 600);
    },
    doGoogleLogin() {
      return googleSdkLogin().then(
        async (res) => {
          return this.googleLogin({
            idToken: res.getAuthResponse().id_token,
            remenber: this.otherRemenber,
            machineId: await getFingerprint(),
          });
        },
        e => {
          this.loginFail(e, 'googleplay');
        }
      );
    },
    loginFail(e, type) {
      try {
        // 兼容一下各个串街登录返回的错误信息
        let gamsg = e.msg || e.error || e.status || e || '';
        globalConfig.gaStatistics('login_fail', {
          login_type: type || e.type || '',
          msg: gamsg.replace(/\([0-9]+\)/g, ''),
        });
      } catch (e) {}
      let res = isObj(e) ? e.error || e.msg : e;
      let msg =
        res.replace(/\[[^\]]*\]/, '') || this.letter.fb_canvas_login_fail;
      alert(msg);
    },
    emptyPro() {
      return new Promise(resolve => {
        resolve();
      });
    },
    ...mapActions([
      'touristLogin',
      'judgeIsLogin',
      'fbLogin',
      'googleLogin',
      'twiLogin',
    ]),
  },
};
