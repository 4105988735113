import { render, staticRenderFns } from "./AgePopup.vue?vue&type=template&id=6f7cf4cc&scoped=true&"
import script from "./AgePopup.vue?vue&type=script&lang=js&"
export * from "./AgePopup.vue?vue&type=script&lang=js&"
import style0 from "./AgePopup.vue?vue&type=style&index=0&id=6f7cf4cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7cf4cc",
  null
  
)

component.options.__file = "AgePopup.vue"
export default component.exports