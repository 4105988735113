<template>
  <div class="container" v-if="showMobageTips">
    <div class="mobage-mask"></div>
    <div class="mobage-tips">
      <div class="mobage-tips-content">
        <div class="head">{{ title }}</div>
        <div class="step-1" v-if="mobageStep === 1">
          <p class="tips">
            <span v-html="attentionTips"></span>
            <span>
              <a class="jumpToAgreePage" @click="addMobageStep">同意事項</a
              >に同意してください。
            </span>
          </p>
          <span class="sm-text" v-html="attentionTipsAgree"></span>
        </div>
        <div class="step-2" v-if="mobageStep === 2">
          <p class="tips" v-html="agreeTips"></p>
        </div>
        <div class="agree" @click="hideTips">同意する</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHOW_MOBAGE_TIP } from '../../mixins/mobage';

const MOBAGE_CONFIG = window.pageInfo.thirdPlatformConfig.mobage;

export default {
  name: 'mobageTips',
  data() {
    return {
      showMobageTips: SHOW_MOBAGE_TIP,
      mobageStep: 1,
      gameName: window.gameInfo.NAME,
      gameId: window.gameId,
      attentionTips: MOBAGE_CONFIG ? MOBAGE_CONFIG.attentionTips : '',
      attentionTipsAgree: MOBAGE_CONFIG ? MOBAGE_CONFIG.attentionTipsAgree : '',
      agreeTips: MOBAGE_CONFIG ? MOBAGE_CONFIG.agreeTips : '',
    };
  },
  computed: {
    title() {
      return this.mobageStep === 1 ? 'ご注意' : '同意事項';
    },
  },
  methods: {
    hideTips() {
      this.showMobageTips = false;
      this.mobageStep = 1;
    },
    addMobageStep() {
      this.mobageStep++;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  z-index: 109;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mobage-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.8);
}
.mobage-tips {
  &-content {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 374px;
    max-width: 100%;
    max-height: 100%;
    background: #e3e1e3;
    text-align: center;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    @media screen and (orientation: landscape) {
      transform: translate(-50%, -50%) scale(0.8);
    }

    .head {
      width: 100%;
      height: 50px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: #9f9f9f;
      line-height: 50px;
      font-size: 24px;
      text-align: center;
      color: #fff;
    }
    .step-1,
    .step-2 {
      overflow: auto;
      max-height: 400px;
      @media screen and (orientation: landscape) {
        max-height: 220px;
      }
    }
    .tips {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      margin: 0;
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      color: #636263;

      &.text-align-left {
        text-align: left;
      }
      &.step-2 {
        overflow: auto;
        max-height: 400px;
      }
    }
  }
  .jumpToAgreePage {
    color: #3e7ef4;
    cursor: pointer;
  }
  .agree {
    width: 374px;
    max-width: 100%;
    height: 50px;
    margin-top: 10px;
    border-top: 1px solid #9d9d9d;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    color: #3e7ef4;
    cursor: pointer;
  }
}
.sm-text {
  font-size: 14px;
}
</style>
