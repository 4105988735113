import { getJSON } from './ultile'
import ajaxUrl from '../config/ajax'

export default {
  state: {
    messageCount: 0
  },
  getters: {
    messageCount: state => state.messageCount
  },
  mutations: {
    updateMessageCount(state, data) {
      state.messageCount = data
    }
  },

  actions: {
    getMessageCount({ commit, rootState }) {
      return new Promise(resolve => {
        if (!ajaxUrl[rootState.platform].other.message) {
          resolve()
          return
        }
        getJSON(ajaxUrl[rootState.platform].other.message, {
          type: 'load'
        }).then(res => {
          commit('updateMessageCount', res.data.remain_msg)
          resolve()
        })
      })
    }
  }
}
