import { mapGetters } from 'vuex'
import { isSafari } from '../utiles/index'
import globalConfig from '../utiles/globalConfig'

// 这个mixins是用来处理pwa安装的,只能用在一个地方,不要多次引入使用
export default {
  data() {
    return {
      isInstall: true,
      isGuide: false,
      isExe: globalConfig.getHrefParam('device') === 'exe',
      pwa: window.pageInfo.pwa,
      isSafari: isSafari(),
      isHideAdd:
        globalConfig.getHrefParam('mode') === 'pwa' ||
        globalConfig.getHrefParam('scid').includes('shortcut'),
      isPwaGuide: false,
      isHadAutoPopupPwaGuide: false
    }
  },
  computed: {
    showPwaInstall() {
      return (
        this.pwa && // 配置开启了PWA
        !this.isExe && // 微端不展示
        !this.isHideAdd && // pwa进入不展示
        (this.isSafari || !this.isInstall) // 已安装不展示，safari展示
      )
    },
    pointParams() {
      return {
        eventType: '',
        loginAccount: this.userName,
        serverId: this.serverId,
        roleId: this.roleId,
        userId: this.userId,
        gclid: this.gclid,
        cid: this.cid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname
      }
    },
    ...mapGetters([
      'gameId',
      'lang',
      'userName',
      'userId',
      'gclid',
      'cid',
      'scid',
      'serverId',
      'roleId',
      'fbc',
      'fbp',
      'adid',
      'adname',
      'camid',
      'camname',
      'adsetid',
      'adsetname'
    ])
  },
  created() {
    if (this.pwa) {
      window.deferredPrompt = null
      this.loadWebmainifest()
      this.pwaInstallationGuide()

      // 1. 需要网页加载完成的
      window.addEventListener('load', () => {
        console.log('serviceWorker valid')
        // 2. 能力检测
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register(
            'https://' + window.location.host + '/h5/sw.js'
          )
        }
      })
    }
  },
  mounted() {
    if (this.pwa) {
      // app安装了
      window.addEventListener('appinstalled', () => {
        this.setIsInstall()
        // 回收 deferredPrompt 变量
        window.deferredPrompt = null
        // pwa成功安装
        globalConfig.gaStatistics('success_install_pwa')
        globalConfig.ajaxStatistics({
          eventName: 'success_install_pwa',
          ...this.pointParams
        })
      })

      window.addEventListener('message', e => {
        if (e.data.action === 'installPwa') {
          this.userCenterInstall()
        }
      })

      // 自动弹出安装提示：iOS
      if (this.isSafari && !this.isHideAdd) {
        const IOS_LOCAL_KEY = 'isShowIOSPwaGuide'
        const isShowIOSPwaGuide =
          Number(window.localStorage.getItem(IOS_LOCAL_KEY)) === 1
        if (!isShowIOSPwaGuide) {
          this.isPwaGuide = true
          window.localStorage.setItem(IOS_LOCAL_KEY, 1)
        }
      }
    }
  },
  methods: {
    pwaInstallationGuide() {
      // 页面加载后 和 选择取消了安装会触发 （iOS不会触发这个事件）
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        this.setInUninstall()
        window.deferredPrompt = e
        // 自动弹出安装提示：PC或者安卓
        if (
          +globalConfig.getHrefParam('popupPwa') === 1 &&
          !this.isHadAutoPopupPwaGuide
        ) {
          this.isPwaGuide = true
          this.isHadAutoPopupPwaGuide = true
        }
      })
    },
    loadWebmainifest() {
      const linkEle = document.createElement('link')
      linkEle.setAttribute('rel', 'manifest')
      linkEle.setAttribute(
        'href',
        'https://' +
          window.location.host +
          '/h5/pwa/webmainifest/game' +
          window.pageInfo.game_id +
          '.webmanifest'
      )
      document.head.appendChild(linkEle)
    },
    saveSourceParams() {
      const linkCid = globalConfig.getHrefParam('cid')
      const linkScid = globalConfig.getHrefParam('scid')
      try {
        window.localStorage.setItem(
          'pwaSourceParams',
          JSON.stringify({
            time: new Date().getTime(),
            cid: linkCid ? linkCid : '',
            scid: linkScid ? linkScid + '_shortcut' : 'shortcut'
          })
        )
      } catch (e) {
        console.error(e)
      }
    },
    setInUninstall() {
      this.isInstall = false
    },
    setIsInstall() {
      this.saveSourceParams()
      this.isInstall = true
    },
    showSafariTip() {
      this.isGuide = true
      globalConfig.gaStatistics('add_to_screen_now')
      globalConfig.ajaxStatistics({
        eventName: 'add_to_screen_now',
        ...this.pointParams
      })
    },
    hideSafariTip() {
      this.isGuide = false
    },
    hidePwaGuide() {
      this.isPwaGuide = false
    },
    updateUrlWithParams(currentUrl, newParams) {
      // 解析当前 URL
      const url = new URL(currentUrl)
      // 遍历并更新/添加新的查询参数
      Object.keys(newParams).forEach(key => {
        url.searchParams.set(key, newParams[key])
      })
      // 返回更新后的 URL 字符串
      return url.toString()
    },
    userCenterInstall() {
      this.isSafari ? this.showSafariTip() : this.installPwa()
      globalConfig.gaStatistics('click_user_center_pwa_install')
      globalConfig.ajaxStatistics({
        eventName: 'click_user_center_pwa_install',
        ...this.pointParams
      })
    },
    headerInstall() {
      this.isSafari ? this.showSafariTip() : this.installPwa()
      globalConfig.gaStatistics('click_header_pwa_install')
      globalConfig.ajaxStatistics({
        eventName: 'click_header_pwa_install',
        ...this.pointParams
      })
    },
    guideInstall() {
      this.isSafari ? this.showSafariTip() : this.installPwa()
      globalConfig.gaStatistics('click_popup_pwa_install')
      globalConfig.ajaxStatistics({
        eventName: 'click_popup_pwa_install',
        ...this.pointParams
      })
    },
    installPwa() {
      if (!window.deferredPrompt) return
      window.deferredPrompt.prompt()
      window.deferredPrompt.userChoice.then(result => {
        if (result.outcome == 'dismissed') {
          // 用户点击页面上的安装按钮
          globalConfig.gaStatistics('cancel_install_pwa')
          globalConfig.ajaxStatistics({
            eventName: 'cancel_install_pwa',
            ...this.pointParams
          })
        } else if (result.outcome == 'accepted') {
          this.setIsInstall()
          window.deferredPrompt = null
          // 用户点击页面上的安装按钮
          globalConfig.gaStatistics('accept_install_pwa')
          globalConfig.ajaxStatistics({
            eventName: 'accept_install_pwa',
            ...this.pointParams
          })
        }
      })
    }
  },
  watch: {
    showPwaInstall: {
      handler(val) {
        this.$store.commit('updateShowPwaInstall', val)
      },
      immediate: true
    }
  }
}
