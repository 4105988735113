import ajax from '../config/ajax';
import { getJSON } from './ultile';
import globalConfig from '../utiles/globalConfig';

export const FROM_MOBAGE = globalConfig.getThirdPlatformType() === 'mobage';

export default {
  state: {
    mobageState: '',
    showNbpf: false,
    nbpfLoginStatus: false,
    productId: '',
  },
  getters: {
    mobageState: state => state.mobageState,
    showNbpf: state => state.showNbpf,
    nbpfLoginStatus: state => state.nbpfLoginStatus,
    productId: state => state.productId,
  },
  mutations: {
    updateMobageState(state, mobageState) {
      state.mobageState = mobageState;
    },
    updateShowNbpf(state, bool) {
      state.showNbpf = bool;
    },
    updateNbpfLoginStatus(state, bool) {
      state.nbpfLoginStatus = bool;
    },
    updateProductId(state, id) {
      state.productId = id;
    },
  },
  actions: {
    // Mobage获取商品信息
    getMobageProductInfo({ commit, state, rootState }, payload) {
      return new Promise(resolve => {
        commit('updateProductId', payload.productId);
        getJSON(ajax.mobage.pay.showProduct, {
          game_id: rootState.gameId,
          server_sid: payload.serverId,
          role_id: payload.roleId,
          f_pay_type: 'mobage_nbpf',
          pay_type: 'mobage_nbpf',
          currency: 'MOBA',
          product_id: payload.productId,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    // 监听用户退出登录游戏logout
    initMobageSessionListener({}, payload) {
      const subscribe = () => {
        mobage.event.subscribe(
          'oauth.sessionStateChange',
          payload.state,
          result => {
            if (result === 'changed') {
              window.onbeforeunload = false;
              setTimeout(() => {
                window.location.href = ajax.mobage.login.nbpfLogout;
              });
            }
          }
        );
      };
      window.mobageReady
        ? subscribe()
        : document.addEventListener('mobageReady', subscribe);
    },
  },
};
