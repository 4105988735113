<template>
  <div class="commonGm99Footer" :class="`${ismobile ? 'mobile' : ''} ${host}`">
    <div class="footer-box">
      <div class="left">
        <img class="logo" :src="logoUrl" alt="" />
        <p class="link-group" v-if="ismobile">
          <a
            class="link"
            target="_blank"
            :href="linkConf.guanyu.link"
            v-if="isGm99"
          >
            {{ linkConf.guanyu.name }}
          </a>
          <a class="link" target="_blank" :href="linkConf.fuwu.link">
            {{ linkConf.fuwu.name }}
          </a>
          <a
            class="link"
            target="_blank"
            :href="linkConf.hezuo.link"
            v-if="isGm99"
          >
            {{ linkConf.hezuo.name }}
          </a>
          <a
            class="link"
            target="_blank"
            :href="linkConf.kefu.link"
            v-if="isGm99"
          >
            {{ linkConf.kefu.name }}
          </a>
        </p>
        <p class="copyright" v-html="copyright"></p>
      </div>
      <div class="right">
        <div>
          <img
            class="grade"
            :class="`class-${pageInfo.gameClass}`"
            :src="gradeUrl"
            alt=""
          />
          <p
            class="grade-t"
            v-for="(item, index) in pageInfo.classText"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
        <p class="link-group" v-if="!ismobile">
          <a
            class="link"
            target="_blank"
            :href="linkConf.guanyu.link"
            v-if="isGm99"
          >
            {{ linkConf.guanyu.name }}
          </a>
          <a class="link" target="_blank" :href="linkConf.fuwu.link">
            {{ linkConf.fuwu.name }}
          </a>
          <a
            class="link"
            target="_blank"
            :href="linkConf.hezuo.link"
            v-if="isGm99"
          >
            {{ linkConf.hezuo.name }}
          </a>
          <a
            class="link"
            target="_blank"
            :href="linkConf.kefu.link"
            v-if="isGm99"
          >
            {{ linkConf.kefu.name }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoUrl from '../../mixins/LogoUrl'
import LinkTermUrl from '../../mixins/LinkTermUrl'
import Platform from '../../mixins/Platform'
import globalConfig from '../../utiles/globalConfig'
const mainHost = globalConfig.getMainHost()

export default {
  name: 'commonGm99',
  data() {
    return {
      pageInfo: window.pageInfo,
      ismobile: window.isPhone,
      gradeUrl: `//abres.octlib.com/platformIcon/h5/grade-${window.pageInfo['gameClass']}.png`,
      copyright: `©Copyright 2013-${window.copyrightTime} www.${mainHost} All Rights Reserved.`,
      host: mainHost.replace(/\.[a-zA-Z]*$/, '')
    }
  },
  mixins: [LinkTermUrl, Platform, LogoUrl],
  computed: {
    linkConf() {
      return {
        kefu: {
          name: '客服中心',
          link: '//service.gm99.com/'
        },
        hezuo: {
          name: '合作提案',
          link: '//service.gm99.com/about/cooperation.html'
        },
        fuwu: {
          name: '服務條款',
          link: this.isGm99
            ? '//service.gm99.com/about/termsofuse.html'
            : this.termUrl
        },
        guanyu: {
          name: '關於我們',
          link: '//service.gm99.com/about/abouts.html'
        }
      }
    },
    ...mapGetters(['platform'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen.scss';
.commonGm99Footer {
  width: 100%;
  padding: 50vh 0 40vh;
  box-sizing: border-box;
  background: white;
}
.footer-box {
  width: 90%;
  display: block;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.left {
  float: left;
}
.right {
  float: right;
}
.logo {
  height: 60vh;
  display: block;
}
.copyright {
  font-size: 14vh;
  color: #333;
  margin: 10vh auto auto;
  line-height: 150%;
}
.link-group {
  font-size: 0;
  margin-top: 6vh;
  .link {
    font-size: 14vh;
    color: #333;
    display: inline-block;
    padding: 0 10vh;
    text-decoration: none;
    position: relative;
    &:last-child {
      &::after {
        width: 0;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:hover {
      cursor: pointer;
      color: $orange;
    }
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 70%;
      top: 15%;
      right: 0;
      background: #333;
    }
  }
}

.grade {
  float: left;
  width: 60vh;
  height: 60vh;
}

.grade-t {
  font-size: 14vh;
  color: #333;
  margin: -1px auto 0;
  white-space: nowrap;
  padding-left: 70vh;
  -webkit-text-size-adjust: none;
}
.commonGm99Footer.mobile {
  .footer-box {
    width: 96%;
    text-align: center;
  }
  .left,
  .right {
    float: none;
  }
  .left {
    text-align: center;
    .logo {
      display: block;
      margin: auto auto 10vh;
    }
    .copyright,
    .link-group {
      margin: 4vh auto 0;
    }
  }
  .right {
    margin-top: 10vh;
    display: inline-block;
    text-align: left;
  }
}
.commonGm99Footer.blancozone {
  .copyright {
    margin-left: 60vh;
  }
}
</style>
