<template>
  <div>
    <em class="h-r"></em>
    <div class="content-box">
      <a class="btn" href="javascript:void(0);" @click="mobageOpenHome">
        <img class="btn-icon" src="../../assets/image/mobage-home.png" alt="" />
        <span class="btn-label">mobage</span>
      </a>
      <a class="btn" href="javascript:void(0);" @click="mobageOpenSetting">
        <img class="btn-icon" src="../../assets/image/mobage-set.png" alt="" />
        <span class="btn-label">設定変更</span>
      </a>
      <a class="btn" href="javascript:void(0);" @click="mobageOpenLaw">
        <img class="btn-icon" src="../../assets/image/mobage-item.png" alt="" />
        <span class="btn-label">特商法</span>
      </a>
      <a class="btn" href="javascript:void(0);" @click="mobageOpenUserCenter">
        <img
          class="btn-icon"
          src="../../assets/image/mobage-service.png"
          alt=""
        />
        <span class="btn-label">お問い合わせ</span>
      </a>
    </div>
  </div>
</template>

<script>
import MobageAsideLink from '../../mixins/MobageAsideLink'
export default {
  mixins: [MobageAsideLink]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.h-r {
  top: 2vh !important;
  background-position: right;
  z-index: -1;
}
</style>
