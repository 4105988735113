import Vue from 'vue'
import Rsa from './Rsa'
import globalConfig from './globalConfig'
import { getJSON } from '../store/ultile'
import ajaxUrl from '../config/ajax'

const userAgent = (window.navigator && window.navigator.userAgent) || ''
const ua = userAgent.toLowerCase()
const rsa = new Rsa()
const mainHost = globalConfig.getMainHost()
export const EventBus = new Vue()
// export const SUCEESSS_POPUP = "successPop";
// export const FAIL_GIFT_POPUP = "failGiftPop";
// export const FAIL_CHECK_POPUP = "failCheckPop";
export let convertParamToString = function (params) {
  let paramArray = []
  for (let key in params) {
    // 需要过滤掉#，因为gam广告会带上这个东东，不然会影响接口的参数拼接
    if (/^\#/.test(key)) continue
    let val = params[key]
    if ('object' === typeof val) {
      val = JSON.stringify(val)
    }
    let param = key + '=' + val
    paramArray.push(param)
  }
  paramArray.push('callback')
  if (paramArray.length > 0) {
    return paramArray.join('&')
  }
  return ''
}

export function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode('0x' + p1)
    })
  )
}

export const encryption = function (text) {
  //todo encryption the text
  return rsa.encode(text)
  // return text;
}

export const decryption = function (text) {
  return rsa.decode(text)
}

// 统计接口,config格式如下：
// {
//     id: 1,
//     la: '帐号',
//     gid: '游戏ID',
//     ext: 1, // 浮层数据
//     e1: '功能类型',
//     e2: '功能名称'
// }
export const statsRequest = function (config) {
  let img = new Image(),
    statsUrl = `//gcollectdata.${mainHost}/ps.gif?`,
    param = ''
  let createParams = config => {
    for (let i in config) {
      param += i + '=' + config[i] + '&'
    }
  }
  Object.prototype.toString.call(config) !== '[object Object]'
    ? globalConfig.log(
        'Error! statsReq config is not a object! The value is ' + config
      )
    : createParams(config)
  img.src = statsUrl + param
}

export const SOUTHEST_ASIA_MODE = 1
export const WESTTERN_MODE = 2

const MAIN_HOST = globalConfig.getMainHost()
export const EXCHANGE_URL = `//gevents.${mainHost}/globalexchange/indexm.html`
export const FEEKBACK_URL = `//service.${MAIN_HOST}#/mylist`
export const SERVICE_TERM_URL = `//gpassport.${MAIN_HOST}/center/servicePrivicy/service`
export const PRIVICY_TERM_URL = `//gpassport.${MAIN_HOST}/center/servicePrivicy/privicy`
export const STORE_PAGE_BASE_URL = `//pay.${MAIN_HOST}`
export const RES_HOST = `//gabres.${MAIN_HOST}/`
export const SERVICE_URL = `//gabres.${MAIN_HOST}/web/support.html`

export const renderGoogleRecaptcha = function (id, key) {
  if (window.grecaptcha.render) {
    window.grecaptcha.render(id, {
      sitekey: key,
      callback: 'onSubmit'
    })
  } else {
    window.recaptchaOnload = () => {
      window.grecaptcha.render(id, {
        sitekey: key,
        callback: 'onSubmit'
      })
    }
  }
}

export const submitGoogleRecaptcha = function (callback) {
  window.onSubmit = token => {
    grecaptcha.reset()
    if (callback && callback instanceof Function) {
      callback(token)
    }
  }
}

export const scrollTop = function () {
  try {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  } catch (error) {}
}

/**
 * 浏览器类型
 */
export const getBrowser = () => {
  let userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('firefox') >= 0) {
    return 'Firefox'
  }
  if (userAgent.indexOf('opera') >= 0 || userAgent.indexOf('opr') >= 0) {
    return 'Opera'
  }
  if (userAgent.indexOf('chrome') >= 0) {
    return 'Chrome'
  }
  if (userAgent.indexOf('safari') >= 0) {
    return 'Safari'
  }
  if (userAgent.indexOf('trident') >= 0) {
    return 'Internet Explorer'
  }
  return 'Other'
}

export const getPhoneModel = () => {
  let userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('windows phone') >= 0) {
    return 'Windows Phone'
  }
  if (userAgent.indexOf('win') >= 0) {
    return 'Windows'
  }
  if (userAgent.indexOf('android') >= 0) {
    return 'Android'
  }
  if (userAgent.indexOf('linux') >= 0 || userAgent.indexOf('cros') >= 0) {
    return 'Linux'
  }
  if (userAgent.indexOf('iphone') >= 0 || userAgent.indexOf('ipad') >= 0) {
    return 'iOS'
  }
  if (userAgent.indexOf('mac') >= 0) {
    return 'Mac'
  }
  return 'Other'
}

export const checkIsElectron = () => {
  return navigator.userAgent.toLowerCase().indexOf('electron') > -1
}

// 旧版通用的判断设备逻辑
export const getDeviceOld = () => {
  var userAgent = window.navigator.userAgent
  var ua = userAgent.toLowerCase()
  var platform = {
    isAndroid: function () {
      return ua.indexOf('android') > -1
    },
    isIOS: function () {
      var NPlatform = navigator.platform
      return (
        ua.indexOf('iphone') > -1 ||
        /iPad|iPhone|iPod/gi.test(NPlatform) ||
        (NPlatform === 'MacIntel' &&
          navigator.maxTouchPoints > 1 &&
          !window.MSStream)
      )
    },
    isWinPhone: function () {
      return !!navigator.userAgent.match(/Windows Phone/i)
    },
    isIpad: function () {
      return ua.indexOf('ipad') > -1
    },
    isPhone: function () {
      return (
        this.isAndroid() || this.isIOS() || this.isWinPhone() || this.isIpad()
      )
    }
  }
  return platform
}

export const systemType = () => {
  let _p = getDeviceOld()
  return _p.isIOS() || _p.isIpad()
    ? 'ios'
    : _p.isAndroid() || _p.isWinPhone()
    ? 'android'
    : 'pc'
}

export const isMobileChrome = function () {
  let _p = getDeviceOld()
  let chromeInIOS = /iapd|iphone|ipod/.test(ua) && /crios/.test(ua)
  let chromeInAndroid = _p.isAndroid() && /chrome/.test(ua)
  return chromeInIOS || chromeInAndroid
}

export const isSafari = function () {
  let safari = /version\/[\d\.]+.*safari/.test(ua)
  return !isMobileChrome() && safari
}
