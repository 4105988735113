<template>
  <div class="webview-sdk">
    <div class="webview-sdk-header">
      <a class="x" @click="closeWebview" href="javascript:;"></a>
    </div>
    <iframe
      :src="link"
      class="webview-iframe"
      id="j-webview-iframe"
      title=""
    ></iframe>
  </div>
</template>
  
<script>
export default {
  props: ['link'],
  methods: {
    closeWebview() {
      this.$emit('closeWebview')
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import '../../assets/scss/_dimen';
.webview-sdk {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1001;
  .webview-iframe {
    position: relative;
    width: 100%;
    height: calc(100% - 54vh);
    border: none;
  }
  &-header {
    height: 54vh;
    width: 100%;
    background-color: white;
    font-size: 0;
    margin: 0;
    position: relative;
    .back,
    .x {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
    .back {
      background: url($img + 'aside-back.png') no-repeat center center;
      background-size: contain;
      width: 54vh;
      height: 54vh;
      left: 0;
    }
    .x {
      background: url($img + 'aside-close.png') no-repeat center center;
      background-size: contain;
      width: 54vh;
      height: 54vh;
      right: 0;
    }
  }
}
</style>
  