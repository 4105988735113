export const ADS_ASIDE_LEFT_ID = 'ads-aside-left'
export const ADS_ASIDE_RIGHT_ID = 'ads-aside-right'

export const createAsideAds = () => {
  googletag.cmd.push(() => {
    googletag
      .defineSlot(
        `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/aside_left`,
        [
          [160, 600],
          [120, 600]
        ],
        ADS_ASIDE_LEFT_ID
      )
      .addService(googletag.pubads())

    googletag
      .defineSlot(
        `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/aside_right`,
        [
          [160, 600],
          [120, 600]
        ],
        ADS_ASIDE_RIGHT_ID
      )
      .addService(googletag.pubads())
  })
}

export const displayAsideAds = () => {
  googletag.cmd.push(() => {
    googletag.display(ADS_ASIDE_LEFT_ID)
    googletag.display(ADS_ASIDE_RIGHT_ID)
  })
}
