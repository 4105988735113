import { INIT_STAGE_CONFIG } from '../../index'
import { getIntervalHours, checkInValidTime } from './time'
import {
  storeMaxTimesInDay,
  storeLastShowTime,
  storeHideWithinDays,
  getMaxTimesInDay,
  getLastShowTime,
  getHideWithinDays,
  storeHasOverOneDay
} from './storager'

class ActionSdkPopup {
  constructor() {
    // 是否有效
    this.isActivated = false
    // 请求参数
    this.reqParams = ''
    // 接口返回结果
    this.resData = ''
    this.lastShowData = {
      times: 0,
      showResetTimes: false
    }
    this.trigger = ''
    this.validAdList = ''
    this.timer = null
  }

  // 设置每个业务具体的请求参数
  setRequestParams(params) {
    this.reqParams = params
  }

  // 获取每个业务具体的请求参数
  getRequestParams() {
    return this.reqParams
  }

  // 解析服务端返回的参数: 初始化数据 + 判断action是否有效
  parseParams(res) {
    this.resData = res || ''
    // adList为空，则无效action状态
    if (!res.adList || res.adList.length === 0) {
      this.isActivated = false
      return
    }
    this.isActivated = true
  }

  // 任务执行
  process(trigger, context) {
    this.trigger = trigger
    if (this.checkCanShowPopup()) {
      this.loadPopup(context)
      this.handleAutoDismiss(context)
    }
  }

  //用于判断Action有效性，未激活状态的Action不会进行业务动作的展示
  checkIsActivated() {
    return this.isActivated || false
  }

  // 获取启动位置
  getStage() {
    return INIT_STAGE_CONFIG.enterServer.stage
  }

  // 判断是否能弹
  checkCanShowPopup() {
    return this.checkActivityValidTime() && this.handleAntiMolest()
  }

  // 活动有效时间判断
  checkActivityValidTime() {
    let tcaActionId = this.trigger.tcaActionId
    if (!tcaActionId) {
      return false
    }

    let adList = this.resData.adList.filter(function (item) {
      return (
        item.tcaActionId == tcaActionId &&
        checkInValidTime(item.adStartTime, item.adEndTime)
      )
    })
    this.validAdList = adList

    return adList && adList.length > 0
  }

  //防骚扰判断
  handleAntiMolest() {
    if (
      this.checkShowInterval() &&
      this.checkMaxTimesInDay() &&
      this.checkHideWithinDays()
    ) {
      this.updateLocalStorageData()
      return true
    }

    return false
  }
  // N小时弹出一次判断
  checkShowInterval() {
    let resShowInterval = +this.resData.showInterval || 0

    if (resShowInterval === 0) {
      return true
    }

    return this.checkMoreThenHours(resShowInterval) || false
  }

  // 每日弹出上限判断
  checkMaxTimesInDay() {
    let resMaxTimesInDay = +this.resData.maxTimesInDay || 5
    let hasShowTime = getMaxTimesInDay()
    this.lastShowData.times = hasShowTime

    if (hasShowTime < resMaxTimesInDay) {
      return true
    }

    let hasMoreThenOneDay = this.checkMoreThenHours(24, true)
    storeHasOverOneDay(+hasMoreThenOneDay)
    return hasMoreThenOneDay || false
  }

  // 近N天内屏蔽判断
  checkHideWithinDays() {
    let hideWithinDays = getHideWithinDays() || 0

    if (hideWithinDays === 0) {
      return true
    }

    return this.checkMoreThenHours(hideWithinDays * 24, true) || false
  }

  // 判断当前时间与缓存上次时间相比是否大于指定小时
  checkMoreThenHours(hours, isCheckMaxTimesInDay) {
    let lastShowTime = getLastShowTime() || ''

    if (!lastShowTime) {
      return true
    }

    if (getIntervalHours(lastShowTime) >= hours) {
      if (isCheckMaxTimesInDay) {
        this.lastShowData.showResetTimes = true
      }
      return true
    }

    return false
  }

  // 更新localStorage里记录的弹出数据
  updateLocalStorageData() {
    storeLastShowTime()
    this.lastShowData.showResetTimes
      ? storeMaxTimesInDay(1)
      : storeMaxTimesInDay(this.lastShowData.times + 1)
    storeHideWithinDays(0)
  }

  // 处理自动消失
  handleAutoDismiss(context) {
    let autoDismissSecond = +this.resData.autoDismiss || 0
    if (autoDismissSecond === 0) {
      return
    }

    this.timer = setTimeout(() => {
      this.hidePopup(context)
    }, autoDismissSecond * 1000)
  }

  // 取消自动消失
  cancelAutoDismiss() {
    this.timer && clearTimeout(this.timer)
  }

  // 加载弹屏
  loadPopup(context) {
    context['loadTp'](this.getInitPopupInfoData())
  }

  // 关闭弹屏
  hidePopup(context) {
    context['hideTp']()
  }

  // 获取弹屏数据
  getInitPopupInfoData() {
    return Object.assign({}, this.resData, {
      adList: this.validAdList,
      popupTcaActionId: this.trigger.tcaActionId,
      popupEvent: this.trigger.eventName
    })
  }
}

const actionSdkPopup = new ActionSdkPopup()

export default actionSdkPopup
