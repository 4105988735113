import host from './host'
import globalConfig from '../utiles/globalConfig'
const mainhost = globalConfig.getMainHost()
const vgmHost = ['gamemart.vn', 'vgm.vn']
export default {
  gm99: {
    login: {
      checkCode: `//${host.gm99.passort}${mainhost}/ajax/h5_ckcode_state`, //是否需要驗證碼
      loginType: ``,
      getFbAppId: ``,
      checkLogin: `//${host.gm99.passort}${mainhost}/ajax/h5_get_user_json`,
      accountLogin: `//${host.gm99.passort}${mainhost}/login/h5_login`,
      accountRegister: `//${host.gm99.passort}${mainhost}/register/h5_register`,
      fbLogin: `//${host.gm99.passort}${mainhost}/facebook/h5_direct_login_v2`,
      twiLogin: ``,
      gpLogin: ``,
      touristLogin: `//${host.gm99.passort}${mainhost}/direct_login/h5_direct_login`,
      fbCanvasLogin: ``,
      logout: `//${host.gm99.passort}${mainhost}/login/logout2`,
      exeCheckLogin: `//${host.gm99.passort}${mainhost}/micro_game/login_info`,
      exeDirLogin: `//${host.gm99.passort}${mainhost}/micro_game/dir_login`
    },
    other: {
      message: `//${host.gm99.page}${mainhost}/message_api/query_msg`, //站內消息接口
      getTermsState: ``,
      getCurGclid: `//${host.gm99.passort}${mainhost}/micro_game/query_adparams`,
      createRole: `//${host.gm99.passort}${mainhost}/web_create_character`,
      addAdparams: `//${host.gm99.passort}${mainhost}/micro_game/add_adparams`
    },
    enter: {
      enterGame: `//${host.gm99.h5}${mainhost}/h5/play_games/enterGameUrl`,
      enterServer: `//${host.gm99.passort}${mainhost}/add_server`
    },
    gift: {
      number: {
        checkState: `//${host.gm99.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=selectAward`,
        getFavour: `//${host.gm99.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=getCardGift`,
        getDownload: `//${host.gm99.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=getCardGift`
      },
      game: {
        checkState: `//${host.gm99.h5}${mainhost}/${window.gameInfo['ENNAME']}/awardState`,
        getFavour: `//${host.gm99.h5}${mainhost}/${window.gameInfo['ENNAME']}/sendGift`,
        getDownload: `//${host.gm99.h5}${mainhost}/${window.gameInfo['ENNAME']}/sendGift`
      }
    },
    pay: {
      payInit: ``
    },
    tca: {
      enterServer: `//${host.gm99.eventapi}${mainhost}/sdkInit/enterServer`,
      checkTca: `//${host.gm99.eventapi}${mainhost}/sdkInit/checkTca`
    }
  },
  global: {
    login: {
      checkCode: ``,
      loginType: `//${vgmHost.includes(mainhost) ? 'g' : ''}${
        host.global.page
      }${mainhost}/ajax/get_login_type`,
      getFbAppId: `//${host.global.passort}${mainhost}/web_facebook/get_app_id`,
      checkLogin: `//${host.global.passort}${mainhost}/center/ajax/get_user_json`,
      accountLogin: `//${host.global.passort}${mainhost}/web_login/login`,
      accountRegister: `//${host.global.passort}${mainhost}/web_register/register`,
      fbLogin: `//${host.global.passort}${mainhost}/h5_facebook/login`,
      twiLogin: `//${host.global.passort}${mainhost}/h5_twitter/login`,
      gpLogin: `//${host.global.passort}${mainhost}/h5_google/login`,
      touristLogin: `//${host.global.passort}${mainhost}/web_direct_login/login`,
      fbCanvasLogin: ``,
      logout: `//${host.global.passort}${mainhost}/web_login/logout`,
      exeCheckLogin: `//${host.global.passort}${mainhost}/micro_game/login_info`,
      exeDirLogin: `//${host.global.passort}${mainhost}/micro_game/dir_login`
    },
    other: {
      message: ``,
      getTermsState: `//${host.global.eventapi}${mainhost}/sdkInit/userCenterTerms`,
      getCurGclid: `//${host.global.passort}${mainhost}/micro_game/query_adparams`,
      createRole: `//${host.global.passort}${mainhost}/web_create_character`,
      addAdparams: `//${host.global.passort}${mainhost}/micro_game/add_adparams`
    },
    enter: {
      enterGame: `//${host.global.h5}${mainhost}/h5/play_games/enterGameUrl`,
      enterServer: `//${host.global.passort}${mainhost}/web_add_server`
    },
    gift: {
      number: {
        checkState: `//${host.global.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=selectAward`,
        getFavour: `//${host.global.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=getCardGift`,
        getDownload: `//${host.global.eventapi}${mainhost}/activity/execute?name=h5cardaward&action=getCardGift`
      },
      game: {
        checkState: `//${host.global.h5}${mainhost}/${window.gameInfo['ENNAME']}/awardState`,
        getFavour: `//${host.global.h5}${mainhost}/${window.gameInfo['ENNAME']}/sendGift`,
        getDownload: `//${host.global.h5}${mainhost}/${window.gameInfo['ENNAME']}/sendGift`
      }
    },
    pay: {
      payInit: `//${host.global.store}${mainhost}/init_data`
    },
    tca: {
      enterServer: `//${host.global.eventapi}${mainhost}/sdkInit/enterServer`,
      checkTca: `//${host.global.eventapi}${mainhost}/sdkInit/checkTca`
    }
  },
  mobage: {
    login: {
      nbpfLogin: `//${host.global.passort}${mainhost}/mobage_nbpf_login/login/game_id/${window.gameId}`,
      nbpfLoginSandbox: `//${host.global.passort}${mainhost}/mobage_nbpf_login/login/env/test/game_id/${window.gameId}`,
      nbpfRefreshLogin: `//${host.global.passort}${mainhost}/mobage_nbpf_login/observe_token`,
      nbpfRefreshLoginSandbox: `//${host.global.passort}${mainhost}/mobage_nbpf_login/observe_token/env/test`,
      logout: `//${
        host.global.passort
      }${mainhost}/webLogin/logout?forward=${encodeURIComponent(
        window.location.href
      )}`,
      nbpfLogout: `//${
        host.global.passort
      }${mainhost}/mobage_nbpf_login/logout?forward=${encodeURIComponent(
        window.location.href
      )}`,
      auth: `//${host.global.passort}${mainhost}/mobage_nbpf_login/mobage_auth`
    },
    pay: {
      productInfo: `//${host.global.store}${mainhost}/mobage_pc/product_info`,
      showProduct: `//${host.global.store}${mainhost}/show_product`,
      submit: `//${host.global.store}${mainhost}/mobage_nbpf/submit`,
      submitSandbox: `//${host.global.store}${mainhost}/mobage_nbpf/submit/env/test`,
      fail: `//${host.global.store}${mainhost}/mobage_nbpf/cancel/game_id/${window.gameId}`,
      failSandbox: `//${host.global.store}${mainhost}/mobage_nbpf/cancel/env/test/game_id/${window.gameId}`,
      success: `//${host.global.store}${mainhost}/mobage_nbpf/online_callback/game_id/${window.gameId}`,
      successSandbox: `//${host.global.store}${mainhost}/mobage_nbpf/online_callback/env/test/game_id/${window.gameId}`
    }
  }
}
