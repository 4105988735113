<template>
  <div
    class="login-box login-box-tourist"
    :class="{ 'full-login-box': isFullPopup && !isfullscreen }"
  >
    <div class="content-box">
      <img :src="logo" class="title" :style="logoCss" alt="" />
      <p class="select-type">
        <span class="text">{{ letter.selectLoginType }}</span>
      </p>
    </div>
    <div class="other-box login-type">
      <a
        v-if="isTouristLogin"
        class="link tourist j-tourist-tourist-btn"
        @click.prevent="otherLogin('tourist')"
      >
        <em class="ico"></em>
        <span>{{ letter.h5_guest_login }}</span>
      </a>

      <!-- <a
        :href="'javascript:;'"
        @click="otherLogin(item)"
        class="link"
        :class="`${item} j-tourist-${item}-btn`"
        v-for="(item, key) in thirdList"
        :key="key"
      >
        {{ loginText[item] }}
      </a> -->
    </div>
    <div class="login-tips">
      <em class="tra-bg"></em>
      {{ letter.h5_third_party_login_tips }}
    </div>
    <a href="javascript:;" class="term-link" @click="toggleOtherAgreeTerm">
      <em class="squ">
        <em class="gou" v-show="termState"></em>
      </em>
      <span v-html="linkTermText"></span>
    </a>
    <a href="javascript:;" class="remenber" @click="toggleOtherRemember">
      <em class="squ">
        <em class="gou" v-show="otherRemenber"></em>
      </em>
      <span>{{ letter.h5_tourist_remenber }}</span>
    </a>
    <p class="about">
      <span class="text" @click="showAboutPopup = true">
        {{ letter.aboutRelevanceAccount }}
      </span>
    </p>
    <transition name="fade">
      <div class="about-popup" v-show="showAboutPopup">
        <div class="content">
          <span class="close" @click="showAboutPopup = false"></span>
          <h3 class="title">{{ letter.aboutRelevanceAccount }}</h3>
          <p class="tips">{{ letter.touristDescription }}</p>
          <p class="tips">{{ letter.touristSuggest }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
import OtherLogin from '../../mixins/OtherLogin.js'
import LoadConfigImg from '../../mixins/LoadConfigImg'
import globalConfig from '../../utiles/globalConfig'
import LogoUrl from '../../mixins/LogoUrl'
const mainHost = globalConfig.getMainHost()
import { mapGetters } from 'vuex'

export default {
  name: 'loginPopupTourist11',
  data() {
    return {
      logo: '',
      logoCss: '',
      isTouristLogin: window.pageInfo['isTourist']
    }
  },
  mounted() {
    if (this.logoUrl) {
      this.loadImg(this.logoUrl, 'logo', false, true)
    }
  },
  computed: {
    isFullPopup() {
      return !this.isHorizontal &&
        this.widthSize == 'small' &&
        !window.isPhone;
    },
    ...mapGetters(['letter', 'lang', 'gameId', 'isfullscreen','widthSize', 'isHorizontal'])
  },
  mixins: [OtherLogin, LoadConfigImg, LogoUrl]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen.scss';

.login-box-tourist {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 580vh;
  height: 920vh;
  max-width: 95%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  z-index: 20;
  background: #f3f3f3;
  .title {
    max-height: none;
  }
  .select-type {
    width: 100%;
    text-align: center;
    margin-bottom: 50vh;
    .text {
      position: relative;
      font-size: 20vh;
      &::before,
      &::after {
        position: absolute;
        top: 14vh;
        left: -76vh;
        display: block;
        content: '';
        width: 67vh;
        height: 1px;
        background: url($img + 'select-type-left.png') no-repeat center center;
      }
      &::after {
        left: auto;
        right: -76vh;
        background: url($img + 'select-type-right.png') no-repeat center center;
      }
    }
  }
  .login-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
    width: 100%;
    a {
      color: #fff;
      display: block;
      width: 276vh;
      height: 58vh;
      margin: 0 auto;
      margin-bottom: 20vh;
      text-align: center;
      line-height: 58vh;
      text-decoration: none;
      font-size: 18vh;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .tourist {
      background: #addf95;
      width: 343vh;
      height: 80vh;
      position: relative;
      line-height: 76vh;
      border-radius: 200vh;
      font-size: 22vh;
      font-weight: 800;
      i,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      .ico {
        margin-right: 20vh;
        background: url($img + 'tourist-guest-ico.png') no-repeat top center;
        background-size: contain;
        width: 41vh;
        height: 29vh;
      }
      animation: breath 2s infinite linear;
      &:hover {
        animation-play-state: paused;
      }
    }
    @keyframes breath {
      0% {
        background: #addf95;
      }
      50% {
        background: #90c078;
      }
      100% {
        background: #addf95;
      }
    }

    .facebook {
      background: url($img + 'tourist-facebook.png') no-repeat top center;
      background-size: contain;
    }
    .twitter {
      background: url($img + 'tourist-twitter.png') no-repeat top center;
      background-size: contain;
    }
    .googleplay {
      color: #000;
      background: url($img + 'tourist-googleplay.png') no-repeat top center;
      background-size: contain;
    }
    .line {
      background: url($img + 'tourist-line.png') no-repeat top center;
      background-size: contain;
    }
  }
  .about {
    position: absolute;
    bottom: 30vh;
    left: 50%;
    transform: translateX(-50%);
    .text {
      font-size: 18vh;
      color: #00a3ff;
      border-bottom: 1px solid #00a3ff;
      cursor: pointer;
    }
  }
  .remenber,
  .term-link {
    text-decoration: none;
    outline: none;
    display: block;
    margin: 16vh auto auto;
    text-align: left;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 80%;

    .squ {
      background: url($img + 'tourist-squ.png') no-repeat top center;
      background-size: contain;
      width: 24vh;
      height: 24vh;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-right: 10vh;
      .gou {
        background: url($img + 'tourist-gou.png') no-repeat top center;
        background-size: contain;
        width: 16vh;
        height: 11vh;
        position: absolute;
        top: 50%;
        left: 50%;
        // transform: translate(-50%, -50%);
        margin-top: -5vh;
        margin-left: -8vh;
      }
    }
    & > span {
      width: calc(100% - 35vh);
      vertical-align: top;
    }
    p,
    span,
    a {
      font-size: 18vh;
      color: #797979;
      margin-left: 4vh;
      display: inline-block;
      margin: 0;
    }
  }
  .term-link {
    color: #797979;
    margin: 32vh auto 0;
  }

  .about-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 510vh;
      height: 326vh;
      margin-top: -153vh;
      margin-left: -255vh;
      border: 12vh solid #fff;
      box-sizing: border-box;
      background: #eae9e9;
      padding: 0 10vh;
      .close {
        display: block;
        position: absolute;
        top: 10vh;
        right: 10vh;
        width: 24vh;
        height: 24vh;
        background: url($img + 'tourist-close.png') no-repeat center center;
        background-size: contain;
        cursor: pointer;
      }
      .title {
        font-size: 36vh;
        margin-top: 45vh;
        width: 100%;
        height: 36vh;
        text-align: center;
        color: #414141;
      }
      .tips {
        font-size: 16vh;
        color: #414141;
      }
    }
  }
}
.login-box-tourist.full-login-box {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.login-tips {
  background: #f3f3dc;
  border: 1px #cfcaac dashed;
  color: #847523;
  font-size: 16vh;
  margin: 30vh auto auto;
  width: 80%;
  box-sizing: border-box;
  padding: 10vh;
  border-radius: 10vh;
  position: relative;
  .tra-bg {
    position: absolute;
    top: -20vh;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 10vh solid #f3f3dc;
    border-color: transparent transparent #f3f3dc transparent;
  }
  &::before {
    content: '';
    position: absolute;
    top: -10vh;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: calc(10vh + 1px);
    height: calc(10vh + 1px);
    border: 1px dashed #cfcaac;
    border-right: none;
    border-bottom: none;
  }
}
</style>
