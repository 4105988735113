import { mapGetters } from 'vuex';
import globalConfig from '../utiles/globalConfig';
import url from '../config/url';
const mainHost = globalConfig.getMainHost();

export default {
  computed: {
    storeUrl() {
      let realStoreUrl = globalConfig.hrefAddParams(url[this.platform].store, {
        gameId: this.gameId,
        serverId: this.serverId,
        roleId: this.roleId,
        roleName: encodeURIComponent(this.roleName),
        language: this.lang,
        appLanguage: this.lang,
        loginAccount: this.userName,
        userId: this.userId,
        pageSource: 'h5',
      });
      if (!this.isLogin) {
        return globalConfig.hrefAddParams(url[this.platform].login, {
          gameId: this.gameId,
          forward: encodeURIComponent(realStoreUrl),
        });
      }
      return realStoreUrl;
    },
    ...mapGetters([
      'isLogin',
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'lang',
      'platform',
      'userName',
      'userId',
    ]),
  },
};
