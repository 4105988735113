<template>
  <div class="age-popup" @click="popupShow = true" v-if="bg && btn">
    <em class="mask" v-show="popupShow"></em>
    <div class="content-box" v-show="popupShow">
      <img :src="bg" :style="bgCss" class="" alt="" />

      <div class="content">
        <p :style="fontcolor">
          18세 미만 이용자가 게임 서비스를 이용할 수 없습니다. 서비스 이용을
          위해 연령대를 선택해주세요.
        </p>
        <a class="sure" href="javascript:;" @click.stop="popupShow = false">
          <img :src="btn" :style="btnCss" alt="" />
          <span :style="surecolor" class="sure-text">18세 미만</span>
        </a>
        <a class="sure" href="javascript:;" @click.stop="closePopup">
          <img :src="btn" :style="btnCss" class="sure-bg" alt="" />
          <span :style="surecolor" class="sure-text">18세 이상</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadConfigImg from '../mixins/LoadConfigImg'
import globalConfig from '../utiles/globalConfig'

export const agePopupCookieName = 'h5_age_popup'

export default {
  data() {
    return {
      popupInfo: window.pageInfo['popup'],
      bg: '',
      bgCss: '',
      close: '',
      closeCss: '',
      btn: '',
      btnCss: '',
      fontcolor: `color:${window.pageInfo['normalColor']}`,
      surecolor: `color:${window.pageInfo['dNormalColor']}`,

      popupShow: true
    }
  },
  mixins: [LoadConfigImg],
  computed: {
    ...mapGetters(['letter'])
  },

  mounted() {
    if (this.popupInfo) {
      if (this.popupInfo.popupBg) {
        this.loadImg(this.popupInfo.popupBg, 'bg', true, true)
      }
      if (this.popupInfo.popupClose) {
        this.loadImg(this.popupInfo.popupClose, 'close', true, true)
      }
      if (this.popupInfo.popupBtn) {
        this.loadImg(this.popupInfo.popupBtn, 'btn', true, true)
      }
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('updateAgePopupState', false)
      globalConfig.setCookie(agePopupCookieName, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.age-popup {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  p {
    text-align: left;
    width: 70%;
    margin: 30vh auto auto;
  }
}
.sure {
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin: 20vh 10vh auto;
  img {
    display: block;
  }

  &-text {
    position: absolute;
    font-size: 14vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
</style>
