<template>
  <div class="qrcode-box" :style="qrStyle">
    <p class="code-tips">{{ letter.qrCodeTip }}</p>
    <img
      :src="qrcodeSrc"
      class="code"
      @click="updateCode"
      v-show="qrcodeSrc"
      alt=""
    />
  </div>
</template>

<script type="text/javascript">
  import { mapGetters } from 'vuex'
import Qrcode from '../mixins/qrCode'
import { pxToVh, getRealGameContentWidthPX } from '../utiles/gameWidth'

export default {
  methods: {
    updateCode() {
      this.$store.commit('updateQrCodeVersion')
    }
  },
  computed: {
    qrStyle() {
      let realL = getRealGameContentWidthPX(this.isHorizontal, this.widthSize) / 2 + 44
      return `margin-left:${pxToVh(realL)}vh;color:${
        window.pageInfo['normalColor']
      }`
    },
    ...mapGetters(['widthSize', 'isHorizontal'])
  },
  mixins: [Qrcode]
}
</script>

<style lang="scss" scoped>
.qrcode-box {
  width: 210vh;
  position: absolute;
  top: 760vh;
  left: 50%;
  z-index: 1;
  text-align: center;
  .code-tips {
    font-size: 19vh;
    width: 90%;
    font-weight: 600;
    margin: auto;
  }

  .code {
    width: 170vh;
    height: 170vh;
    margin-top: 10vh;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
