<template>
  <div
    class="login-box"
    :class="{ 'full-login-box': isFullPopup && !isfullscreen }"
  >
    <em class="h-l"></em>
    <em class="h-r"></em>
    <div class="content-box" v-show="isLoginPannel">
      <img :src="logo" class="title" :style="logoCss" v-if="logo" alt="" />
      <input
        type="text"
        name="userAccount"
        class="input"
        id="loginAccountInput"
        :class="{ err: userAccountTips }"
        :placeholder="letter.accountName"
        v-model="userAccount"
        @blur="blurUserAccount(true)"
      />
      <p class="tips">{{ userAccountTips }}</p>

      <input
        type="password"
        name="userPwd"
        class="input"
        id="loginPassInput"
        :placeholder="letter.password"
        :class="{ err: userPasswordTips }"
        v-model="userPassword"
        @blur="blurUserPassword(true)"
      />
      <p class="tips">{{ userPasswordTips }}</p>
      <!-- 谷歌验证码 -->
      <div class="g-recaptcha" v-show="isOpenGv" id="recaptcha"></div>
      <div class="radio-box">
        <em class="kuang" @click="toggleRemember">
          <em class="gou" v-show="isRemember"></em>
        </em>
        <span class="label" @click="toggleRemember">{{ letter.remember }}</span>
        <a :href="findPwdLink" target="_blank" class="forget">
          {{ letter.forgetPwd }}
        </a>
      </div>
      <button class="go-doing login j-account-login-btn" @click="login">
        {{ letter.login }}
      </button>
      <p class="tips center">{{ loginRequestTips }}</p>
      <div class="other-box" v-show="thirdList.length > 0">
        <span class="other-box-title">{{ letter.otherLogin }}</span>
        <span
          v-if="isTouristLogin"
          @click="otherLogin('tourist')"
          class="link tourist-all j-account-tourist-btn"
        ></span>
        <a
          :href="'javascript:;'"
          @click="otherLogin(item)"
          class="link"
          :class="`${item} j-account-${item}-btn`"
          v-for="(item, key) in thirdList"
          :key="key"
        ></a>
      </div>
      <button class="go-register" @click="switchToRegister">
        {{ letter.register }}
      </button>
    </div>
    <div class="content-box register" v-show="!isLoginPannel">
      <img :src="logo" class="title" :style="logoCss" v-if="logo" alt="" />
      <input
        type="text"
        name="userAccountRegister"
        class="input"
        id="registerAccountInput"
        v-model="userAccount"
        @blur="blurUserAccount(true)"
        :class="{ err: userAccountTips }"
        :placeholder="letter.accountName"
      />
      <p class="tips">{{ userAccountTips }}</p>
      <input
        type="password"
        name="passwordRegister"
        class="input"
        id="registerPassInput"
        :placeholder="letter.password"
        v-model="userPassword"
        :class="{ err: userPasswordTips }"
        @blur="blurUserPassword(true)"
      />
      <p class="tips">{{ userPasswordTips }}</p>
      <input
        type="password"
        name="userRePwdRegister"
        class="input"
        id="registerRepassInput"
        :placeholder="letter.repeatPassword"
        v-model="userRePassword"
        :class="{ err: userRePasswordTips }"
        @blur="blurUserRePassword(true)"
      />
      <p class="tips">{{ userRePasswordTips }}</p>
      <div class="radio-box" @click.stop="toggleAgreeTerm">
        <em class="kuang">
          <em class="gou" v-show="isAgreeTerm"></em>
        </em>
        <p class="label" v-html="linkTermText"></p>
      </div>
      <p class="tips">{{ termTips }}</p>
      <button class="go-doing login j-account-register-btn" @click="register">
        {{ letter.register }}
      </button>
      <p class="tips center">{{ registerRequestTips }}</p>
      <button class="go-register" @click="switchToLogin">
        {{ letter.backLogin }}
      </button>
    </div>
  </div>
</template>

<script type="text/javascript">
import AccountLogin from '../../mixins/AccountLogin.js'
import OtherLogin from '../../mixins/OtherLogin.js'
import { mapGetters } from 'vuex'
import LoadConfigImg from '../../mixins/LoadConfigImg'
import LogoUrl from '../../mixins/LogoUrl'
import LinkTermUrl from '../../mixins/LinkTermUrl'

export default {
  name: 'loginPopup',
  data() {
    return {
      logo: '',
      logoCss: '',
      isTouristLogin: window.pageInfo['isTourist'],
      isAutoTouristLogin: window.pageInfo['isAutoTouristLogin']
    }
  },
  created() {
    if (this.isAutoTouristLogin) {
      this.otherLogin('tourist')
    }
    window.onloadCallback = function () {
      if (document.getElementById("recaptcha")) {
        window.googleVerificationWidget = grecaptcha.render('recaptcha', {
          'sitekey': '6LcRFF0jAAAAAMb5krYvk199Dzsh_44t3XXy4dUh',
          'callback': 'onSubmit'
        });
      }
    };
    window.onSubmit = function (token) {
      window.googleVerificationToken = token;
    };
    // 数据校验 根据游戏配置语言加载不同版本谷歌验证器 默认英文
    let loadLang = this.lang || "en-US";
    (function (d, s) {
      let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s)
      j.async = true
      j.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=' + loadLang
      f.parentNode.insertBefore(j, f)
    })(document, 'script')
  },
  mounted() {
    if (this.logoUrl) {
      this.loadImg(this.logoUrl, 'logo', false, true)
    }
  },
  computed: {
    isFullPopup() {
      return !this.isHorizontal &&
        this.widthSize == 'small' &&
        !window.isPhone;
    },
    isOpenGv() {
      // 根据gameId开启谷歌验证
      const isOpenGvGames = [161];
      if (isOpenGvGames.includes(+this.gameId)) {
        let isShow = false;
        if (/recaptchaCookie=/.test(document.cookie)) {
          isShow = true;
        }
        return this.isShowRecaptcha || isShow;
      }
      return false;
    },
    ...mapGetters(['widthSize', 'isfullscreen', 'isHorizontal', 'gameId', 'lang'])
  },
  mixins: [AccountLogin, OtherLogin, LoadConfigImg, LogoUrl, LinkTermUrl]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen.scss';
.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 580vh;
  height: 920vh;
  max-width: 95%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  z-index: 20;
  background-color: white;
  .login {
    background: url($img + 'login-btn.png') no-repeat center center;
  }
  .register {
    .go-register {
      margin-top: 10vh;
    }
    .radio-box {
      padding-left: 50vh;
    }
    .kuang {
      position: absolute !important;
      left: 0;
      top: 50%;
      // transform: translateY(-50%) !important;
      // -webkit-transform: translateY(-50%) !important;
      margin-top: -20vh;
    }
    .label {
      color: $blue;
    }
    a.label {
      vertical-align: initial;
    }
  }

  .g-recaptcha {
    margin-bottom: 10px;
    position: relative;
  }
}

.login-box.full-login-box {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
</style>
