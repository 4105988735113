import globalConfig, { EXE_DEVICE_PLATE, H5_DEVICE_PLATE } from '../../../utiles/globalConfig'
import { checkIsElectron } from '../../../utiles/index'

export const sendStatistics = params => {
  globalConfig.ajaxStatistics({
    eventName: params.eventName,
    eventValue: JSON.stringify(getEventValue(params)),
    eventType: 'custom_popup_window',
    loginAccount: params.loginAccount,
    serverId: params.serverId,
    roleId: params.roleId,
    userId: params.userId,
    gclid: params.gclid,
    cid: params.cid,
    scid: params.scid,
    fbc: params.fbc,
    fbp: params.fbp,
    adid: params.adid,
    adname: params.adname,
    camid: params.camid,
    camname: params.camname,
    adsetid: params.adsetid,
    adsetname: params.adsetname,
    isTpData: true
  })
}

const getEventValue = params => {
  const commonParams = {
    loginAccount: params.loginAccount || '',
    appLanguage: params.appLanguage || '',
    language: params.language || '',
    serverId: params.serverId || '',
    devicePlate: checkIsElectron() ? EXE_DEVICE_PLATE : H5_DEVICE_PLATE,
    gameId: params.gameId || '',
    roleId: params.roleId || '',
    roleName: params.roleName || '',
    packageName: params.packageName || '',
    uid: params.userId || '',
    popupTcaActionId: params.popupTcaActionId || '',
    popupEvent: params.popupEvent || ''
  }
  return Object.assign({}, { ...params }, commonParams)
}