export default {
  gm99: {
    login: {
      code: true,
      loginTypeDefault: ['facebook'],
      fbAppIdDefault: '150671791714175',
      googleId: '',
    },
  },

  global: {
    login: {
      googleId:
        '7516021564-c1fedmh49hglgd0dlagun73e506s0fjh.apps.googleusercontent.com',
    },
  },
};
