<template>
  <div
    class="float-icon-box"
    @mouseleave.prevent="setRealIconPosition(false)"
    @mousedown.prevent="saveMousePosition"
    @touchstart.prevent="saveMousePosition"
    @mousemove.prevent="updateIconPosition"
    @mouseup.prevent="setRealIconPosition(true)"
    @touchmove.prevent="updateIconPosition"
    @touchend.prevent="setRealIconPosition(true)"
  >
    <em class="move-mask" v-show="canDrag"></em>
    <div
      ref="floatIcon"
      class="float-icon"
      :class="`${iconClassName}`"
      draggable="false"
      @mouseenter="showIcon"
    >
      <em class="red-dot" v-if="showRedDot"></em>
      <img class="float-icon-img" :src="realIconHref" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'
import OpenUserCenter from '../mixins/OpenUserCenter'
export default {
  data() {
    return {
      iconHref:
        window.pageInfo['floatIcon'][window.h5ip] ||
        window.pageInfo['floatIcon'][this.lang] ||
        window.pageInfo['floatIcon'].default,
      iconX: 0,
      iconY: 0,
      lastY: 0,
      preY: 0,
      isDrag: false,
      canDrag: false,
      iframeW: 0,
      iframeH: 0,
      iconW: 0,
      iconH: 0,
      floatIconEle: null,
      iconClassName: 'icon-hide',
      startTime: 0,
      showRedDot: +window.localStorage.getItem('showFloatIconRedDot') !== 1
    }
  },
  mounted() {
    this.floatIconEle = this.$refs.floatIcon
    globalConfig.addEvenListener(() => {
      this.setIconTransition('all 0.1s', `translate3d(0,0,0)`)
      this.lastY = 0
      this.preY = 0
      this.isDrag = false
      this.canDrag = false
    }, 'orientationchange')
  },
  mixins: [OpenUserCenter],
  computed: {
    ...mapGetters([
      'platform',
      'gameId',
      'serverId',
      'roleId',
      'roleName',
      'thirdPlatformType'
    ]),
    realIconHref() {
      let iconURL = ''
      if (this.thirdPlatformType !== '') {
        iconURL =
          window.pageInfo.floatIcon[this.thirdPlatformType] || this.iconHref
      } else {
        iconURL = this.iconHref
      }
      return iconURL
    }
  },
  methods: {
    updateRedDotStatus() {
      this.showRedDot = false
      window.localStorage.setItem('showFloatIconRedDot', 1)
    },
    setRealIconPosition(canOpen) {
      let newTime = new Date().getTime()
      if ((!this.isDrag && canOpen) || newTime - this.startTime < 200) {
        this.excOpenUserCenter()
        this.updateRedDotStatus()
      }
      this.canDrag = false
      this.isDrag = false
      this.preY = this.lastY

      this.setIconTransition('all 0.1s', `translate3d(0,${this.preY}px,0)`)
      this.iconClassName = 'icon-hide'
    },
    saveMousePosition() {
      this.isDrag = false
      this.canDrag = true
      this.startTime = new Date().getTime()

      const floatIconRef = this.$refs.floatIcon.getBoundingClientRect()
      const gamePageRef = document.getElementById('game-iframe')

      this.iconY = parseInt(floatIconRef.top + floatIconRef.height / 2, 10)
      this.iconX = parseInt(floatIconRef.left + floatIconRef.width / 2, 10)

      this.iconW = parseInt(floatIconRef.width, 10)
      this.iconH = parseInt(floatIconRef.height, 10)

      this.iframeW = parseInt(gamePageRef.offsetWidth, 10)
      this.iframeH = parseInt(gamePageRef.offsetHeight, 10)
    },
    showIcon() {
      if (!this.isDrag || !this.canDrag) {
        this.iconClassName = ''
      }
    },
    hideIcon() {
      if (!this.canDrag) {
        this.iconClassName = 'icon-hide'
      }
    },
    // 实时更新icon位置
    updateIconPosition(e) {
      e.preventDefault()
      if (this.canDrag) {
        this.isDrag = true

        let eventX = parseInt(e.clientX || e.changedTouches[0].clientX, 10)
        let eventY = parseInt(e.clientY || e.changedTouches[0].clientY, 10)

        let moveY = eventY - this.iconY + this.preY
        let moveX = eventX - this.iconX

        let limtParamIcon = this.iconH
        let limtParamIframe = this.iframeH / 2

        if (moveX > 0) {
          moveX = 0
        }
        if (moveX < -1 * this.iframeW + this.iconW) {
          moveX = -1 * this.iframeW + this.iconW
        }

        if (moveY < -1 * limtParamIframe) {
          moveY = -1 * limtParamIframe
        } else if (moveY > limtParamIframe - limtParamIcon) {
          moveY = limtParamIframe - limtParamIcon
        }

        this.setIconTransition('all 0s', `translate3d(${moveX}px,${moveY}px,0)`)

        this.lastY = moveY
      }
    },
    setIconTransition(tion, late) {
      if (this.floatIconEle.style.webkitTransition) {
        this.floatIconEle.style.webkitTransition = tion
      }
      if (this.floatIconEle.style.webkitTransform) {
        this.floatIconEle.style.webkitTransform = late
      }
      if (this.floatIconEle.style.MozTransition) {
        this.floatIconEle.style.MozTransition = tion
      }
      if (this.floatIconEle.style.MozTransform) {
        this.floatIconEle.style.MozTransform = late
      }
      if (this.floatIconEle.style.OTransition) {
        this.floatIconEle.style.OTransition = tion
      }
      if (this.floatIconEle.style.OTransform) {
        this.floatIconEle.style.OTransform = late
      }
      if (this.floatIconEle.style.msTransition) {
        this.floatIconEle.style.msTransition = tion
      }
      if (this.floatIconEle.style.msTransform) {
        this.floatIconEle.style.msTransform = late
      }
      this.floatIconEle.style.transition = tion
      this.floatIconEle.style.transform = late
    }
  }
}
</script>

<style lang="scss" scoped>
.float-icon-box {
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  cursor: pointer;
}
.float-icon {
  position: absolute;
  height: 75vh;
  width: 75vh;
  z-index: 5;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  right: 0;
}
.red-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  left: 4px;
  top: 4px;
}
.float-icon-img {
  width: 100%;
  height: 100%;
}
.icon-hide {
  right: -37.5vh;
}
.horizontal.bigsize .icon-hide {
  right: -25vh;
}
.move-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  cursor: pointer;
  // background: rgba(180, 31, 31, 0.6);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.m .float-icon {
  &:hover {
    right: -37.5vh;
  }
}
</style>
