import { mapActions, mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'
import { judgeLangCurrent } from '../store/languages/index'
import { N_UUID } from '../utiles/thirdPartySdkFn'
import { decryption, getPhoneModel } from '../utiles/index'
import OpenUserCenter from '../mixins/OpenUserCenter'
import MobageAsideLink from '../mixins/MobageAsideLink'
import Tca from '../mixins/Tca'
import Ads from '../mixins/Ads'
export default {
  mixins: [OpenUserCenter, MobageAsideLink, Tca, Ads],
  computed: {
    ...mapGetters([
      'userName',
      'serverId',
      'roleId',
      'userId',
      'gclid',
      'cid',
      'scid',
      'gameId',
      'platform'
    ])
  },

  methods: {
    sdkMessageHandler(evt) {
      let ev = evt || event
      let action = ev.data.action
      if (!!action && !!this[action]) {
        this[action](ev.data.param)
        this.handleTcaTrigger(action, ev.data.param)
      }
    },
    gameLoadProcess(param) {
      globalConfig.gaStatistics('game_load_process', param)
    },
    clickEnterGameBtn() {
      globalConfig.gaStatistics('click_enter_game')
    },
    clickCreateRoleBtn() {
      globalConfig.gaStatistics('click_create_role')
    },
    enterSelectServerPage() {
      globalConfig.gaStatistics('enter_server_page')
    },
    enterPlay() {
      globalConfig.gaStatistics('enter_game')
    },
    enterCreateRolePage() {
      globalConfig.gaStatistics('enter_create_role_page')
    },

    isRole(param) {
      globalConfig.gaStatistics('is_role', param)
    },

    enterSelectRolePage() {
      globalConfig.gaStatistics('enter_select_role_page')
    },

    clickEnterRoleBtn() {
      globalConfig.gaStatistics('click_enter_role_btn')
    },

    clickCreateOtherRoleBtn() {
      globalConfig.gaStatistics('click_create_other_role_btn')
    },

    enterServer(sdkParam) {},
    enterGame(sdkParam) {},

    roleInfo(sdkParam) {
      this.$store.commit('updateUserGameInfo', sdkParam)
      this.enterServerReport({
        ...sdkParam
      })
      globalConfig.gaStatistics('enter_server_role', sdkParam)
    },

    createRoleInfo(sdkParam) {
      console.info('create-role')
      globalConfig.gaStatistics('create_role', sdkParam)
      if (this.platform === 'global') {
        this.createRoleReport({
          ...sdkParam
        })
      }
    },

    switchSucc() {
      globalConfig.log('switchSucc')
      this.judgeIsLogin()
      this.getGameLink()
      this.$store.commit('updateAsidePopupState', false)
    },

    userCenter(param) {
      console.info('userCenter')
      console.info(param)
      globalConfig.gaStatistics(param.action, {
        type: param.type
      })
    },

    openPayList() {
      console.info('open-pay-list')
      globalConfig.gaStatistics('open_pay_list')
    },

    pay(sdkParam) {
      console.info('pay')
      const _self = this
      this.$store.commit('updateSdkParams', sdkParam)
      let platform = globalConfig.getThirdPlatformType()
      const PAY_HANDLE = {
        global: _self.globalPay,
        mobage: _self.mobagePay
      }
      let payHandle = PAY_HANDLE[platform] || PAY_HANDLE.global
      payHandle(sdkParam)
      globalConfig.gaStatistics('pay', sdkParam)
    },

    globalPay(sdkParam) {
      this.$store.commit('updateStorePopupState', true)
    },

    openUserCenter() {
      console.info('open-user-center')
      this.excOpenUserCenter()
    },

    selectServer() {
      console.info('select-server')
      this.getGameLink()
    },

    switchLanguage(param) {
      console.info('switchLang')
      if (param.lang && judgeLangCurrent(param.lang)) {
        this.$store.dispatch('switchLanguage', param.lang)
      }
    },

    markUserBehave(sdkParam) {
      // 绕过 上一步/账号记录 流程
      globalConfig.gaStatistics(sdkParam.eventName, sdkParam)
      globalConfig.ajaxStatistics({
        eventName: sdkParam.eventName,
        eventValue: sdkParam.eventValue,
        eventType: sdkParam.eventType,
        loginAccount: this.userName,
        serverId: this.serverId,
        roleId: this.roleId,
        userId: this.userId,
        gclid: this.gclid,
        cid: this.cid,
        scid: this.scid,
        fbc: this.fbc,
        fbp: this.fbp,
        adid: this.adid,
        adname: this.adname,
        camid: this.camid,
        camname: this.camname,
        adsetid: this.adsetid,
        adsetname: this.adsetname
      })

      // 越南大掌柜引导下载app弹屏
      const isDzgVn = +this.gameId === 215
      const reachEvent = ['free_giftbag'].includes(sdkParam.eventName)
      const pcOrIos =
        !window.isPhone || (window.isPhone && getPhoneModel() === 'iOS')
      if (isDzgVn && reachEvent && pcOrIos) {
        this.handleDownloadGuideTp(sdkParam.eventName)
      }
    },

    updateLoginId(param) {
      let realId = ''
      try {
        realId = decryption(param)
      } catch (e) {
        globalConfig.log(e)
      }
      if (realId) {
        realId = realId.replace(/\"/g, '')
        globalConfig.setLclStorageItem(N_UUID, realId)
        globalConfig.setCookie(N_UUID, realId)
      }
    },

    commonFn(sdkParam) {
      /**
       * mobage侧边按钮
       * action:mobageAsideOpenLink
       * type: home|setting|law|userCenter
       */
      if (
        sdkParam &&
        sdkParam.action &&
        typeof sdkParam.action === 'string' &&
        sdkParam.action === 'mobageAsideOpenLink'
      ) {
        let type = (sdkParam.data && sdkParam.data.type) || ''
        const FUN_MAP = {
          home: 'mobageOpenHome',
          setting: 'mobageOpenSetting',
          law: 'mobageOpenLaw',
          userCenter: 'mobageOpenUserCenter'
        }
        FUN_MAP[type] && this[FUN_MAP[type]]()
      }
    },

    logout() {
      console.info('logout')
      this.exclogout()
    },

    ...mapActions({
      enterServerReport: 'enterServerReport',
      judgeIsLogin: 'judgeIsLogin',
      getGameLink: 'getGameLink',
      exclogout: 'logout',
      createRoleReport: 'createRoleReport'
    })
  }
}
