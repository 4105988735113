<template>
  <div class="gift-get-popup" v-if="bg && close">
    <img :src="bg" :style="bgCss" alt="" />
    <a class="close" href="javascript:;" @click="closePopup">
      <img :src="close" :style="closeCss" alt="" />
    </a>

    <div class="content">
      <p class="title" :style="mainColor">{{ letter.h5_succ_get_gift }}</p>
      <p class="text pretitle" :style="fontcolor">{{ letter.favourTip }}</p>
      <p class="number" :style="numberColor">{{ giftNumber }}</p>
      <p class="tips" :style="mainColor">
        {{ letter.h5_exchange_info_title }}:
      </p>
      <p class="text" :style="fontcolor">1、{{ exchangeWay }}</p>
      <p class="text" :style="fontcolor">2、{{ letter.h5_exchange_info_2 }}</p>
    </div>
  </div>
</template>

<script>
import LoadConfigImg from '../mixins/LoadConfigImg'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      popupInfo: window.pageInfo['popup'],
      fontcolor: `color:${window.pageInfo['normalColor']}`,
      bg: '',
      bgCss: '',
      close: '',
      closeCss: '',
      mainColor: `color:${window.pageInfo['mainColor']}`,
      borderColor: `border-color:${window.pageInfo['borderColor']}`,
      numberColor: `background-color:${window.pageInfo['tableColor']};color:${window.pageInfo['normalColor']}`
    }
  },
  mixins: [LoadConfigImg],
  computed: {
    exchangeWay() {
      return (
        (window.pageInfo['awardExchangeText'] &&
          window.pageInfo['awardExchangeText'][this.lang]) ||
        ''
      )
    },
    ...mapGetters(['giftNumber', 'letter', 'lang'])
  },
  mounted() {
    if (this.popupInfo) {
      if (this.popupInfo.popupBgB) {
        this.loadImg(this.popupInfo.popupBgB, 'bg', true, true)
      }
      if (this.popupInfo.popupClose) {
        this.loadImg(this.popupInfo.popupClose, 'close', true, true)
      }
    }
  },
  methods: {
    closePopup() {
      this.$store.commit('updateGiftNumber', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-get-popup {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 2;
  }
  .content {
    position: absolute;
    width: 100%;
    top: 0;
    text-align: center;
    font-size: 20vh;
    .title {
      font-size: 28vh;
      font-weight: 600;
      margin: 30vh auto 4vh;
    }
    .text,
    .tips {
      font-size: 18vh;
      margin: 0 30vh;
      line-height: 180%;
      text-align: left;
    }
    .tips {
      font-weight: 600;
      font-size: 20vh;
    }
    .pretitle {
      text-align: center;
    }
    .number {
      margin: 25vh 30vh;
      padding: 20vh 0;
      font-weight: 600;
      font-size: 22vh;
    }
  }
}
</style>
