<template>
  <div class="common-footer" :class="{ mobile: ismobile }">
    <div class="common-footer-content">
      <img
        class="common-footer-logo"
        :src="logoUrl"
        :class="{ small: footerContent }"
        alt=""
      />
      <div class="common-footer-links" :class="{ ageLimit: isOpenAgeLimit && !ismobile }">
        <template v-if="isOpenAgeLimit">
          <div :class="{ linkWrap: !ismobile }">
            <p>
              <a class="common-footer-link" :href="termUrl" v-if="serverTermState" v-html="letter.termsOfUse"
                target="_blank"></a>

              <a class="common-footer-link" :href="privicyUrl" v-if="privacyTermState" v-html="letter.privacyPolicy"
                target="_blank"></a>
            </p>
            <p class="copyright" v-if="footerContent">{{ footerContent }}</p>
            <p v-html="copyright" class="copyright"></p>
          </div>
          <div :class="{ gradeWrap: ismobile }" style="text-align: left;">
            <img class="grade" :src="gradeUrl" alt="15" />
            <div>
              <p class="grade-t">本遊戲軟體分級管理辦法分類為：輔15級</p>
              <p class="grade-t">本遊戲內容涉及暴力</p>
              <p class="grade-t">本遊戲部份內容需另行支付費用</p>
              <p class="grade-t">請避免沉迷遊戲</p>
              <p class="grade-t">代理商：優勢領航科技有限公司</p>
            </div>
          </div>
        </template>
        <template v-else>
          <p>
            <a class="common-footer-link" :href="termUrl" v-if="serverTermState" v-html="letter.termsOfUse"
              target="_blank"></a>

            <a class="common-footer-link" :href="privicyUrl" v-if="privacyTermState" v-html="letter.privacyPolicy"
              target="_blank"></a>
          </p>
          <p class="copyright" v-if="footerContent">{{ footerContent }}</p>
          <p v-html="copyright" class="copyright"></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoUrl from '../../mixins/LogoUrl'
import LinkTermUrl from '../../mixins/LinkTermUrl'
import globalConfig from '../../utiles/globalConfig'
const mainHost = globalConfig.getMainHost()

const FOOTER_CONTENT = {
  common: '',
  kr: '결제내역문의(PL고객센터) : 1599-7591'
}

export default {
  name: 'FooterCommonGlobal',
  data() {
    return {
      ismobile: window.isPhone,
      gradeUrl: `//abres.octlib.com/platformIcon/h5/grade-15.png`,
      footerContent: FOOTER_CONTENT[window.pageInfo['footerContent']] || '',
      copyright:
        window.pageInfo['copyright'] ||
        `Copyright © www.${mainHost} All rights reserved.`
    }
  },
  computed: {
    isOpenAgeLimit() {
      // 2024.6.25 特殊处理：霸业2.0台港澳版H5底栏需要展示年龄分级
      // 根据gameId开启底栏年龄分级限制
      const isOpenAgeLimitGames = [379];
      return isOpenAgeLimitGames.includes(+this.gameId);
    },
    ...mapGetters(['gameId'])
  },
  mixins: [LogoUrl, LinkTermUrl]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_dimen.scss';
.common-footer {
  background: white;
  width: 100%;
  display: block;
  box-sizing: border-box;
  &-content {
    width: 90%;
    padding: 36vh 0;
    margin: auto;
    position: relative;
  }
  &-logo {
    height: 60%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    &.small {
      height: 40%;
    }
  }
  &-links {
    text-align: right;
    color: black;
    font-size: 16vh;
    p {
      margin: 0;
    }
    .grade {
      float: left;
      width: 60vh;
      height: 60vh;
    }

    .grade-t {
      font-size: 14vh;
      color: #333;
      margin: -1px auto 0;
      white-space: nowrap;
      padding-left: 70vh;
      -webkit-text-size-adjust: none;
    }
    &.ageLimit {
      display: flex;
      justify-content: right;
      .linkWrap {
        margin-right: 25vh !important;
        padding-top: 25vh !important;
      }
    }
    .gradeWrap {
      display: flex;
      justify-content: center;
      margin-top: 10vh;

      .grade-t {
        padding-left: 18vh !important;
      }
    }
  }
  .copyright {
    margin-top: 6vh;
  }
  &-link {
    color: black;
    text-decoration: none;
    position: relative;
    margin-left: 30vh;
    font-size: 16vh;
    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 80%;
      background: black;
      left: -15vh;
      top: 10%;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
  &.mobile {
    padding: 30vh 0;
    .common-footer-content {
      width: 100%;
      padding: 0;
    }
    .common-footer-logo {
      height: 50vh;
      display: block;
      margin: auto;
      position: initial;
      transform: none;
    }
    .common-footer-links {
      margin-top: 10vh;
      text-align: center;
    }
  }
}
</style>
