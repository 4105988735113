let anchorSlotLeft = null
let anchorSlotRight = null

export const createAnchorAds = () => {
  googletag.cmd.push(() => {
    anchorSlotLeft = googletag.defineOutOfPageSlot(
      `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/anchor_left`,
      googletag.enums.OutOfPageFormat.LEFT_SIDE_RAIL
    )
    if (anchorSlotLeft) {
      anchorSlotLeft.addService(googletag.pubads())
    }

    anchorSlotRight = googletag.defineOutOfPageSlot(
      `/${window.pageInfo['ads']['gamConfig']['adsAccount']}/h5_${window.gameInfo.GAME_CODE}/anchor_right`,
      googletag.enums.OutOfPageFormat.RIGHT_SIDE_RAIL
    )
    if (anchorSlotRight) {
      anchorSlotRight.addService(googletag.pubads())
    }
  })
}

export const displayAnchorAds = () => {
  if (anchorSlotLeft) {
    googletag.cmd.push(() => {
      googletag.display(anchorSlotLeft)
    })
  }
  if (anchorSlotRight) {
    googletag.cmd.push(() => {
      googletag.display(anchorSlotRight)
    })
  }
}
