import globalConfig from '../utiles/globalConfig';
import url from '../config/url';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['platform', 'gameId', 'serverId', 'roleId', 'roleName']),
  },
  methods: {
    excOpenUserCenter() {
      if (window.pageInfo['asideType'] === 'popup') {
        this.$store.commit('updateAsidePopupState', true);
      } else {
        window.open(
          globalConfig.hrefAddParams(url[this.platform].userCenter, {
            gameId: this.gameId,
            serverId: this.serverId,
            roleId: this.roleId,
            roleName: encodeURIComponent(this.roleName),
          }),
          '_blank'
        );
      }
    },
  },
};
