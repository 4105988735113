import languages from './languages';
languages.init();

export default {
  state: {
    letter: languages.getAll(),
    lang: languages.language,
    langList: languages.getLangText(),
  },
  getters: {
    letter: state => state.letter,
    lang: state => state.lang,
  },

  mutations: {
    // 切换语言
    switchLanguage(state) {
      state.letter = languages.getAll();
      state.lang = languages.language;
    },
  },
  actions: {
    // 切换语言
    switchLanguage({ commit }, param) {
      languages.setLanguage(param);
      commit('switchLanguage');
      // 切换tips？
    },
  },
};
