import Vue from 'vue';
if (window.hwerrVue) {
  Vue.config.errorHandler = window.hwerrVue;
}
import Vuex from 'vuex';

import storeOptions from '../resource/store/index';

import h5Pc from '../resource/views/Pc';

import VueClipboards from 'vue-clipboard2';

import globalConfig from '../resource/utiles/globalConfig';

import TcaCoreManager from '../resource/tca/index'
let tcaCoreManager = new TcaCoreManager()
window.tcaCoreManager = tcaCoreManager  

Vue.use(VueClipboards);
Vue.use(Vuex);

Vue.config.productionTip = false;

const store = new Vuex.Store(storeOptions);

globalConfig.gaStatistics('visit');

new Vue({
  store,
  render: h => h(h5Pc),
}).$mount('#h5-pc');
