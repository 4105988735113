<template>
  <div class="guide-popup">
    <div class="guide-popup-shade" @click="hidePopup"></div>
    <div class="guide-popup-content">
      <p class="guide-popup-title">{{ letter.voucherAddHome }}</p>
      <img class="guide-popup-img" :src="logo" alt="logo" />
      <div class="guide-popup-btn">
        <a class="guide-popup-btn-item" href="javascript:;" @click="install">
          {{ letter.pwsInstall }}
        </a>
      </div>
      <a
        class="guide-popup-close j-click-coupon-close"
        href="javascript:;"
        @click="hidePopup"
        >&times;</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SafariPwaGuidePopup',
  emits: ['close', 'install'],
  data() {
    return {
      logo: `//${window.location.host}/h5/pwa/icon/icon${window.pageInfo.game_id}.png`
    }
  },
  computed: {
    ...mapGetters({
      letter: 'letter'
    })
  },
  methods: {
    hidePopup() {
      this.$emit('close')
    },
    install() {
      this.$emit('install')
      this.hidePopup()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/_dimen.scss';

.guide-popup {
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;

  &-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    width: 354px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
  }

  &-title {
    margin-top: 19.5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  &-img {
    display: block;
    margin: 12px auto;
    width: 128px;
    height: 128px;
    object-position: center;
    object-fit: contain;
  }

  &-btn {
    text-align: center;
    margin: 12px auto 20px;

    &-item {
      display: inline-block;
      padding: 0 22px;
      max-width: 260px;
      min-height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      color: #333333;
      border-radius: 4px;
      border: 1px solid #333333;
      text-decoration: none;
      box-shadow: 1px 1px 10px 1px #ddd;
    }
  }

  &-close {
    position: absolute;
    right: 16px;
    top: 12px;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    text-decoration: none;
    color: #333333;
  }
}
</style>
