import { mapGetters } from 'vuex';
import url from '../config/url';
import globalConfig from '../utiles/globalConfig';

export default {
  computed: {
    termUrl() {
      if (this.platform == 'global' && this.gameId === '179') {
        return 'https://gabres.3syua.com/service/kr_rlwj.html';
      }
      return globalConfig.hrefAddParams(url[this.platform].serviceUrl, {
        gameId: this.gameId,
        language: this.lang,
        appLanguage: this.lang,
      });
    },
    privicyUrl() {
      return globalConfig.hrefAddParams(url[this.platform].privicyUrl, {
        gameId: this.gameId,
        language: this.lang,
        appLanguage: this.lang,
      });
    },
    serverTermState() {
      if (window.pageInfo['serviceDisplay'][window.h5ip] !== undefined) {
        return window.pageInfo['serviceDisplay'][window.h5ip];
      }
      if (window.pageInfo['serviceDisplay'][this.lang] !== undefined) {
        return window.pageInfo['serviceDisplay'][this.lang];
      }
      return this.serverState;
    },
    privacyTermState() {
      if (window.pageInfo['privacyDisplay'][window.h5ip] !== undefined) {
        return window.pageInfo['privacyDisplay'][window.h5ip];
      }
      if (window.pageInfo['privacyDisplay'][this.lang] !== undefined) {
        return window.pageInfo['privacyDisplay'][this.lang];
      }
      return this.privacyState;
    },
    linkTermText() {
      let arr = this.letter.linkTermText.split('[');
      let res = this.letter.linkTermText;
      if (arr.length === 3) {
        res = arr[0];
        let text = arr[1].split(']');
        if (this.serverTermState) {
          res += `<a class="label blue" target="_blank" href="${this.termUrl}">${text[0]}</a>`;
        }
        if (this.serverTermState && this.privacyTermState) {
          res += text[1];
        }
        if (this.privacyTermState) {
          res += `<a class="label blue" target="_blank" href="${
            this.privicyUrl
          }">${arr[2].replace(/\]/g, '</a>')}`;
        }
      }
      return res;
    },
    ...mapGetters([
      'gameId',
      'letter',
      'lang',
      'serverState',
      'privacyState',
      'platform',
    ]),
  },
};
