import { INIT_STAGE_CONFIG } from '../tca/index'
import { mapGetters } from 'vuex'
import globalConfig, { EXE_DEVICE_PLATE, H5_DEVICE_PLATE } from '../utiles/globalConfig'
import { checkIsElectron } from '../utiles/index'
export default {
  data() {
    return {
      isOpenTca: !!window.tcaCoreManager,
      showTcaLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'userName',
      'userId',
      'serverId',
      'gameId',
      'platform'
    ])
  },
  methods: {
    handleTcaTrigger(action, sdkParam) {
      if (window.tcaCoreManager && action == 'roleInfo') {
        window.tcaCoreManager.updateNowStage(
          INIT_STAGE_CONFIG.enterServer.stage
        )
        window.tcaCoreManager
          .initTaData({
            platform: this.platform,
            apiKey: 'enterServer',
            reqParams: {
              gameId: this.gameId,
              appLanguage: this.lang,
              language: this.lang,
              loginAccount: this.userName,
              devicePlate: checkIsElectron() ? EXE_DEVICE_PLATE : H5_DEVICE_PLATE,
              uid: this.userId,
              serverId: this.serverId,
              packageName: globalConfig.getHrefParam('packageName') || window.pageInfo.packageName,
              roleLevel: sdkParam.level || '',
              roleName: sdkParam.roleName || '',
              roleId: sdkParam.roleId || '',
              isMerge: 1
            }
          })
          .then(() => {
            window.tcaCoreManager.trigger('finish_add_server', this)
          })
          .catch(() => {
            console.log('tca-enterServer初始化失败')
          })
        return
      }
      if (window.tcaCoreManager && action == 'markUserBehave' && sdkParam.eventName) {
        window.tcaCoreManager.trigger(sdkParam.eventName, this)
      }
    },
    showTcaPageLoading() {
      this.showTcaLoading = true
    },
    hideTcaPageLoading() {
      this.showTcaLoading = false
    }
  }
}
