import { mapActions, mapGetters } from 'vuex'
import globalConfig from '../utiles/globalConfig'

export default {
  data() {
    let giftType = window.pageInfo['awardType'] || 'game'
    return {
      isFavourAward: window.pageInfo['favourAward']['isAward'] || false,
      isNormalGift: giftType === 'game',
      isNumberGift: giftType === 'number'
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'favourHasGift', 'letter', 'roleId'])
  },
  methods: {
    ...mapActions(['getAwardState']),
    favourFn() {
      try {
        globalConfig.gaStatistics('click_favour')
      } catch (e) {}

      // 如果沒有發獎，就直接提示收藏
      if (!this.isFavourAward) {
        this.doFavour(this.letter.favourTip)
        return
      }

      // 如果還沒進入遊戲，就提示要先進遊戲
      if (!(this.isLogin && this.roleId)) {
        this.doFavour(this.letter.h5_favour_login_get)
        return
      }

      // 如果已經領過了，就提示已領
      if (this.favourHasGift) {
        this.doFavour(this.letter.h5_favour_has_gift)
        return
      }

      this.doFavour()
      this.$store.dispatch('getFavourGift').then(res => {
        // 如果领奖失败
        if (res.result == '0') {
          // 兼容一下161遊戲的旧接口
          if (res.code == -20002) {
            this.doFavour(this.letter.h5_favour_has_gift, true)
          } else {
            this.doFavour(this.letter.h5_get_gift_fault, true)
          }
          return
        }

        // 通过领奖的类型显示对应的文案和弹窗
        let realText = this.isNormalGift
          ? this.letter.h5_favour_get_gift
          : res.data.card
        this.doFavour(realText, true, true)
        this.getAwardState()

        try {
          globalConfig.gaStatistics('favour_gift')
        } catch (e) {}
      })
    },
    doFavour(text, onlyAlert, judgeType) {
      if (!onlyAlert) {
        let curTitle =
          window.pageInfo['title'][window.h5ip] ||
          window.pageInfo['title'][this.lang] ||
          window.pageInfo['title'].default ||
          window.pageInfo['page_title']
        try {
          window.external.addFavorite(window.location.href, curTitle)
        } catch (e) {
          try {
            window.sidebar.addPanel(curTitle, window.location.href, '')
          } catch (e) {}
        }
      }

      this.$nextTick(() => {
        if (this.isNumberGift && judgeType) {
          this.$store.commit('updateGiftNumber', text)
        } else {
          if (text) {
            // 如果是序号领奖，而且已经进到游戏了，又需要显示提示，提示按钮就为查看奖励，其他的都是确认
            this.$store.commit('updateCommonTips', {
              content: text,
              sure:
                this.isNumberGift && this.isLogin && this.roleId
                  ? 'giftList'
                  : ''
            })
          }
        }
      })
    }
  }
}
